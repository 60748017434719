import cx from 'classnames'
import {ChangeEventHandler, MouseEvent, useCallback, useRef} from 'react'
import {ReactComponent as RadioBlankOutlineIcon} from '../icons/radio-blank-outline.svg'
import {ReactComponent as RadioMarkedIcon} from '../icons/radio-marked.svg'
import {Label} from './Label'
import {Text} from './Text'

type Color = 'default' | 'green' | 'red'
type Size = 'sm' | 'default' | 'lg'

type Props = {
  id?: string
  label?: string
  error?: string
  size?: Size
  color?: Color
  value?: string
  name?: string
  checked?: boolean
  onChange?: ChangeEventHandler<HTMLInputElement>
  className?: string
  disabled?: boolean
  labelPosition?: 'left' | 'right'
}

const getColorClasses = (color: Color) => {
  switch (color) {
    case 'default':
      return 'text-blue-700'
    case 'green':
      return 'text-green-700'
    case 'red':
      return 'text-red-700'
  }
}

const getSizeClasses = (size: Size) => {
  switch (size) {
    case 'sm':
      return 'w-5 h-5'
    case 'default':
      return 'w-6 h-6'
    case 'lg':
      return 'w-7 h-7'
  }
}

export const Radio = ({
  id,
  label,
  error,
  color = 'default',
  size = 'default',
  name,
  value,
  checked,
  onChange,
  className,
  disabled,
  labelPosition = 'right',
}: Props) => {
  const hiddenInputRef = useRef<HTMLInputElement>(null)

  const colorClasses = getColorClasses(color)
  const sizeClasses = getSizeClasses(size)
  const resultClassName = cx('bg-transparent cursor-pointer flex items-center justify-center', colorClasses, sizeClasses, className)

  const onInputClick = useCallback(() => {
    if (hiddenInputRef.current) {
      hiddenInputRef.current.click()
    }
  }, [hiddenInputRef])

  const onLabelClick = useCallback((event: MouseEvent) => {
    event.preventDefault()
  }, [])

  return (
    <div className={cx('flex flex-col gap-1', className)}>
      <input
        id={id}
        checked={checked}
        type="radio"
        name={name}
        value={value}
        className="hidden"
        onChange={onChange}
        disabled={disabled}
        ref={hiddenInputRef}
      />
      <div className="flex gap-1 items-center cursor-pointer select-none" onClick={onInputClick}>
        {label && labelPosition === 'left' && (
          <Label htmlFor={id} className="cursor-pointer" onClick={onLabelClick}>
            {label}
          </Label>
        )}
        <div className={resultClassName}>{checked ? <RadioMarkedIcon /> : <RadioBlankOutlineIcon />}</div>
        {label && labelPosition === 'right' && (
          <Label htmlFor={id} className="cursor-pointer" onClick={onLabelClick}>
            {label}
          </Label>
        )}
      </div>
      {error && <Text color="red">{error}</Text>}
    </div>
  )
}
