import {PageHeader} from 'components/PageHeader'
import {userHooks} from 'hooks/users'
import {useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {Components} from 'ui'
import {Pill} from 'ui/components'
import {DEFAULT_LIMIT} from 'ui/components/Paginator'

const RolePill = ({role}: {role: string}) => {
  switch (role) {
    case 'superAdmin':
      return <Pill className="text-orange-500">Super admin</Pill>
    case 'operationsAdmin':
      return <Pill className="text-green-500">Operations admin</Pill>
    case 'operationsUser':
      return <Pill className="text-green-400">Operations user</Pill>
    case 'guideUser':
      return <Pill className="text-blue-400">Guide user</Pill>
    case 'finanseUser':
      return <Pill className="text-magenta-400">Finance user</Pill>
  }

  return <Pill className="text-gray-500">Unknown role</Pill>
}

export const List = () => {
  const navigate = useNavigate()
  const [offset, setOffset] = useState(0)

  const userListQuery = userHooks.useListQuery({
    limit: DEFAULT_LIMIT,
    orderBy: ['firstName', 'lastName'],
    // filters: [{field: 'isDeleted', value: false}],
    offset,
  })

  const userCountQuery = userHooks.useCountQuery({
    // filters: [{field: 'isDeleted', value: false}],
  })

  const onAddButtonClick = () => {
    navigate('/users/create')
  }

  const headers = [
    {key: 'name', value: 'Name'},
    {key: 'email', value: 'Email'},
    {key: 'roles', value: 'Roles'},
    {key: 'edit', className: 'w-1'},
  ]

  if (!userListQuery.data || !userCountQuery.data) {
    return null
  }

  const rows = userListQuery.data.map((user) => ({
    key: user.id,
    columns: {
      name: `${user.firstName} ${user.lastName}`,
      email: user.email,
      roles: (
        <div className="flex gap-1">
          {user.roles.map((role) => (
            <RolePill key={role} role={role} />
          ))}
        </div>
      ),
      edit: <Components.EditLink to={`/users/${user.id}/edit`} />,
    },
  }))

  return (
    <div className="flex flex-col gap-4">
      <PageHeader title="Users">
        <Components.Button onClick={onAddButtonClick} size="sm" className="ml-auto">
          Add User
        </Components.Button>
      </PageHeader>

      <Components.Table headers={headers} rows={rows} />
      <Components.Paginator offset={offset} total={userCountQuery.data} onChangeOffset={setOffset} />
    </div>
  )
}
