import {PageHeader} from 'components/PageHeader'
import {MonthFilter, getDefaultMonthPeriodId} from 'components/filters/MonthFilter'
import {locationHooks} from 'hooks/locations'
import {useState} from 'react'
import {Components} from 'ui'

export const View = () => {
  const [intervalFilters, setIntervalFilters] = useState(getDefaultMonthPeriodId())

  const locationListQuery = locationHooks.useListQuery({orderBy: ['name']})

  if (!locationListQuery.data) {
    return null
  }

  // const locationOptions = locationListQuery.data.map((location) => {
  //   return {
  //     label: location.name,
  //     value: location.id,
  //   }
  // })

  return (
    <>
      <PageHeader title="Finance" />
      <div className="flex flex-col p-4">
        <div className="flex gap-2 items-center">
          <Components.Label>Month</Components.Label>
          <MonthFilter value={intervalFilters} onChange={setIntervalFilters} />
        </div>
      </div>
    </>
  )
}
