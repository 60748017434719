import {DATE_FORMAT} from 'consts'
import moment from 'moment'
import {WeekPicker} from 'ui/components/WeekPicker'
import {ControlSize} from 'ui/types'

type Props = {
  value: string
  onChange: (value: string) => void
  size?: ControlSize
}

export const getDefaultWeekPeriodId = (): string => {
  return moment().startOf('isoWeek').format(DATE_FORMAT)
}

export const WeekFilter = ({value, onChange, size}: Props) => {
  return <WeekPicker size={size} value={value} onChange={(event) => onChange(event.target.value)} />
}
