import { useEntityById } from "hooks/useEntityById"
import { useData } from "./useData"
import { Assignments } from "common/types"

const DEFAULT_VALUE: Assignments.Basic.StorableAugmentedEntity = {
assignerId: '',
bufferMin: 0,
checkInDurationMin: 0,
createdAt: '',
updatedAt: '',
startedAt: '',
endedAt: '',
guideId: '',
id: '',
locationId: '',
productId: '',
shiftId: '',
status: 'pending',
}

const useList = () => {
  const {data} = useData()
  const {assignments} = data
  return assignments
}

export const useAssignmentById = (id: string) => {
  return useEntityById(useList, id, DEFAULT_VALUE)
}