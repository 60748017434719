import { Notice } from 'components/Notice';
import { PageHeader } from 'components/PageHeader';

export const View = () => {
  return (
    <>
      <PageHeader title="Resources" />
      <Notice title="Sorry!" text="This feature is not implemented yet." type="warning" />
    </>
  );
}
