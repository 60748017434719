import { ReactNode } from "react"
import cx from 'classnames'

type Props = {
  children: ReactNode
  className?: string
}

export const Card = ({children, className}: Props) => {
  return (
    <div className={cx('border rounded py-1 px-4', className)}>{children}</div>
  )
}