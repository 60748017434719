import {Shifts} from 'common/types'
import {ShiftState} from 'components/ShiftState'
import {shiftHooks} from 'hooks/shifts'
import {ReactComponent as Close} from 'icons/Close.svg'
import {AssignmentPanel} from 'pages/shifts/operator/AssignmentPanel/AssignmentPanel'
import {ResellerBreakdown} from 'pages/shifts/operator/ResellerBreakdown'
import {ShiftCounters} from 'pages/shifts/operator/ShiftCounters'
import {ShiftDetails} from 'pages/shifts/operator/ShiftDetails'
import {useCallback} from 'react'
import {Components} from 'ui'

type Props = {
  shiftId: string
  initialShiftData?: Shifts.Basic.AugmentedEntity
  onClose: () => void
  onChange: () => void
}

export const ShiftViewer = ({shiftId, initialShiftData, onClose, onChange}: Props) => {
  const shiftItemQuery = shiftHooks.useItemQuery(shiftId, {
    initialData: initialShiftData,
  })

  const change = useCallback(() => {
    shiftItemQuery.refetch()
    onChange()
  }, [onChange, shiftItemQuery])

  if (!shiftItemQuery.data) {
    return null
  }

  return (
    <Components.Modal fullscreen onClose={onClose} bodyClassName="!p-0 w-full h-full">
      <div className="w-full h-full bg-white">
        <div className="grid grid-cols-[minmax(250px,1fr),6fr] gap-4 p-4 h-full">
          <div className="flex flex-col gap-4 min-h-0">
            <ShiftState shift={shiftItemQuery.data} className="text-sm" />
            <ShiftCounters shift={shiftItemQuery.data} className="bg-gray-50 border border-gray-200 p-4 rounded-lg" />
            <ResellerBreakdown shift={shiftItemQuery.data} className="bg-gray-50 border border-gray-200 p-4 rounded-lg overflow-y-auto" />
          </div>
          <div className="flex flex-col gap-4 min-h-0 h-full">
            <div className="flex items-center justify-between">
              <ShiftDetails shift={shiftItemQuery.data} />
              <Close className="w-6 h-6 cursor-pointer" onClick={onClose} />
            </div>
            <AssignmentPanel shift={shiftItemQuery.data} onChange={change} />
          </div>
        </div>
      </div>
    </Components.Modal>
  )
}
