import {PageHeader} from 'components/PageHeader'
import {useForgotPasswordMutation} from 'hooks/auth'
import {locationHooks} from 'hooks/locations'
import {useId} from 'hooks/useId'
import {userHooks} from 'hooks/users'
import {isEmpty, pick} from 'lodash-es'
import {Form} from 'react-final-form'
import {useNavigate} from 'react-router-dom'
import {Components, Fields} from 'ui'
import {ToasterProvider} from 'ui/providers'
import {isValidEmail} from 'utils/isValidEmail'
import {ROLE_OPTIONS} from './Create'

export const Edit = () => {
  const navigate = useNavigate()
  const id = useId()
  const itemQuery = userHooks.useItemQuery(id)

  const locationsQuery = locationHooks.useListQuery({orderBy: ['name']})

  const updateMutation = userHooks.useUpdateMutation()
  const forgotPasswordMutation = useForgotPasswordMutation()

  const addMessage = ToasterProvider.useToasterMessageAdder()

  const onSubmit = (values: any) => {
    updateMutation.mutate(
      {...values},
      {
        onSuccess() {
          itemQuery.refetch()
        },
      }
    )
  }

  const onResetPasswordButtonClick = (values: any) => {
    if (!isValidEmail(values.email)) {
      return
    }

    forgotPasswordMutation.mutate(
      {
        email: values.email,
      },
      {
        onSuccess() {
          addMessage({
            message: `Email with instructions to reset password was successfully sent`,
            type: 'success',
          })
        },
      }
    )
  }

  const onEditButtonClick = () => {
    navigate(`/guides/${id}/edit`)
  }

  const onCloneButtonClick = () => {
    if (!itemQuery.data) {
      throw new Error(`Can't access user data`)
    }

    navigate(`/users/create?clone=${encodeURIComponent(JSON.stringify(pick(itemQuery.data, 'roles', 'locationIds')))}`)
  }

  if (!itemQuery.data || !locationsQuery.data) {
    return null
  }

  const locationOptions = locationsQuery.data.map((location) => {
    return {
      label: location.name,
      value: location.id,
    }
  })

  return (
    <div className="flex flex-col gap-6">
      <PageHeader title={`${itemQuery.data.firstName} ${itemQuery.data.lastName}`} subtitle="Edit User">
        {itemQuery.data.roles.includes('guideUser') && (
          <div className="flex gap-4 ml-auto">
            <Components.Button outline onClick={onCloneButtonClick} size="sm">
              Clone user
            </Components.Button>
            <Components.Button outline onClick={onEditButtonClick} size="sm">
              Edit guide
            </Components.Button>
          </div>
        )}
      </PageHeader>

      <Form
        initialValues={itemQuery.data}
        onSubmit={onSubmit}
        render={({handleSubmit, pristine, values}) => {
          const disableSave =
            pristine ||
            isEmpty(values.firstName) ||
            isEmpty(values.lastName) ||
            isEmpty(values.email) ||
            isEmpty(values.locationIds) ||
            isEmpty(values.roles)

          const disableForgotPassword = isEmpty(values.email) || !isValidEmail(values.email)

          return (
            <form onSubmit={handleSubmit} className="flex flex-col gap-4">
              <Fields.InputField label="First name" name="firstName" expanded />
              <Fields.InputField label="Last name" name="lastName" expanded />
              <Fields.InputField label="Email" name="email" expanded />
              <Fields.MultiSelectField label="Roles" name="roles" options={ROLE_OPTIONS} expanded />
              <Fields.MultiSelectField label="Locations" name="locationIds" options={locationOptions} expanded />

              <div className="flex gap-4 justify-end">
                <Components.Button disabled={disableForgotPassword} onClick={() => onResetPasswordButtonClick(values)} type="button">
                  Reset password
                </Components.Button>

                <Components.Button disabled={disableSave}>Save</Components.Button>
              </div>
            </form>
          )
        }}
      />
    </div>
  )
}
