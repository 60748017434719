import {ReactNode, useEffect} from 'react'
import {useAppContext} from './AppContext'

type Props = {
  children?: ReactNode
  title: string
  subtitle?: string
}

export const PageHeader = ({children, title, subtitle}: Props) => {
  const appContext = useAppContext()

  useEffect(() => {
    appContext.setData({
      title: title,
      children: children,
      subtitle: subtitle,
    })
  }, [children, title, subtitle])

  return null
}
