import {Field} from 'react-final-form'
import {CheckboxValue} from 'ui/components/Checkbox'
import {Checkbox} from '../components'

type Props = {
  id?: string
  label?: string
  name: string
  disabled?: boolean
  indeterminate?: boolean
  className?: string
  initialValue?: boolean
}

const parse = (value: string) => {
  return value === 'indeterminate' ? undefined : value === 'checked'
}

const format = (value: boolean | undefined) => {
  return value === undefined ? 'indeterminate' : value ? 'checked' : 'unchecked'
}

export const CheckboxField = ({id, label, name, disabled, className, initialValue, indeterminate}: Props) => {
  return (
    <Field
      type="text"
      name={name}
      initialValue={initialValue}
      parse={parse}
      format={format}
      render={({input}) => {
        return (
          <Checkbox
            {...input}
            value={input.value as CheckboxValue}
            id={id}
            label={label}
            disabled={disabled}
            className={className}
            indeterminate={indeterminate}
          />
        )
      }}
    />
  )
}
