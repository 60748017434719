import {DATE_FORMAT} from 'consts'
import moment from 'moment'
import {DatePicker} from 'ui/components'
import {ControlSize} from 'ui/types'

type Props = {
  value: string
  onChange: (value: string) => void
  size?: ControlSize
}

export const getDefaultDayPeriodId = (): string => {
  return moment().format(DATE_FORMAT)
}

export const getDayPeriodIdByDate = (date: string): string => {
  return moment(date).format(DATE_FORMAT)
}

export const DayFilter = ({value, onChange, size = 'sm'}: Props) => {
  return <DatePicker size={size} value={value} onChange={(event) => onChange(event.target.value)} />
}
