import {unavailabilityDescriptorApi} from 'api'
import {UnavailabilityDescriptors} from 'common/types'
import {Utils} from 'ui'

export const unavailabilityDescriptorHooks = new Utils.CrudHooks<
  never,
  never,
  UnavailabilityDescriptors.Params.Create,
  UnavailabilityDescriptors.Params.Update,
  never,
  UnavailabilityDescriptors.Responses.Item,
  UnavailabilityDescriptors.Responses.Create,
  UnavailabilityDescriptors.Responses.Update
>('unavailabilityDescriptor', 'Unavailability', unavailabilityDescriptorApi)
