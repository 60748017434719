import {RefObject, useEffect, useState} from 'react'

type DropdownStyles = {
  height?: string
  top?: string
  bottom?: string
}

const getDropdownStyles = (input: HTMLUnknownElement | null, maxDropdownHeight?: number) => {
  if (!input) {
    return {
      top: '0px',
      maxHeight: `${maxDropdownHeight}px`,
    }
  }

  const inputRect = input.getBoundingClientRect()

  const toScreenTop = inputRect.top
  const toScreenBottom = document.documentElement.clientHeight - inputRect.bottom
  const inputHeight = inputRect.height
  const maxDistanceToScreenBorder = Math.max(toScreenTop, toScreenBottom)
  const maxAdaptedDropdownHeight =
    maxDropdownHeight === undefined ? maxDistanceToScreenBorder : Math.min(maxDistanceToScreenBorder, maxDropdownHeight)
  const anchorStyle = maxAdaptedDropdownHeight <= toScreenBottom ? {top: '0px'} : {bottom: `${inputHeight}px`}

  return {
    ...anchorStyle,
    maxHeight: `${maxAdaptedDropdownHeight}px`,
  }
}

export const useDropdownStyle = (inputRef: RefObject<HTMLUnknownElement>, maxDropdownHeight?: number) => {
  const [dropdownStyles, setDropdownStyles] = useState<DropdownStyles>(getDropdownStyles(inputRef.current))

  useEffect(() => {
    const input = inputRef.current

    if (!input) {
      return
    }

    const resizeObserver = new ResizeObserver(() => {
      setDropdownStyles(getDropdownStyles(input, maxDropdownHeight))
    })

    resizeObserver.observe(input)

    const onScroll = () => {
      setDropdownStyles(getDropdownStyles(input, maxDropdownHeight))
    }

    const onResize = () => {
      setDropdownStyles(getDropdownStyles(input, maxDropdownHeight))
    }

    window.addEventListener('scroll', onScroll)
    window.addEventListener('resize', onResize)

    return () => {
      window.removeEventListener('scroll', onScroll)
      window.removeEventListener('resize', onResize)

      if (input) {
        resizeObserver.unobserve(input)
      }
    }
  }, [inputRef, maxDropdownHeight])

  return dropdownStyles
}
