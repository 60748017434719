import {ReactNode, useRef} from 'react'
import {FocusMark} from './FocusMark'

type Props = {
  borderColor: string
  backgroundColor: string
  children: ReactNode
  onClick?: () => void
  focus?: boolean
  opacity: string
}
export const EXPANDED_INTERVAL_WIDTH = 210

export const Preview = ({borderColor, backgroundColor, children, onClick, focus, opacity}: Props) => {
  const wrapperRef = useRef<HTMLDivElement | null>(null)
  const containerRef = useRef<HTMLDivElement | null>(null)

  const onMouseEnter = () => {
    if (!wrapperRef.current || !containerRef.current) {
      return
    }

    const screenWidth = document.body.clientWidth
    const rect = wrapperRef.current.getBoundingClientRect()
    const target = containerRef.current

    target.style.minWidth = `${EXPANDED_INTERVAL_WIDTH}px`
    target.style.zIndex = '1'
    target.style.opacity = 'unset'

    if (rect.left + EXPANDED_INTERVAL_WIDTH > screenWidth) {
      target.style.left = `${screenWidth - rect.left - EXPANDED_INTERVAL_WIDTH}px`
    }
  }

  const onMouseLeave = () => {
    if (!containerRef.current) {
      return
    }

    const target = containerRef.current
    target.style.left = '0'
    target.style.zIndex = '0'
    target.style.minWidth = '100%'
    target.style.opacity = opacity
  }

  return (
    <div ref={wrapperRef}>
      <div
        className="relative top-0 left-0 w-full"
        ref={containerRef}
        style={{ opacity }}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <div
          className="cursor-pointer w-full flex border-2"
          style={{
            borderColor,
            backgroundColor,
          }}
        >
          <div className="flex items-center w-full" onClick={onClick}>
            {focus && <FocusMark className="p-0.5" />}
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}
