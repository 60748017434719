import {Field} from 'react-final-form'
import {Radio} from '../components'

type Props = {
  id?: string
  label?: string
  name: string
  value: string
  disabled?: boolean
  className?: string
}

export const RadioField = ({id, label, name, value, disabled, className}: Props) => {
  return (
    <Field
      type="radio"
      name={name}
      value={value}
      render={({input}) => {
        return <Radio {...input} id={id} label={label} disabled={disabled} className={className} />
      }}
    />
  )
}
