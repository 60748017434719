import {assignmentApi, notificationApi, transferApi} from 'api'
import {Assignments, Notifications, Transfers} from 'common/types'
import { STALE_TIME } from 'consts'
import {compact, map} from 'lodash-es'
import {UserProvider} from 'providers/UserProvider'
import {useQuery} from 'react-query'
import {DEFAULT_LIMIT} from 'ui/components/Paginator'

type Data = {
  newNotifications: Notifications.Basic.StorableEntity<Notifications.Basic.Details>[]
  oldNotifications: Notifications.Basic.StorableEntity<Notifications.Basic.Details>[]
  assignments: Assignments.Basic.StorableAugmentedEntity[]
  transfers: Transfers.Basic.StorableEntity[]
}

const DEFAULT_DATA: Data = {
  newNotifications: [],
  oldNotifications: [],
  assignments: [],
  transfers: [],
}

const useReceivers = () => {
  const user = UserProvider.useUser()
  const receivers = []

  if (user.roles.includes('operationsUser') || user.roles.includes('operationsAdmin') || user.roles.includes('superAdmin')) {
    for (const locationId of user.locationIds) {
      receivers.push(`operator_${locationId}`)
    }
  }

  if (user.roles.includes('guideUser')) {
    receivers.push(`guide_${user.id}`)
  }

  return receivers
}

export const useData = () => {
  const receivers = useReceivers()

  const query = useQuery(['notificationData'], async () => {
    const [newNotifications, oldNotifications] = await Promise.all([
      notificationApi.list({
        orderBy: ['createdAt'],
        where: [
          {field: 'receiver', values: receivers},
          {field: 'isSeen', value: false},
        ],
      }),
      notificationApi.list({
        orderBy: ['createdAt'],
        where: [
          {field: 'receiver', values: receivers},
          {field: 'isSeen', value: true},
        ],
        limit: DEFAULT_LIMIT,
      }),
    ])

    const notifications = [...newNotifications, ...oldNotifications]

    const assignmentIds = compact(map(notifications, 'details.assignmentId'))
    const transferIds = compact(map(notifications, 'details.transferId'))

    const [assignments, transfers] = await Promise.all([
      assignmentApi.list({where: [{field: 'id', values: assignmentIds}]}),
      transferApi.list({where: [{field: 'id', values: transferIds}]}),
    ])

    return {
      newNotifications,
      oldNotifications,
      assignments,
      transfers,
    }
  }, {
    staleTime: STALE_TIME,
  })

  return {
    data: query.data || DEFAULT_DATA,
    refetch: query.refetch,
  }
}
