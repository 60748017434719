import {Params, Responses} from 'common/types/assignments'
import {Utils} from 'ui'
import {axiosInstance} from './getInstance'

export class CrudApi extends Utils.CrudApi<
  Params.Count,
  Params.List,
  Params.Create,
  never,
  Responses.List,
  Responses.Item,
  Responses.Create,
  never
> {
  async listOfRequestedForTransfer(params: Params.ListOfRequestedForTransfer): Promise<Responses.ListOfRequestedForTransfer> {
    const result = await this.axiosInstance.post<Responses.ListOfRequestedForTransfer>(this.entityApiPath + '/list/requestedForTransfer', params)
    return result.data
  }

  async bulkChange(params: Params.BulkChange): Promise<void> {
    await this.axiosInstance.post<void>(this.entityApiPath + '/bulkChange', params)
  }

  async autoAssign(params: Params.AutoAssign): Promise<void> {
    await this.axiosInstance.post<void>(this.entityApiPath + '/autoAssign', params)
  }

  async accept(id: string): Promise<void> {
    await this.axiosInstance.post<void>(`${this.entityApiPath}/${id}/accept`)
  }

  async decline(id: string): Promise<void> {
    await this.axiosInstance.post<void>(`${this.entityApiPath}/${id}/decline`)
  }
}

export const crudApi = new CrudApi('/assignments', axiosInstance)
