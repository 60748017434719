import {CategoryFilter} from 'components/filters/CategoryFilter'
import {ProductFilter} from 'components/filters/ProductFilter'
import {GuideManager} from 'hooks/scheduling/GuideManager'
import {ProductManager} from 'hooks/scheduling/ProductManager'
import {UserProvider} from 'providers/UserProvider'
import {Types} from 'ui'

export type FiltersValue = {
  productIds: string[]
  categories: string[]
}

type Props = {
  value: FiltersValue
  onChange: (value: FiltersValue) => void
}

const CATEGORY_OPTIONS: Types.SelectOptions = [
  {
    label: 'Confirmed',
    value: 'accepted',
  },
  {
    label: 'Pending',
    value: 'pending',
  },
]

export const DEFAULT_FILTERS: FiltersValue = {
  productIds: [],
  categories: [],
}

export const Filters = ({value, onChange}: Props) => {
  const user = UserProvider.useUser()
  const guide = GuideManager.useById(user.id)
  const productOptions = ProductManager.useOptions((product) => {
    return guide.productIds.includes(product.id)
  })

  return (
    <div className="flex gap-5 items-center">
      <ProductFilter
        options={productOptions}
        productIds={value.productIds}
        onChange={(productIds) => onChange({...value, productIds})}
        className="!w-56"
      />
      <CategoryFilter
        options={CATEGORY_OPTIONS}
        categories={value.categories}
        onChange={(categories) => onChange({...value, categories})}
        className="!w-56"
      />
    </div>
  )
}
