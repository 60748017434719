import {Products} from 'common/types'
import {PageHeader} from 'components/PageHeader'
import {locationHooks} from 'hooks/locations'
import {productHooks} from 'hooks/products'
import {defaultTo, keyBy} from 'lodash-es'
import {useCallback, useMemo} from 'react'
import {Form} from 'react-final-form'
import {useNavigate} from 'react-router-dom'
import {Components, Fields} from 'ui'
import {isEmptyValue} from 'ui/utils'
import {formatCurrency} from 'utils/formatCurrency'
import {BUFFER_OPTIONS, CHECK_IN_DURATION_OPTIONS} from './utils'

type Values = Products.Params.Create

export const Create = () => {
  const navigate = useNavigate()
  const locationsQuery = locationHooks.useListQuery({})

  const createMutate = productHooks.useCreateMutation().mutate

  const submit = useCallback(
    (values: Values) => {
      createMutate(
        {...values},
        {
          onSuccess(product) {
            navigate(`/products/${product.id}/edit`)
          },
        }
      )
    },
    [createMutate, navigate]
  )

  const locationOptions = useMemo(() => {
    return defaultTo(locationsQuery.data, []).map((location) => {
      return {
        label: location.name,
        value: location.id,
      }
    })
  }, [locationsQuery.data])

  if (!locationsQuery.data) {
    return null
  }

  const locationMap = keyBy(locationsQuery.data, 'id')

  return (
    <>
      <PageHeader title="Tours" subtitle="Add Tour" />

      <div className="py-5">
        <Form
          onSubmit={submit}
          render={({handleSubmit, pristine, values}) => {
            const disableSave =
              pristine ||
              isEmptyValue(values.name) ||
              isEmptyValue(values.locationId) ||
              isEmptyValue(values.code) ||
              isEmptyValue(values.color) ||
              isEmptyValue(values.ratio) ||
              isEmptyValue(values.checkInDurationMin) ||
              isEmptyValue(values.bufferMin) ||
              isEmptyValue(values.payment)

            return (
              <form onSubmit={handleSubmit} className="flex flex-col gap-4">
                <Fields.InputField label="Name" name="name" expanded />
                <Fields.SelectField label="Location" name="locationId" options={locationOptions} expanded />
                <Fields.InputField label="Code" name="code" expanded />
                <Fields.InputField label="Color" name="color" expanded />
                <Fields.InputField label="Guests / Guide" name="ratio" type="number" min={0} expanded />
                <Fields.SelectField label="Checkin Duration" name="checkInDurationMin" options={CHECK_IN_DURATION_OPTIONS} expanded />
                <Fields.SelectField label="Buffer" name="bufferMin" options={BUFFER_OPTIONS} expanded />
                <Fields.InputField
                  label={`Payment, ${formatCurrency(locationMap[values.locationId].currency)}`}
                  name="payment"
                  type="number"
                  min={0}
                  parse={(value: string) => parseInt(value, 10) * 100}
                  format={(value: number) => String(value / 100)}
                  expanded
                />

                <Components.Button disabled={disableSave} className="ml-auto">
                  Add Tour
                </Components.Button>
              </form>
            )
          }}
        />
      </div>
    </>
  )
}
