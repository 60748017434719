import {Assignments, Transfers} from 'common/types'
import {Preview} from 'components/Preview'
import { TextEllipsis } from 'components/TextEllipsis'
import { ProductManager } from 'hooks/scheduling/ProductManager'
import { UserProvider } from 'providers/UserProvider'
import {Flag} from 'ui/components'
import {getTourInfo} from 'utils/getTourInfo'
import {isOutdatedEvent} from 'utils/isOutdatedEvent'
import {isCloseEvent} from 'utils/isCloseEvent'

type Props = {
  assignment: Assignments.Basic.StorableAugmentedEntity
  transfer: Transfers.Basic.StorableEntity | null
  onClick?: () => void
  focus?: boolean
}

export const AssignmentPreview = ({assignment, transfer, onClick, focus}: Props) => {
  const user = UserProvider.useUser()
  const product = ProductManager.useById(assignment.productId)
  const isCloseAssignment = isCloseEvent(assignment.startedAt)

  return (
    <Preview
      borderColor={product.color}
      backgroundColor="white"
      onClick={onClick}
      focus={focus}
      opacity={isOutdatedEvent(assignment) ? '50%' : 'unset'}
    >
      <div className="flex flex-col w-full p-0.5 leading-[1.35] min-w-0 text-[11px] gap-1">
        {assignment.status === 'accepted' && !transfer && <Flag className="bg-green-500"><TextEllipsis>ACCEPTED</TextEllipsis></Flag>}
        {assignment.status === 'accepted' && transfer && user.id === transfer.fromGuideId && <Flag className="bg-blue-500"><TextEllipsis>TRANSFER REQUESTED BY ME</TextEllipsis></Flag>}
        {assignment.status === 'accepted' && transfer && user.id === transfer.toGuideId && <Flag className="bg-purple-500"><TextEllipsis>TRANSFER REQUESTED TO ME</TextEllipsis></Flag>}
        {assignment.status === 'pending' && isCloseAssignment && <Flag className="bg-red-500"><TextEllipsis>PENDING</TextEllipsis></Flag>}
        {assignment.status === 'pending' && !isCloseAssignment && <Flag className="bg-yellow-500"><TextEllipsis>PENDING</TextEllipsis></Flag>}
        <div className="flex flex-col gap-0.5 justify-between text-[11px] leading-tight">
          <TextEllipsis className="font-semibold">{getTourInfo(assignment, product)}</TextEllipsis>
        </div>
      </div>
    </Preview>
  )
}
