import cx from 'classnames'
import {Shifts} from 'common/types'
import {Storable} from 'common/types/utils'
import {InfoBlock} from 'components/InfoBlock/InfoBlock'

type Props = {
  shift: Storable<Shifts.Basic.AugmentedEntity>
  className?: string
}

export const ShiftCounters = ({shift, className}: Props) => {
  return (
    <div className={cx('flex flex-col text-sm w-full gap-4', className)}>
      <div className="font-bold">Shift Counters</div>
      <InfoBlock>
        <InfoBlock.Item label="Total Pax">{shift.confirmedGuestQty}</InfoBlock.Item>
        <InfoBlock.Item label="Guides Needed">{shift.requiredGuideQty}</InfoBlock.Item>
        <InfoBlock.Item label="Guides Assigned">{shift.assignedGuideQty}</InfoBlock.Item>
        <InfoBlock.Item label="Guides Confirmed">{shift.acceptedAssignmentQty}</InfoBlock.Item>
        <InfoBlock.Item label="Guides Pending">{shift.pendingAssignmentQty}</InfoBlock.Item>
      </InfoBlock>
    </div>
  )
}
