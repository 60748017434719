import {Products} from 'common/types'
import {PageHeader} from 'components/PageHeader'
import {locationHooks} from 'hooks/locations'
import {productHooks} from 'hooks/products'
import {useId} from 'hooks/useId'
import {defaultTo, keyBy, omit} from 'lodash-es'
import {useCallback, useMemo} from 'react'
import {Form} from 'react-final-form'
import {Components, Fields} from 'ui'
import {isEmptyValue} from 'ui/utils'
import {formatCurrency} from 'utils/formatCurrency'
import {BUFFER_OPTIONS, CHECK_IN_DURATION_OPTIONS} from './utils'

type Values = Products.Params.Update

export const Edit = () => {
  const id = useId()
  const itemQuery = productHooks.useItemQuery(id)
  const locationsQuery = locationHooks.useListQuery({})

  const updateMutate = productHooks.useUpdateMutation().mutate

  const submit = useCallback(
    (values: Values) => {
      updateMutate(omit(values, 'code', 'locationId'), {
        onSuccess() {
          itemQuery.refetch()
        },
      })
    },
    [updateMutate, itemQuery]
  )

  const locationOptions = useMemo(() => {
    return defaultTo(locationsQuery.data, []).map((location) => {
      return {
        label: location.name,
        value: location.id,
      }
    })
  }, [locationsQuery.data])

  if (!itemQuery.data || !locationsQuery.data) {
    return null
  }

  const locationMap = keyBy(locationsQuery.data, 'id')

  return (
    <div className="flex flex-col gap-6">
      <PageHeader title={itemQuery.data.name} subtitle="Edit Tour" />

      <Form
        initialValues={itemQuery.data}
        onSubmit={submit}
        render={({handleSubmit, pristine, values}) => {
          const disableSave =
            pristine ||
            isEmptyValue(values.name) ||
            isEmptyValue(values.color) ||
            isEmptyValue(values.ratio) ||
            isEmptyValue(values.checkInDurationMin) ||
            isEmptyValue(values.bufferMin) ||
            isEmptyValue(values.payment)

          return (
            <form onSubmit={handleSubmit} className="flex flex-col gap-4">
              <Fields.InputField label="Name" name="name" expanded />
              <Fields.SelectField label="Location" name="locationId" options={locationOptions} expanded disabled />
              <Fields.InputField label="Code" name="code" expanded disabled />
              <Fields.InputField label="Color" name="color" expanded />
              <Fields.InputField label="Guests / Guide" name="ratio" type="number" expanded />
              <Fields.SelectField label="Checkin Duration" name="checkInDurationMin" options={CHECK_IN_DURATION_OPTIONS} expanded />
              <Fields.SelectField label="Buffer" name="bufferMin" options={BUFFER_OPTIONS} expanded />
              <Fields.InputField
                label={`Payment, ${formatCurrency(locationMap[itemQuery.data.locationId].currency)}`}
                name="payment"
                type="number"
                min={0}
                parse={(value: string) => parseInt(value, 10) * 100}
                format={(value: number) => String(value / 100)}
                expanded
              />

              <Components.Button disabled={disableSave} className="ml-auto">
                Save
              </Components.Button>
            </form>
          )
        }}
      />
    </div>
  )
}
