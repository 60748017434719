import {DATE_FORMAT} from 'consts'
import moment from 'moment'
import {MonthPicker} from 'ui/components/MonthPicker'
import {ControlSize} from 'ui/types'

type Props = {
  value: string
  onChange: (value: string) => void
  size?: ControlSize
}

export const getDefaultMonthPeriodId = (): string => {
  return moment().date(1).format(DATE_FORMAT)
}

export const MonthFilter = ({value, onChange, size = 'sm'}: Props) => {
  return <MonthPicker size={size} value={value} onChange={(event) => onChange(event.target.value)} />
}
