import {PropsWithChildren, createContext, useContext, useState} from 'react'

export const AppContext = createContext<any>(null)

export const AppProvider = ({children}: PropsWithChildren) => {
  const [data, setData] = useState({title: '', subtitle: '', children: null})

  return <AppContext.Provider value={{data, setData}}>{children}</AppContext.Provider>
}

export const useAppContext = () => {
  return useContext(AppContext)
}
