import {Shifts} from 'common/types'
import {Storable} from 'common/types/utils'
import {Flag} from 'ui/components'
import {isCloseEvent} from 'utils/isCloseEvent'
import {TextEllipsis} from './TextEllipsis'
import cx from 'classnames'

type Props = {
  shift: Storable<Shifts.Basic.AugmentedEntity>
  className?: string
}

export const ShiftState = ({shift, className}: Props) => {
  const colorClass = isCloseEvent(shift.startedAt) ? 'bg-red-500' : 'bg-yellow-500'

  switch (shift.schedulingStatus) {
    case 'pending':
      return (
        <Flag className={cx(colorClass, className)}>
          <TextEllipsis>UNCONFIRMED GUIDES</TextEllipsis>
        </Flag>
      )
    case 'guides_needed':
      return (
        <Flag className={cx(colorClass, className)}>
          <TextEllipsis>GUIDES NEEDED</TextEllipsis>
        </Flag>
      )
    case 'too_many_guides':
      return (
        <Flag className={cx(colorClass, className)}>
          <TextEllipsis>TOO MANY GUIDES</TextEllipsis>
        </Flag>
      )
    case 'confirmed':
      return (
        <Flag className={cx("bg-green-500", className)}>
          <TextEllipsis>GUIDES CONFIRMED</TextEllipsis>
        </Flag>
      )
  }
}
