import {AppProvider} from 'components/AppContext'
import {AppHeader} from 'components/AppHeader'
import {DesktopNavbar} from 'components/navbar/DesktopNavbar'
import {MobileNavbar} from 'components/navbar/MobileNavbar'
import {ForgotPassword} from 'pages/ForgotPassword'
import {ResetPassword} from 'pages/ResetPassword'
import {SignIn} from 'pages/SignIn'
import * as Guides from 'pages/guides'
import * as Locations from 'pages/locations'
import * as Notifications from 'pages/notifications'
import * as Payouts from 'pages/payouts'
import * as Products from 'pages/products'
import * as Reporting from 'pages/reporting'
import * as Resources from 'pages/resources'
import * as Shifts from 'pages/shifts'
import * as Transfers from 'pages/transfers'
import * as Users from 'pages/users'
import {UserProvider} from 'providers/UserProvider'
import {PropsWithChildren, useState} from 'react'
import {Navigate, Outlet, Route, Routes} from 'react-router-dom'

const Dashboard = () => {
  return <CommonDashboard fullscreen={false} />
}

const FullScreenDashboard = () => {
  return <CommonDashboard fullscreen={true} />
}

const CommonDashboard = ({fullscreen = false}: PropsWithChildren & {fullscreen: boolean}) => {
  const [showMobileNav, setShowMobileNav] = useState(true)

  let content = <Outlet />

  if (!fullscreen) {
    content = (
      <>
        <div className="m-auto p-5">
          <Outlet />
        </div>
      </>
    )
  }

  return (
    <div className="flex w-full min-h-screen">
      <DesktopNavbar />

      <div className="flex flex-col grow w-full">
        <div className="w-full">
          <AppHeader showNav={setShowMobileNav} />
          {content}
        </div>
      </div>

      <MobileNavbar className={showMobileNav ? '' : 'hidden'} setShow={setShowMobileNav} />
    </div>
  )
}

const AdminRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<FullScreenDashboard />}>
        <Route path="shifts/scheduling" element={<Shifts.Scheduling />} />
        <Route path="shifts/assigned" element={<Shifts.ToursCalendar />} />
        <Route path="" element={<Navigate to="/shifts/scheduling" replace={true} />} />
      </Route>
      <Route path="/" element={<Dashboard />}>
        <Route path="notifications" element={<Notifications.List />} />

        <Route path="locations" element={<Locations.List />} />
        <Route path="locations/create" element={<Locations.Create />} />
        <Route path="locations/:id/edit" element={<Locations.Edit />} />

        <Route path="products" element={<Products.List />} />
        <Route path="products/create" element={<Products.Create />} />
        <Route path="products/:id/edit" element={<Products.Edit />} />

        <Route path="users" element={<Users.List />} />
        <Route path="users/create" element={<Users.Create />} />
        <Route path="users/:id/edit" element={<Users.Edit />} />

        <Route path="guides" element={<Guides.List />} />
        <Route path="guides/create" element={<Guides.Create />} />
        <Route path="guides/:id/edit" element={<Guides.Edit />} />

        <Route path="payouts" element={<Payouts.View />} />
        <Route path="resources" element={<Resources.View />} />
        <Route path="reporting" element={<Reporting.View />} />
      </Route>
    </Routes>
  )
}

const OperatorRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<FullScreenDashboard />}>
        <Route path="shifts/scheduling" element={<Shifts.Scheduling />} />
        <Route path="shifts/assigned" element={<Shifts.ToursCalendar />} />
        <Route path="" element={<Navigate to="/shifts/scheduling" replace={true} />} />
      </Route>
      <Route path="/" element={<Dashboard />}>
        <Route path="notifications" element={<Notifications.List />} />

        <Route path="locations" element={<Locations.List />} />
        <Route path="locations/:id/edit" element={<Locations.Edit />} />

        <Route path="products" element={<Products.List />} />
        <Route path="products/:id/edit" element={<Products.Edit />} />

        <Route path="guides" element={<Guides.List />} />
        <Route path="guides/create" element={<Guides.Create />} />
        <Route path="guides/:id/edit" element={<Guides.Edit />} />

        <Route path="payouts" element={<Payouts.View />} />
        <Route path="resources" element={<Resources.View />} />
        <Route path="reporting" element={<Reporting.View />} />
      </Route>
    </Routes>
  )
}

const GuideRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<FullScreenDashboard />}>
        <Route path="/shifts/assigned" element={<Shifts.ToursCalendar />} />
        <Route path="" element={<Navigate to="/shifts/assigned" replace={true} />} />
      </Route>
      <Route path="/" element={<Dashboard />}>
        <Route path="notifications" element={<Notifications.List />} />
        <Route path="transfers" element={<Transfers.List />} />
        <Route path="payouts" element={<Payouts.View />} />
        <Route path="resources" element={<Resources.View />} />
      </Route>
    </Routes>
  )
}

const AppRoutes = () => {
  const user = UserProvider.useUser()

  if (user.roles.includes('superAdmin')) {
    return <AdminRoutes />
  }

  if (user.roles.includes('operationsUser')) {
    return <OperatorRoutes />
  }

  if (user.roles.includes('guideUser')) {
    return <GuideRoutes />
  }

  return null
}

export const Routing = () => {
  return (
    <Routes>
      <Route path="/signIn" element={<SignIn />} />
      <Route path="/forgotPassword" element={<ForgotPassword />} />
      <Route path="/resetPassword/:token" element={<ResetPassword />} />
      <Route
        path="/*"
        element={
          <UserProvider>
            <AppProvider>
              <AppRoutes />
            </AppProvider>
          </UserProvider>
        }
      />
    </Routes>
  )
}
