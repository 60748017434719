import {Shifts} from 'common/types'
import {SingleCondition} from 'common/types/utils'
import {assignmentHooks} from 'hooks/assignments'
import {shiftHooks} from 'hooks/shifts'
import {isEmpty} from 'lodash-es'
import moment from 'moment'
import {FiltersValue} from 'pages/shifts/operator/Filters'
import {UserProvider} from 'providers/UserProvider'
import {useCallback, useMemo, useState} from 'react'
import {Period} from 'types'

type Params = {
  periodId: string
  groupFilters: FiltersValue
  period: Period
}

export const useShifts = ({periodId, period, groupFilters}: Params) => {
  const user = UserProvider.useUser()
  const [effectivePeriodId, setEffectivePeriodId] = useState(periodId)

  const from = moment(periodId).format()
  const to = moment(periodId).add(1, period).subtract(1, 'second').format()

  const commonFilters: SingleCondition<Shifts.Basic.AugmentedEntity>[] = useMemo(
    () => [
      {field: 'startedAt', op: 'between', start: from, end: to},
      {field: 'areThereConfirmedBookings', value: true},
      {field: 'locationId', values: isEmpty(groupFilters.locationIds) ? user.locationIds : groupFilters.locationIds},
      {field: 'productId', values: groupFilters.productIds, skip: isEmpty(groupFilters.productIds)},
      {field: 'guides', op: '@>', values: groupFilters.guideHandles, skip: isEmpty(groupFilters.guideHandles)},
      {field: 'schedulingStatus', values: groupFilters.categories, skip: isEmpty(groupFilters.categories)},
    ],
    [from, to, groupFilters, user]
  )

  const shiftListQuery = shiftHooks.useListQuery(
    {
      where: [{field: 'startedAt', op: 'between', start: from, end: to}, ...commonFilters],
      orderBy: ['startedAt'],
    },
    {
      keepPreviousData: true,
      onSuccess: () => setEffectivePeriodId(periodId),
    }
  )

  const autoAssignMutate = assignmentHooks.useAutoAssignMutation().mutate

  const autoAssign = useCallback(() => {
    const now = moment().format()
    autoAssignMutate(
      {
        where: [{field: 'startedAt', op: 'between', start: now, end: to}, ...commonFilters],
      },
      {
        onSuccess: () => shiftListQuery.refetch(),
      }
    )
  }, [autoAssignMutate, commonFilters, shiftListQuery, to])

  return {
    shifts: shiftListQuery.data || [],
    refetch: shiftListQuery.refetch,
    effectivePeriodId,
    autoAssign,
  }
}
