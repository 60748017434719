import {Params, Responses} from 'common/types/transfers'
import {Utils} from 'ui'
import {axiosInstance} from './getInstance'

export class CrudApi extends Utils.CrudApi<never, Params.List, Params.Create, never, Responses.List, Responses.Item, Responses.Create, never> {
  async accept(id: string): Promise<void> {
    await this.axiosInstance.post<void>(`${this.entityApiPath}/${id}/accept`)
  }

  async decline(id: string): Promise<void> {
    await this.axiosInstance.post<void>(`${this.entityApiPath}/${id}/decline`)
  }
}

export const crudApi = new CrudApi('/transfers', axiosInstance)
