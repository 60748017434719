import {isValidEmail} from 'utils/isValidEmail'
import logo from 'logo.png'
import {Form} from 'react-final-form'
import {Components, Fields} from 'ui'
import {Link} from 'components/Link'
import {useForgotPasswordMutation} from 'hooks/auth'
import {Params} from 'common/types/auth'
import {ReactNode, useState} from 'react'

type PageState = {
  type: 'success' | 'error' | 'input'
}

export const ForgotPassword = () => {
  const [pageState, setPageState] = useState<PageState>({type: 'input'})

  const forgotPasswordMutation = useForgotPasswordMutation()

  const onSubmit = async (values: Params.ForgotPassword) => {
    forgotPasswordMutation.mutate(
      {
        email: values.email.trim()
      },
      {
        onSuccess() {
          setPageState({
            type: 'success'
          })
        },
        onError(error) {
          console.error(error);

          setPageState({
            type: 'error'
          })
        }
      }
    )
  }

  const renderForm = (): ReactNode => {
    return <Form
      onSubmit={onSubmit}
      render={({handleSubmit, pristine, values}) => {
        const disableReset = pristine || !isValidEmail(values.email)

        return (
          <form onSubmit={handleSubmit} className="flex flex-col gap-4 w-full">
            <Fields.InputField label="Email" name="email" expanded />

            <Components.Button disabled={disableReset} className="" expanded>
              Reset password
            </Components.Button>
          </form>
        )
      }}
    />
  }

  const renderSuccessMessage = (): ReactNode => {
    return (<div className="text-center">We have e-mailed your password reset link</div>)
  }

  const renderErrorMessage = (): ReactNode => {
    return (<div className="text-center">Unfortunately we were unable to send you an email</div>)
  }

  const renderContent = (): ReactNode => {
    switch (pageState.type) {
      case 'success':
        return renderSuccessMessage();
      case 'error':
        return renderErrorMessage();
      default:
        return renderForm();
    }
  }

  return (
    <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-50">
      <div className="mx-4 bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12 w-96 flex flex-col items-center gap-6">
        <div className="flex flex-col items-center">
          <img src={logo} className="h-12 w-12 mb-3" alt="ExperienceFirst logo" />
          <div className="font-bold text-lg">ExperienceFirst Scheduler</div>
        </div>

        { renderContent() }

        <Link
          className='text-sm'
          redirectTo='/signIn'
        >
          Sign In
        </Link>
      </div>
    </div>
  )
}
