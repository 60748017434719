import { Notifications } from "common/types"
import { notificationHooks } from "hooks/notifications"
import { useCallback, useEffect, useState } from "react"

export const useMarkAsSeen = (notification: Notifications.Basic.StorableEntity<Notifications.Basic.Details>, mode: 'manual' | 'auto') => {
  const [isSeen, setIsSeen] = useState(notification.isSeen)
  const markAsSeenMutation = notificationHooks.useMarkAsSeenMutation()
  const markAsSeenMutate = markAsSeenMutation.mutate

  useEffect(() => {
    if (mode === 'auto' && !isSeen) {
      markAsSeenMutate(notification.id, {
        onSuccess: () => setIsSeen(true)
      })
    }
  }, [markAsSeenMutate, isSeen, mode, notification])

  const markAsSeen = useCallback(() => {
    if (!isSeen) {
      markAsSeenMutate(notification.id, {
        onSuccess: () => setIsSeen(true)
      })
    }
  }, [markAsSeenMutate, isSeen, notification])

  return {
    isSeen,
    markAsSeen,
  }
}