import {getDefaultDayPeriodId} from 'components/filters/DayFilter'
import {getDefaultMonthPeriodId} from 'components/filters/MonthFilter'
import {getDefaultWeekPeriodId} from 'components/filters/WeekFilter'
import {useSearchParams} from 'react-router-dom'
import {Period} from 'types'

export const useInitialPeriodId = (view: Period) => {
  const [searchParams] = useSearchParams()

  const periodId = searchParams.get('periodId')

  if (periodId) {
    return periodId
  }

  switch (view) {
    case 'month':
      return getDefaultMonthPeriodId()
    case 'week':
      return getDefaultWeekPeriodId()
    case 'day':
      return getDefaultDayPeriodId()
  }
}
