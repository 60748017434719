import { StorableEventLike } from 'common/types/utils'
import {EXPANDED_INTERVAL_WIDTH} from 'components/Preview'
import {groupBy, keys, sortBy} from 'lodash-es'
import moment from 'moment'
import {ComponentType, Fragment} from 'react'
import {PreviewComponentProps} from 'types'
import cx from 'classnames'

type Props<T extends StorableEventLike> = {
  focusedEventId?: string 
  onSelect: (eventId: string) => void
  events: T[]
  previewComponent: ComponentType<PreviewComponentProps<T>>
  headerClassName?: string
}

export const DayCalendar = <T extends StorableEventLike>({
  focusedEventId,
  events,
  onSelect,
  previewComponent: PreviewComponent,
  headerClassName,
}: Props<T>) => {
  const groupedEvents = groupBy(events, 'startedAt')
  const sortedStartTimes = sortBy(keys(groupedEvents))

  return (
    <div>
      <div className={cx("grid grid-cols-[min-content_auto]", headerClassName)}>
        {sortedStartTimes.map((startTime) => {
          return (
            <Fragment key={startTime}>
              <div className="border-e border-gray-300 px-4 pt-4 text-xs font-bold">{moment(startTime).format('HH:mm')}</div>
              <div className="flex px-4 pt-4 gap-4">
                {groupedEvents[startTime].map((event) => {
                  return (
                    <div key={event.id} style={{flexBasis: `${EXPANDED_INTERVAL_WIDTH}px`}}>
                      <PreviewComponent
                        key={event.id}
                        event={event}
                        focus={event.id === focusedEventId}
                        onClick={() => onSelect(event.id)}
                      />
                    </div>
                  )
                })}
              </div>
            </Fragment>
          )
        })}
        <div className="pt-4 border-e border-gray-300" />
        <div className="pt-4" />
      </div>
    </div>
  )
}
