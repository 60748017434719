/* eslint-disable react-hooks/rules-of-hooks */
import {transferApi} from 'api'
import {CrudApi} from 'api/transfers'
import {Params, Responses} from 'common/types/transfers'
import {useMutation} from 'react-query'
import {Providers, Utils} from 'ui'

class CrudHooks extends Utils.CrudHooks<never, Params.List, Params.Create, never, Responses.List, Responses.Item, Responses.Create, never, CrudApi> {
  useAcceptMutation() {
    const add = Providers.ToasterProvider.useToasterMessageAdder()
    return useMutation<void, unknown, string>((id: string) => this.crudApi.accept(id), {
      onSuccess: () => {
        add({
          message: `Changes applied`,
          type: 'success',
        })
      },
    })
  }

  useDeclineMutation() {
    const add = Providers.ToasterProvider.useToasterMessageAdder()
    return useMutation<void, unknown, string>((id: string) => this.crudApi.decline(id), {
      onSuccess: () => {
        add({
          message: `Changes applied`,
          type: 'success',
        })
      },
    })
  }
}

export const transferHooks = new CrudHooks('transfer', 'Transfer', transferApi)
