import {Notifications} from 'common/types'
import {TourEventCardInfo} from 'components/TourEventCardInfo'
import {useMarkAsSeen} from '../hooks/useMarkAsSeen'
import { useAssignmentById } from '../hooks/useAssignment'
import { NotificationCard } from '../NotificationCard'
import { TransferStatusPill } from 'components/pills/TransferStatusPill'

type Props = {
  notification: Notifications.Basic.StorableEntity<Notifications.Basic.TransferDeclinedDetails>
}

export const TransferDeclined = ({notification}: Props) => {
  const {isSeen} = useMarkAsSeen(notification, 'auto')
  const assignment = useAssignmentById(notification.details.assignmentId)

  return (
    <NotificationCard isSeen={isSeen}>
      <TourEventCardInfo title='Shift Transfer Declined' event={assignment} />
      <TransferStatusPill status="declined" />
    </NotificationCard>
  )
}
