import {range} from 'lodash-es'
import moment from 'moment'

export const getLastMonths = () => {
  const now = moment()
  const start = moment(now).date(1).subtract(5, 'month').startOf('day')

  return range(5).map((i) => {
    return moment(start).add(i, 'months').format('YYYY-MM')
  })
}
