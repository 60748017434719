import { useMemo } from "react"
import { Period } from "types"
import { MonthCalendar } from "components/calendars"
import { DayCalendar } from "components/calendars/DayCalendar"
import { WeekCalendar } from "components/calendars/WeekCalendar"

export const usePeriodCalendarComponent = (period: Period) => {
  return useMemo(() => {
    switch (period) {
      case 'month':
        return MonthCalendar
      case 'week':
        return WeekCalendar
      case 'day':
        return DayCalendar
    }
  }, [period])
}
