import {Shifts} from 'common/types'
import {Storable} from 'common/types/utils'
import {Preview} from 'components/Preview'
import {ShiftState} from 'components/ShiftState'
import {TextEllipsis} from 'components/TextEllipsis'
import {ProductManager} from 'hooks/scheduling/ProductManager'
import {useMemo} from 'react'
import {PreviewComponentProps} from 'types'
import {getBreakdownInfo} from 'utils/getBreakdownInfo'
import {getShiftSummary} from 'utils/getShiftSummary'
import {getTourInfo} from 'utils/getTourInfo'
import {isOutdatedEvent} from 'utils/isOutdatedEvent'

type Props = {
  shift: Storable<Shifts.Basic.AugmentedEntity>
  onClick?: () => void
  focus?: boolean
}

export const ShiftPreview = ({shift, onClick, focus}: Props) => {
  const product = ProductManager.useById(shift.productId)

  return (
    <Preview
      borderColor={product.color}
      backgroundColor="white"
      opacity={isOutdatedEvent(shift) ? '50%' : 'unset'}
      focus={focus}
      onClick={() => onClick && onClick()}
    >
      <div className="flex flex-col w-full p-0.5 leading-[1.35] min-w-0 text-[11px] gap-1">
        <div className="flex flex-col gap-0.5">
          <ShiftState shift={shift} />
        </div>
        <div className="flex flex-col justify-between text-[11px] leading-tight">
          <TextEllipsis className="font-semibold">{getTourInfo(shift, product)}</TextEllipsis>
          <TextEllipsis>{getShiftSummary(shift)}</TextEllipsis>
          <TextEllipsis>{getBreakdownInfo(shift)}</TextEllipsis>
        </div>
      </div>
    </Preview>
  )
}

export const useShiftPreviewComponent = () => {
  return useMemo(() => {
    return function PreviewComponent({event: shift, focus, onClick}: PreviewComponentProps<Shifts.Basic.AugmentedEntity>) {
      return <ShiftPreview key={shift.id} shift={shift} focus={focus} onClick={onClick} />
    }
  }, [])
}
