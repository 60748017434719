import {Notifications} from 'common/types'
import {TourEventCardInfo} from 'components/TourEventCardInfo'
import {GuideManager} from 'hooks/scheduling/GuideManager'
import {useMarkAsSeen} from '../hooks/useMarkAsSeen'
import { useAssignmentById } from '../hooks/useAssignment'
import { NotificationCard } from '../NotificationCard'
import { AssignmentStatusPill } from 'components/pills/AssignmentStatusPill'

type Props = {
  notification: Notifications.Basic.StorableEntity<Notifications.Basic.AssignmentAcceptedDetails>
}

export const AssignmentAccepted = ({notification}: Props) => {
  const {isSeen} = useMarkAsSeen(notification, 'auto')
  const assignment = useAssignmentById(notification.details.assignmentId)
  const guide = GuideManager.useById(assignment.guideId)

  return (
    <NotificationCard isSeen={isSeen}>
      <TourEventCardInfo title={`Shift confirmed by ${guide.firstName} ${guide.lastName}`} event={assignment} />
      <AssignmentStatusPill status='accepted'/>
    </NotificationCard>
  )
}
