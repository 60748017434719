import {LocationFilter} from 'components/filters/LocationFilter'
import {ProductFilter} from 'components/filters/ProductFilter'
import {LocationManager} from 'hooks/scheduling/LocationManager'
import {ProductManager} from 'hooks/scheduling/ProductManager'
import {UserProvider} from 'providers/UserProvider'

export type FiltersValue = {
  productIds: string[]
  locationIds: string[]
}

type Props = {
  value: FiltersValue
  onChange: (value: FiltersValue) => void
}

export const DEFAULT_FILTERS: FiltersValue = {
  productIds: [],
  locationIds: [],
}

export const Filters = ({value, onChange}: Props) => {
  const user = UserProvider.useUser()

  const locationOptions = LocationManager.useOptions((location) => {
    return user.locationIds.includes(location.id)
  })

  const productOptions = ProductManager.useOptions((product) => {
    return value.locationIds.length > 0 ? value.locationIds.includes(product.locationId) : user.locationIds.includes(product.locationId)
  })

  return (
    <div className="flex gap-5 items-center">
      <LocationFilter
        options={locationOptions}
        locationIds={value.locationIds}
        onChange={(locationIds) => onChange({...value, locationIds})}
        className="!w-56"
        size="sm"
      />
      <ProductFilter
        options={productOptions}
        productIds={value.productIds}
        onChange={(productIds) => onChange({...value, productIds})}
        className="!w-56"
        size="sm"
      />
    </div>
  )
}
