export function isSameOrChildOf(a: Node, b: Node): boolean {
  if (a === b || a.parentNode === b) {
    return true
  }

  if (a.parentNode === null) {
    return false
  }

  return isSameOrChildOf(a.parentNode, b)
}
