import cx from 'classnames'
import {ReactNode} from 'react'
import {Card} from 'ui/components/Card'

type Props = {
  children: ReactNode
  className?: string
  isSeen: boolean
}

export const NotificationCard = ({children, className, isSeen}: Props) => {
  return <Card className={cx('flex justify-between items-center text-sm', isSeen ? 'bg-gray-300' : '', className)}>{children}</Card>
}
