import {CategoryFilter} from 'components/filters/CategoryFilter'
import {GuideFilter} from 'components/filters/GuideFilter'
import {LocationFilter} from 'components/filters/LocationFilter'
import {ProductFilter} from 'components/filters/ProductFilter'
import {GuideManager} from 'hooks/scheduling/GuideManager'
import {LocationManager} from 'hooks/scheduling/LocationManager'
import {ProductManager} from 'hooks/scheduling/ProductManager'
import {intersection} from 'lodash-es'
import {Types} from 'ui'

export type FiltersValue = {
  locationIds: string[]
  productIds: string[]
  guideHandles: string[]
  categories: string[]
}

type Props = {
  value: FiltersValue
  onChange: (value: FiltersValue) => void
}

const CATEGORY_OPTIONS: Types.SelectOptions = [
  {
    label: 'Confirmed',
    value: 'confirmed',
  },
  {
    label: 'Pending',
    value: 'pending',
  },
  {
    label: 'Guides Needed',
    value: 'guides_needed',
  },
  {
    label: 'Too Many Guides',
    value: 'too_many_guides',
  },
]

export const DEFAULT_FILTERS: FiltersValue = {
  productIds: [],
  locationIds: [],
  guideHandles: [],
  categories: [],
}

export const Filters = ({value, onChange}: Props) => {
  const locationOptions = LocationManager.useOptions()

  const productOptions = ProductManager.useOptions((product) => {
    return value.locationIds.length === 0 || value.locationIds.includes(product.locationId)
  })

  const guideOptions = GuideManager.useOptions((guide) => {
    return (
      (value.locationIds.length === 0 || intersection(value.locationIds, guide.locationIds).length > 0) &&
      (value.productIds.length === 0 || intersection(value.productIds, guide.productIds).length > 0)
    )
  })

  return (
    <div className="flex gap-5 items-top w-full">
      <LocationFilter
        options={locationOptions}
        locationIds={value.locationIds}
        onChange={(locationId) => onChange({...value, locationIds: locationId})}
        expanded
      />
      <ProductFilter options={productOptions} productIds={value.productIds} onChange={(productIds) => onChange({...value, productIds})} expanded />
      <GuideFilter
        options={guideOptions}
        guideHandles={value.guideHandles}
        onChange={(guideHandles) => onChange({...value, guideHandles})}
        expanded
      />
      <CategoryFilter options={CATEGORY_OPTIONS} categories={value.categories} onChange={(categories) => onChange({...value, categories})} expanded />
    </div>
  )
}
