
import { useSearchParams } from "react-router-dom";
import { Period } from "types";

export const useInitialView = () => {
  const [searchParams] = useSearchParams();

  const view = searchParams.get('view')

  if (view && ['month', 'week', 'day'].includes(view)) {
    return view as Period
  }

  return 'month' as Period
}