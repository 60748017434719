/* eslint-disable react-hooks/rules-of-hooks */
import {assignmentApi} from 'api'
import {CrudApi} from 'api/assignments'
import {Params, Responses} from 'common/types/assignments'
import { omit } from 'lodash-es'
import {useMutation, useQuery} from 'react-query'
import {Providers, Utils} from 'ui'
import { QueryOptions, WithAdditionalQueryKeyPart } from 'ui/types'

class CrudHooks extends Utils.CrudHooks<
  Params.Count,
  Params.List,
  Params.Create,
  never,
  Responses.List,
  Responses.Item,
  Responses.Create,
  never,
  CrudApi
> {
  useListOfRequestedForTransferQuery(params: Params.ListOfRequestedForTransfer, options?: WithAdditionalQueryKeyPart<QueryOptions<Responses.ListOfRequestedForTransfer>>) {
    return useQuery<Responses.ListOfRequestedForTransfer>(
      [this.entityKey, 'listOfRequestedForTransfer', params, options?.additionalQueryKeyPart],
      () => this.crudApi.listOfRequestedForTransfer(params),
      omit(options, 'additionalQueryKeyPart')
    )
  }

  useBulkChangeMutation() {
    const add = Providers.ToasterProvider.useToasterMessageAdder()
    return useMutation<void, unknown, Params.BulkChange>((params: Params.BulkChange) => this.crudApi.bulkChange(params), {
      onSuccess: () => {
        add({
          message: `Changes applied`,
          type: 'success',
        })
      },
    })
  }

  useAutoAssignMutation() {
    const add = Providers.ToasterProvider.useToasterMessageAdder()
    return useMutation<void, unknown, Params.AutoAssign>((params: Params.AutoAssign) => this.crudApi.autoAssign(params), {
      onSuccess: () => {
        add({
          message: `Changes applied`,
          type: 'success',
        })
      },
    })
  }

  useAcceptMutation() {
    const add = Providers.ToasterProvider.useToasterMessageAdder()
    return useMutation<void, unknown, string>((id: string) => this.crudApi.accept(id), {
      onSuccess: () => {
        add({
          message: `Changes applied`,
          type: 'success',
        })
      },
    })
  }

  useDeclineMutation() {
    const add = Providers.ToasterProvider.useToasterMessageAdder()
    return useMutation<void, unknown, string>((id: string) => this.crudApi.decline(id), {
      onSuccess: () => {
        add({
          message: `Changes applied`,
          type: 'success',
        })
      },
    })
  }
}

export const assignmentHooks = new CrudHooks('assignment', 'Assignment', assignmentApi)
