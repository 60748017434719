import { ReactNode } from 'react'
import cx from 'classnames'
import {useNavigate} from 'react-router-dom'

type Props = {
  children: ReactNode
  className?: string
  redirectTo?: string
  onClick?: () => void
  disabled?: boolean
}

export const Link = ({children, className, redirectTo, onClick, disabled}: Props) => {
  const navigate = useNavigate()

  const action = redirectTo ? () => {
    navigate(redirectTo)
  } : onClick

  return (
    <div
      className={cx(
        'text-blue-600 hover:opacity-80',
        disabled ? 'cursor-not-allowed pointer-events-none' : 'cursor-pointer',
        className,
      )}
      onClick={action}
    >
      {children}
    </div>
  )
}