import {Assignments, Notifications} from 'common/types'
import {TourEventCardInfo} from 'components/TourEventCardInfo'
import {AssignmentStatusPill} from 'components/pills/AssignmentStatusPill'
import {assignmentHooks} from 'hooks/assignments'
import {useCallback, useEffect, useState} from 'react'
import {Components} from 'ui'
import {NotificationCard} from '../NotificationCard'
import {useAssignmentById} from '../hooks/useAssignment'
import {useMarkAsSeen} from '../hooks/useMarkAsSeen'

type Props = {
  notification: Notifications.Basic.StorableEntity<Notifications.Basic.GuideAssignedDetails>
}

export const GuideAssigned = ({notification}: Props) => {
  const {isSeen, markAsSeen} = useMarkAsSeen(notification, 'manual')
  const acceptMutate = assignmentHooks.useAcceptMutation().mutate
  const declineMutate = assignmentHooks.useDeclineMutation().mutate
  const initialAssignment = useAssignmentById(notification.details.assignmentId)
  const [assignment, setAssignment] = useState<Assignments.Basic.StorableAugmentedEntity>(initialAssignment)

  const assignmentItemQuery = assignmentHooks.useItemQuery(notification.details.assignmentId, {
    enabled: false,
    onSuccess: (assignment) => setAssignment(assignment),
  })

  useEffect(() => {
    if (assignmentItemQuery.data) {
      markAsSeen()
    }
  }, [assignmentItemQuery.data, markAsSeen])

  const accept = useCallback(() => {
    acceptMutate(notification.details.assignmentId, {
      onSuccess: () => assignmentItemQuery.refetch(),
    })
  }, [acceptMutate, assignmentItemQuery, notification])

  const decline = useCallback(() => {
    declineMutate(notification.details.assignmentId, {
      onSuccess: () => assignmentItemQuery.refetch(),
    })
  }, [declineMutate, assignmentItemQuery, notification])

  return (
    <NotificationCard isSeen={isSeen}>
      <TourEventCardInfo title="Shift invite" event={assignment} />
      {isSeen ? (
        <AssignmentStatusPill assignment={assignment} transfer={null} view="guide" />
      ) : (
        <div className="flex gap-4">
          <Components.Button color="green" size="sm" onClick={accept}>
            Accept
          </Components.Button>
          <Components.Button color="red" size="sm" onClick={decline}>
            Decline
          </Components.Button>
        </div>
      )}
    </NotificationCard>
  )
}
