import {Locations} from 'common/types'
import {PageHeader} from 'components/PageHeader'
import {CURRENCIES} from 'consts'
import {locationHooks} from 'hooks/locations'
import {isEmpty} from 'lodash-es'
import {Form} from 'react-final-form'
import {useNavigate} from 'react-router-dom'
import {Components, Fields} from 'ui'

export const Create = () => {
  const navigate = useNavigate()

  const createMutation = locationHooks.useCreateMutation()

  const onSubmit = (values: Locations.Params.Create) => {
    createMutation.mutate(values, {
      onSuccess(location) {
        navigate(`/locations/${location.id}/edit`)
      },
    })
  }

  return (
    <>
      <PageHeader title="Locations" subtitle="Add Location" />

      <div className="py-5">
        <Form
          onSubmit={onSubmit}
          render={({handleSubmit, pristine, values}) => {
            const disableSave = pristine || isEmpty(values.name) || isEmpty(values.code) || isEmpty(values.timeZone)

            return (
              <form onSubmit={handleSubmit} className="flex flex-col gap-4">
                <Fields.InputField label="Name" name="name" expanded />
                <Fields.InputField label="Code" name="code" expanded />
                <Fields.InputField label="Time zone" name="timeZone" expanded />
                <Fields.SelectField options={CURRENCIES} label="Currency" name="currency" expanded />

                <Components.Button disabled={disableSave} className="ml-auto">
                  Add Location
                </Components.Button>
              </form>
            )
          }}
        />
      </div>
    </>
  )
}
