import cx from 'classnames'
import {AccessGuard} from 'components/AccessGuard'
import {ReactComponent as CalendarMonth} from 'icons/CalendarMonth.svg'
import {ReactComponent as EventAvailable} from 'icons/EventAvailable.svg'
import {ReactComponent as Extension} from 'icons/Extension.svg'
import {ReactComponent as Group} from 'icons/Group.svg'
import {ReactComponent as LineChart} from 'icons/LineChart.svg'
import {ReactComponent as LocationOn} from 'icons/LocationOn.svg'
import {ReactComponent as Notifications} from 'icons/Notifications.svg'
import {ReactComponent as Payments} from 'icons/Payments.svg'
import {ReactComponent as PersonApron} from 'icons/PersonApron.svg'
import {ReactComponent as SwapHoriz} from 'icons/SwapHoriz.svg'
import {ReactComponent as Tour} from 'icons/Tour.svg'
import {NavbarItem} from './NavbarItem'

type Props = {
  collapsed?: boolean
}

export const NavbarItems = ({collapsed}: Props) => {
  return (
    <div className={cx('flex flex-col gap-3 grow')}>
      <AccessGuard hasRole="guideUser">
        <NavbarItem redirectTo="/shifts/assigned" icon={EventAvailable} title="Tour Calendar" collapsed={collapsed} />
      </AccessGuard>

      <AccessGuard hasSomeOfPermissions={['shifts:create']}>
        <NavbarItem redirectTo="/shifts/scheduling" icon={CalendarMonth} title="Schedule" collapsed={collapsed} />
      </AccessGuard>

      <NavbarItem redirectTo="/notifications" icon={Notifications} title="Notifications" collapsed={collapsed} />

      <AccessGuard hasRole="guideUser">
        <NavbarItem redirectTo="/transfers" icon={SwapHoriz} title="Swaps" collapsed={collapsed} />
      </AccessGuard>

      <AccessGuard hasSomeOfPermissions={['guides:view']}>
        <NavbarItem redirectTo="/guides" icon={PersonApron} title="Guides" collapsed={collapsed} />
      </AccessGuard>

      <AccessGuard hasSomeOfPermissions={['products:view']}>
        <NavbarItem redirectTo="/products" icon={Tour} title="Tours" collapsed={collapsed} />
      </AccessGuard>

      <AccessGuard hasSomeOfPermissions={['locations:view']}>
        <NavbarItem redirectTo="/locations" icon={LocationOn} title="Locations" collapsed={collapsed} />
      </AccessGuard>

      <NavbarItem redirectTo="/resources" icon={Extension} title="Resources" collapsed={collapsed} />

      <AccessGuard hasSomeOfPermissions={['shifts:view']}>
        <NavbarItem redirectTo="/payouts" icon={Payments} title="Finance" collapsed={collapsed} />
      </AccessGuard>

      <AccessGuard hasSomeOfPermissions={['users:view']}>
        <NavbarItem redirectTo="/reporting" icon={LineChart} title="Reporting" collapsed={collapsed} />
      </AccessGuard>

      <AccessGuard hasSomeOfPermissions={['users:view']}>
        <NavbarItem redirectTo="/users" icon={Group} title="User Roles" collapsed={collapsed} />
      </AccessGuard>
    </div>
  )
}
