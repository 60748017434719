import {ReactComponent as ArrowCircleLeft} from 'icons/ArrowCircleLeft.svg'
import {ReactComponent as ArrowCircleRight} from 'icons/ArrowCircleRight.svg'
import {ReactComponent as Cancel} from 'icons/Cancel.svg'
import logo from 'logo.png'
import {Link} from 'react-router-dom'

type Props = {
  onClose?: () => void
  onCollapseToggle?: () => void
  collapsed?: boolean
}

export const NavbarHeader = ({onClose, onCollapseToggle, collapsed}: Props) => {
  if (collapsed) {
    return <ArrowCircleRight className="hidden lg:block w-6 h-6 my-2 cursor-pointer hover:opacity-50 mx-2" onClick={onCollapseToggle} />
  }
  return (
    <div className="flex justify-between items-center pb-5 mb-5 border-b">
      <Link to="/" className="flex items-center">
        <img src={logo} className="h-9 mr-2" alt="ExperienceFirst logo" />
        <div className="flex flex-col">
          <span className="text-[11px] mt-2 text-gray-400 leading-[0.5rem] mb-0 font-semibold whitespace-nowrap">ExperienceFirst</span>
          <span className="text-lg mt-0 font-semibold whitespace-nowrap">Scheduler</span>
        </div>
      </Link>
      {onClose && <Cancel className="lg:hidden w-6 h-6 cursor-pointer hover:opacity-50" onClick={onClose} />}
      {onCollapseToggle && <ArrowCircleLeft className="hidden lg:block w-6 h-6 cursor-pointer hover:opacity-50" onClick={onCollapseToggle} />}
    </div>
  )
}
