import cx from 'classnames'
import {Guides} from 'common/types'
import {ReactComponent as NewIcon} from 'icons/New.svg'
import {ReactComponent as TrophyIcon} from 'icons/Trophy.svg'
import {Pill} from 'ui/components'

type Props = {
  status: Guides.Basic.GuideStatus
  className?: string
}

export const GuideStatusPill = ({status, className}: Props) => {
  switch (status) {
    case 'new':
      return (
        <Pill className={cx('flex gap-1 items-center !text-blue-500', className)}>
          <NewIcon className="w-2 h-2" />
          New
        </Pill>
      )
    case 'regular':
      return <Pill className={cx('!text-gray-600', className)}>Regular</Pill>
    case 'top_achiever':
      return (
        <Pill className={cx('flex gap-1 items-center !text-yellow-500', className)}>
          <TrophyIcon className="w-3 h-3" />
          Top Achiever
        </Pill>
      )
  }
}
