import {DayFilter} from 'components/filters/DayFilter'
import {MonthFilter} from 'components/filters/MonthFilter'
import {WeekFilter} from 'components/filters/WeekFilter'
import {useMemo} from 'react'
import {Period} from 'types'

export const usePeriodFilterComponent = (period: Period) => {
  return useMemo(() => {
    switch (period) {
      case 'month':
        return MonthFilter
      case 'week':
        return WeekFilter
      case 'day':
        return DayFilter
    }
  }, [period])
}
