import {Notification} from './Notification'
import { Components } from 'ui'
import { useData } from './hooks/useData'
import { PageHeader } from 'components/PageHeader'

export const List = () => {
  const {data} = useData()
  const {newNotifications, oldNotifications} = data

  return (
    <div className="flex flex-col gap-8">
      <div className="flex flex-col gap-3">
        <PageHeader title="Notifications" />

        {newNotifications.length > 0 ? (
          <>
            {newNotifications.map((notification) => {
              return <Notification key={notification.id} notification={notification}/>
            })}
          </>
        ) : (
          <div className="italic text-gray-500 text-sm">No new notifications</div>
        )}
      </div>

      <div className="flex flex-col gap-3">
        <Components.Header.H5>Archived Notifications</Components.Header.H5>
        {oldNotifications.length > 0 ? (
          <>
            {oldNotifications.map((notification) => {
              return <Notification key={notification.id} notification={notification}/>
            })}
          </>
        ) : (
          <div className="italic text-gray-500 text-sm">No old notifications</div>
        )}
      </div>
    </div>
  )
}
