import {Assignments, Transfers} from 'common/types'
import {InfoBlock} from 'components/InfoBlock/InfoBlock'
import { TourEventInfoBlock } from 'components/InfoBlock/TourEventInfoBlock'
import {AssignmentStatusPill} from 'components/pills/AssignmentStatusPill'


type Props = {
  assignment: Assignments.Basic.AugmentedEntity
  transfer: Transfers.Basic.Entity | null
}

export const AssignmentInfo = ({assignment, transfer}: Props) => {
  return (
    <TourEventInfoBlock event={assignment}>
      <InfoBlock.Item label="Status">
        <AssignmentStatusPill assignment={assignment} transfer={transfer} view='guide'/>
      </InfoBlock.Item>
    </TourEventInfoBlock>
  )
}
