import cx from 'classnames'
import {isArray} from 'lodash-es'
import {ChangeEventHandler, useEffect, useMemo, useRef, useState} from 'react'
import {useDropdownStyle} from 'ui/utils/useDropdownStyle'
import {ReactComponent as ChevronDownIcon} from '../icons/chevron-down.svg'
import {ReactComponent as CloseIcon} from '../icons/close.svg'
import {ControlSize, SelectOptions} from '../types'
import {isSameOrChildOf} from '../utils/isSameOrChildOf'
import {normalizeOptions} from '../utils/normalizeOptions'
import {Label} from './Label'
import {getControlClasses, getIconClasses, getInputPaddingClasses, getMaxOptionsHeight, getOptionHeight, getTextClasses} from './Select'
import {Text} from './Text'

type Props = {
  id?: string
  label?: string
  error?: string
  name?: string
  options: SelectOptions
  value?: string[]
  onChange?: ChangeEventHandler<HTMLSelectElement>
  className?: string
  size?: ControlSize
  expanded?: boolean
  disabled?: boolean
  visibleNumber?: number
  hideSeparator?: boolean
  placeholder?: string
}

export const MultiSelect = ({
  id,
  label,
  error,
  name,
  value: initialValues,
  options,
  onChange,
  visibleNumber = 10,
  className,
  size = 'default',
  expanded,
  disabled,
  hideSeparator = false,
  placeholder = 'None',
}: Props) => {
  const normalizedOptions = useMemo(() => normalizeOptions(options), [options])
  const [values, setValues] = useState<string[]>(isArray(initialValues) ? initialValues : [])
  const [prevValues, setPrevValues] = useState(values)
  const [collapsed, setCollapsed] = useState(true)
  const visibleSelectRef = useRef<HTMLDivElement>(null)
  const hiddenSelectRef = useRef<HTMLSelectElement>(null)

  const onOptionClick = (value: string) => {
    setValues((values) => {
      return values.includes(value) ? values.filter((item) => item !== value) : [...values, value]
    })
  }

  useEffect(() => {
    if (hiddenSelectRef && hiddenSelectRef.current) {
      if (values !== prevValues) {
        hiddenSelectRef.current.dispatchEvent(new Event('change', {bubbles: true}))
      }
      setPrevValues(values)
    }
  }, [values, prevValues])

  const onInputClick = () => setCollapsed((collapsed) => !collapsed)
  const onCloseClick = (value: string) => setValues((values) => values.filter((item) => item !== value))

  useEffect(() => {
    const onClick = (event: MouseEvent) => {
      if (
        visibleSelectRef &&
        visibleSelectRef.current &&
        event.target &&
        !isSameOrChildOf(event.target as HTMLDivElement, visibleSelectRef.current)
      ) {
        setCollapsed(true)
      }
    }

    document.addEventListener('click', onClick)

    return () => document.removeEventListener('click', onClick)
  }, [])

  const selectedOptions = normalizedOptions.filter((option) => values.includes(option.value))

  const textClasses = getTextClasses(size)
  const inputPaddingClasses = getInputPaddingClasses(size)
  const iconClasses = getIconClasses(size)
  const optionHeight = getOptionHeight(size)
  const controlClasses = getControlClasses(size, hideSeparator)
  const maxOptionsHeight = getMaxOptionsHeight(visibleNumber, optionHeight, normalizedOptions)

  const inputClasses = cx(
    'ring-inset outline-none bg-transparent border border-gray-300 text-gray-900 rounded-lg block',
    {'!text-gray-500 pointer-events-none !bg-gray-50': disabled},
    {'!ring-1 !ring-blue-500 !border-blue-500': !collapsed},
    inputPaddingClasses
  )

  const dropdownStyles = useDropdownStyle(visibleSelectRef, maxOptionsHeight)

  return (
    <div className={cx('flex flex-col select-none', expanded ? 'w-full' : 'w-56', className)}>
      {label && <Label htmlFor={id}>{label}</Label>}
      <select ref={hiddenSelectRef} className="hidden" multiple name={name} value={values} onChange={onChange}>
        {normalizedOptions.map((option) => {
          return <option key={option.value} value={option.value} />
        })}
      </select>
      <div id={id} ref={visibleSelectRef} className={cx('flex justify-between', textClasses, inputClasses)} onClick={onInputClick}>
        <div className="flex gap-1 grow-0 flex-wrap">
          {selectedOptions.length === 0 && <span className="text-nowrap italic text-gray-500 py-1 px-2">{placeholder}</span>}
          {selectedOptions.length > 0 &&
            selectedOptions.map((option) => {
              return (
                <div className="py-1 px-2 bg-blue-300 rounded-full flex flex-nowrap gap-2 items-center" key={option.value}>
                  <span className="text-nowrap">{option.label}</span>
                  <div onClick={() => onCloseClick(option.value)}>
                    <CloseIcon className="w-4 h-4 cursor-pointer" />
                  </div>
                </div>
              )
            })}
        </div>
        <div className={controlClasses}>
          <ChevronDownIcon className={cx(iconClasses, 'cursor-pointer text-gray-400')} />
        </div>
      </div>
      <div className={cx('relative w-full', textClasses, {hidden: collapsed})}>
        <ul
          className="flex flex-col absolute w-full z-20 bg-white divide-y divide-gray-100 rounded-lg border shadow block py-2 overflow-y-auto"
          style={dropdownStyles}
        >
          {normalizedOptions
            .filter((option) => !option.invisible)
            .map((option) => {
              const selected = values.includes(option.value)
              return (
                <li
                  key={option.value}
                  value={option.value}
                  className={cx(
                    'flex-none flex items-center px-4 text-nowrap cursor-pointer hover:bg-blue-200',
                    selected ? 'text-blue-500' : 'text-gray-900',
                    {'cursor-auto pointer-events-none text-gray-300': option.disabled}
                  )}
                  style={{height: `${optionHeight}px`}}
                  onClick={() => onOptionClick(option.value)}
                >
                  {option.label}
                </li>
              )
            })}
        </ul>
      </div>
      {error && (
        <Text color="red" size="sm">
          {error}
        </Text>
      )}
    </div>
  )
}
