import {PageHeader} from 'components/PageHeader'
import {useInitialView} from 'pages/shifts/hooks/useInitialView'
import {DEFAULT_FILTERS} from 'pages/shifts/operator/Filters'
import {useState} from 'react'
import {Period} from 'types'
import {PeriodView} from './PeriodView'

export const Scheduling = () => {
  const initialPeriod = useInitialView()
  const [period, setPeriod] = useState<Period>(initialPeriod)
  const [groupFilters, setGroupFilters] = useState(DEFAULT_FILTERS)

  return <PeriodView title="Schedule" period={period} onChangeView={setPeriod} groupFilters={groupFilters} onChangeGroupFilters={setGroupFilters} />
}
