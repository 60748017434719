import { useEntityById } from "hooks/useEntityById"
import { useData } from "./useData"
import { Transfers } from "common/types"

const DEFAULT_VALUE: Transfers.Basic.StorableEntity = {
id: '',
createdAt: '',
updatedAt: '',
assignmentId: '',
fromGuideId: '',
toGuideId: '',
status: 'pending',
}

const useList = () => {
  const {data} = useData()
  const {transfers} = data
  return transfers
}

export const useTransferById = (id: string) => {
  return useEntityById(useList, id, DEFAULT_VALUE)
}