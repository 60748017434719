import cx from 'classnames'
import moment from 'moment'
import {ChangeEventHandler, useMemo} from 'react'
import {ControlSize} from 'ui/types'
import {GroupItemsPicker} from './GroupItemsPicker'

type Props = {
  id?: string
  label?: string
  error?: string
  name?: string
  value?: string
  onChange?: ChangeEventHandler<HTMLInputElement>
  disabled?: boolean
  expanded?: boolean
  size?: ControlSize
  className?: string
  placeholder?: string
}

const currentYear = moment().year()

const YEAR_OPTIONS = [currentYear - 1, currentYear, currentYear + 1]
const MONTH_OPTIONS = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'Decempber'].map(
  (name, index) => {
    const value = index + 1
    return {
      label: name,
      value: value < 10 ? '0' + value : String(value),
    }
  }
)

export const formatValue = (value: string) => {
  return value ? moment(value).format('YYYY MMMM') : ''
}

const buildValue = (groupValues: string[]) => {
  if (groupValues.some((groupValue) => !groupValue)) {
    return ''
  }
  return `${groupValues[0]}-${groupValues[1]}-01`
}

const parseValue = (value: string) => {
  if (!value) {
    return ['', '']
  }
  return value.split('-').slice(0, 2)
}

export const MonthPicker = ({
  value,
  onChange,
  size = 'default',
  label,
  id,
  name,
  error,
  disabled,
  expanded,
  className,
  placeholder = 'None',
}: Props) => {
  const groups = useMemo(() => {
    return [
      {options: YEAR_OPTIONS, name: 'Year'},
      {options: MONTH_OPTIONS, name: 'Month'},
    ]
  }, [])

  return (
    <GroupItemsPicker
      value={value}
      onChange={onChange}
      size={size}
      label={label}
      id={id}
      name={name}
      error={error}
      groups={groups}
      formatValue={formatValue}
      buildValue={buildValue}
      parseValue={parseValue}
      disabled={disabled}
      expanded={expanded}
      className={cx('w-32', className)}
      placeholder={placeholder}
    />
  )
}
