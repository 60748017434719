import cx from 'classnames'
import {ChangeEventHandler, useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {useDropdownStyle} from 'ui/utils/useDropdownStyle'
import {ReactComponent as ChevronDownIcon} from '../icons/chevron-down.svg'
import {ControlSize, SelectOption, SelectOptions} from '../types'
import {isSameOrChildOf} from '../utils/isSameOrChildOf'
import {normalizeOptions} from '../utils/normalizeOptions'
import {Label} from './Label'
import {Text} from './Text'

type Props = {
  id?: string
  label?: string
  error?: string
  name?: string
  options: SelectOptions
  value?: string
  onChange?: ChangeEventHandler<HTMLSelectElement>
  className?: string
  size?: ControlSize
  expanded?: boolean
  disabled?: boolean
  defaultValue?: string
  visibleNumber?: number
  notSelectedOption?: boolean | SelectOption
  hideSeparator?: boolean
  placeholder?: string
}

export const getTextClasses = (size: ControlSize) => {
  switch (size) {
    case 'sm':
      return 'text-xs'
    case 'default':
      return 'text-sm'
    case 'lg':
      return 'text-base'
  }
}

export const getInputPaddingClasses = (size: ControlSize) => {
  switch (size) {
    case 'sm':
      return 'p-1'
    case 'default':
      return 'p-1.5'
    case 'lg':
      return 'p-3'
  }
}

export const getIconClasses = (size: ControlSize) => {
  switch (size) {
    case 'sm':
      return 'h-4 w-4'
    case 'default':
      return 'h-5 w-5'
    case 'lg':
      return 'h-6 w-6'
  }
}

export const getOptionHeight = (size: ControlSize) => {
  switch (size) {
    case 'sm':
      return 32
    case 'default':
      return 40
    case 'lg':
      return 56
  }
}

export const getControlClasses = (size: ControlSize, hideSeparator: boolean) => {
  switch (size) {
    case 'sm':
      return hideSeparator ? 'py-1 pr-1' : 'border-l py-1 pl-2 pr-1'
    case 'default':
      return hideSeparator ? 'py-1 pr-2' : 'border-l py-1 pl-3 pr-2'
    case 'lg':
      return hideSeparator ? 'py-1 pr-2' : 'border-l py-1 pl-3 pr-2'
  }
}

export const getMaxOptionsHeight = (visibleNumber: number, optionHeight: number, normalizedOptions: SelectOption[]) => {
  return (optionHeight + 1) * Math.min(visibleNumber, normalizedOptions.filter(({invisible}) => !invisible).length) + 8 * 2
}

const getNotSelectedOption = (option: boolean | SelectOption, placeholder: string) => {
  return typeof option === 'boolean'
    ? {
        value: '',
        label: placeholder,
      }
    : option
}

export const Select = ({
  id,
  label,
  error,
  name,
  value: initialValue = '',
  options,
  onChange,
  visibleNumber = 10,
  className,
  size = 'default',
  expanded,
  disabled,
  notSelectedOption,
  hideSeparator = false,
  placeholder = 'None',
}: Props) => {
  const resultNotSelectedOption = useMemo(() => getNotSelectedOption(notSelectedOption || false, placeholder), [notSelectedOption, placeholder])

  const normalizedOptions = useMemo(() => {
    return notSelectedOption ? [resultNotSelectedOption, ...normalizeOptions(options)] : normalizeOptions(options)
  }, [resultNotSelectedOption, options, notSelectedOption])

  const [value, setValue] = useState(initialValue)
  const [collapsed, setCollapsed] = useState(true)
  const visibleSelectRef = useRef<HTMLDivElement>(null)
  const hiddenSelectRef = useRef<HTMLSelectElement>(null)
  const optionsRef = useRef<HTMLUListElement>(null)
  const optionHeight = getOptionHeight(size)
  const maxOptionsHeight = getMaxOptionsHeight(visibleNumber, optionHeight, normalizedOptions)

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  const selectedOption = normalizedOptions.find((option) => option.value === value)

  const onOptionClick = useCallback((value: string) => {
    setValue(value)
    setCollapsed(true)
  }, [])

  useEffect(() => {
    if (hiddenSelectRef && hiddenSelectRef.current) {
      hiddenSelectRef.current.dispatchEvent(new Event('change', {bubbles: true}))
    }
  }, [value])

  const onInputClick = useCallback(() => setCollapsed((collapsed) => !collapsed), [])

  useEffect(() => {
    const onClick = (event: MouseEvent) => {
      if (visibleSelectRef && visibleSelectRef.current && !isSameOrChildOf(event.target as HTMLDivElement, visibleSelectRef.current)) {
        setCollapsed(true)
      }
    }

    document.addEventListener('click', onClick)

    return () => document.removeEventListener('click', onClick)
  }, [])

  const textClasses = getTextClasses(size)
  const inputPaddingClasses = getInputPaddingClasses(size)
  const iconClasses = getIconClasses(size)
  const controlClasses = getControlClasses(size, hideSeparator)

  const inputClasses = cx(
    'ring-inset outline-none bg-transparent border border-gray-300 text-gray-900 rounded-lg block',
    {'!text-gray-500 pointer-events-none !bg-gray-50': disabled},
    {'ring-1 !ring-blue-500 !border-blue-500': !collapsed},
    inputPaddingClasses
  )

  const dropdownStyles = useDropdownStyle(visibleSelectRef, maxOptionsHeight)

  const selectedOptionLabel = selectedOption ? selectedOption.label : resultNotSelectedOption.label
  const selectedOptionValue = selectedOption ? selectedOption.value : resultNotSelectedOption.value

  return (
    <div className={cx('flex flex-col select-none', expanded ? 'w-full' : 'w-56', className)}>
      {label && <Label htmlFor={id}>{label}</Label>}
      <select ref={hiddenSelectRef} name={name} value={value} onChange={onChange} className="hidden">
        {normalizedOptions.map((option) => {
          return <option key={option.value} value={option.value} />
        })}
      </select>
      <div id={id} ref={visibleSelectRef} className={cx('flex justify-between', textClasses, inputClasses)} onClick={onInputClick}>
        <div
          className={cx('flex items-center gap-1 grow-0 flex-wrap py-1 px-2', {
            'italic text-gray-500': selectedOptionValue === resultNotSelectedOption.value,
          })}
        >
          {selectedOptionLabel}
        </div>
        <div className={controlClasses}>
          <ChevronDownIcon className={cx(iconClasses, 'cursor-pointer text-gray-400')} />
        </div>
      </div>
      <div className={cx('relative w-full', textClasses, {hidden: collapsed})}>
        <ul
          ref={optionsRef}
          className="flex flex-col absolute w-full z-20 bg-white divide-y divide-gray-100 rounded-lg border shadow block py-2 overflow-y-auto"
          style={dropdownStyles}
        >
          {normalizedOptions
            .filter((option) => !option.invisible)
            .map((option) => {
              const selected = option.value === value
              return (
                <li
                  key={option.value}
                  value={option.value}
                  className={cx(
                    'flex-none flex items-center px-4 text-nowrap cursor-pointer hover:bg-blue-200',
                    selected ? 'text-blue-500' : 'text-gray-900',
                    {italic: option.value === resultNotSelectedOption.value},
                    {'cursor-auto pointer-events-none text-gray-300': option.disabled}
                  )}
                  style={{height: `${optionHeight}px`}}
                  onClick={() => onOptionClick(option.value)}
                >
                  {option.label}
                </li>
              )
            })}
        </ul>
      </div>
      {error && (
        <Text color="red" size="sm">
          {error}
        </Text>
      )}
    </div>
  )
}
