import cx from 'classnames'
import {ChangeEventHandler, useCallback, useEffect, useRef, useState} from 'react'
import {ControlSize, SelectOptions} from 'ui/types'
import {isSameOrChildOf} from 'ui/utils/isSameOrChildOf'
import {normalizeOptions} from 'ui/utils/normalizeOptions'
import {useDropdownStyle} from 'ui/utils/useDropdownStyle'
import {Label} from './Label'
import {Text} from './Text'

declare const Object: any

type Group = {
  options: SelectOptions
  name: string
}

type Props = {
  id?: string
  label?: string
  error?: string
  name?: string
  value?: string
  onChange?: ChangeEventHandler<HTMLInputElement>
  size?: ControlSize
  groups: Group[]
  formatValue?: (value: string) => string
  buildValue: (groupValues: string[]) => string
  parseValue: (value: string) => string[]
  disabled?: boolean
  expanded?: boolean
  className?: string
  placeholder?: string
}

const getSizeClasses = (size: ControlSize) => {
  switch (size) {
    case 'sm':
      return 'text-xs p-2'
    case 'default':
      return 'text-sm p-2.5'
    case 'lg':
      return 'text-md p-4'
  }
}

export const GroupItemsPicker = ({
  groups,
  id,
  label,
  error,
  name,
  value: initialValue = '',
  onChange,
  size = 'default',
  formatValue = (value: string) => value,
  buildValue,
  parseValue,
  disabled,
  expanded,
  className,
  placeholder = 'None',
}: Props) => {
  const sizeClasses = getSizeClasses(size)
  const [groupValues, setGroupValues] = useState(parseValue(initialValue))
  const [value, setValue] = useState(initialValue)
  const [collapsed, setCollapsed] = useState(true)
  const visibleInputRef = useRef<HTMLDivElement>(null)
  const hiddenInputRef = useRef<HTMLInputElement>(null)

  const changeGroupValue = useCallback((groupIndex: number, groupValue: string) => {
    setGroupValues((groupValues) => {
      const clonedGroupValues = [...groupValues]
      clonedGroupValues[groupIndex] = groupValue
      return clonedGroupValues
    })
  }, [])

  useEffect(() => {
    setValue(buildValue(groupValues))
  }, [groupValues, buildValue])

  useEffect(() => {
    const onClick = (event: MouseEvent) => {
      if (visibleInputRef.current && !isSameOrChildOf(event.target as HTMLDivElement, visibleInputRef.current)) {
        setCollapsed(true)
      }
    }

    document.addEventListener('click', onClick)

    return () => document.removeEventListener('click', onClick)
  }, [visibleInputRef])

  useEffect(() => {
    if (hiddenInputRef.current) {
      const nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value').set
      nativeInputValueSetter.call(hiddenInputRef.current, value)
      hiddenInputRef.current.dispatchEvent(new Event('change', {bubbles: true}))
    }
  }, [value])

  const dropdownStyles = useDropdownStyle(visibleInputRef, 48 * 4)

  const onInputClick = useCallback(() => setCollapsed((collapsed) => !collapsed), [])

  const inputClasses = cx(
    'text-gray-900 border rounded-lg w-full',
    {
      'ring-inset ring-1 ring-blue-500 border-blue-500': !collapsed,
      '!text-gray-500 pointer-events-none !bg-gray-50': disabled,
    },
    sizeClasses
  )

  return (
    <div className={cx('flex flex-col select-none', className, expanded ? 'w-full' : '')}>
      {label && <Label htmlFor={id}>{label}</Label>}
      <input className="hidden" onChange={onChange} ref={hiddenInputRef} name={name} type="text" />

      <div ref={visibleInputRef} className={inputClasses} onClick={onInputClick}>
        {value ? <span className="text-gray-900">{formatValue(value)}</span> : <span className="italic text-gray-500">{placeholder}</span>}
      </div>
      <div className="relative">
        <div
          className={cx('absolute flex text-sm bg-white border shadow z-20 rounded-lg overflow-y-auto overflow-x-hidden', {hidden: collapsed})}
          style={dropdownStyles}
        >
          {groups.map((group, index) => {
            const normalizedOptions = normalizeOptions(group.options)
            return (
              <div key={group.name} className="flex flex-col">
                <div className="px-4 py-2 italic text-gray-500 text-xs">{group.name}</div>
                <div className="overflow-y-auto">
                  {normalizedOptions.map((option) => {
                    const isSelected = option.value === groupValues[index]
                    return (
                      <div
                        key={option.value}
                        className={cx('px-4 py-2', {
                          'bg-blue-500 text-white pointer-events-none': isSelected,
                          'cursor-pointer hover:bg-blue-200': !isSelected,
                        })}
                        onClick={() => changeGroupValue(index, option.value)}
                      >
                        {option.label}
                      </div>
                    )
                  })}
                </div>
              </div>
            )
          })}
        </div>
      </div>
      {error && (
        <Text color="red" size="sm">
          {error}
        </Text>
      )}
    </div>
  )
}
