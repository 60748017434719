import cx from 'classnames'
import {DATE_FORMAT} from 'consts'
import {range} from 'lodash-es'
import moment, {Moment} from 'moment'
import {ChangeEventHandler, useCallback, useEffect, useRef, useState} from 'react'
import {ControlSize} from 'ui/types'
import {isSameOrChildOf} from 'ui/utils/isSameOrChildOf'
import {useDropdownStyle} from 'ui/utils/useDropdownStyle'
import {ReactComponent as ChevronForwardIcon} from '../icons/chevron-forward.svg'
import {ReactComponent as ChevronLeftIcon} from '../icons/chevron-left.svg'
import {Label} from './Label'
import {Text} from './Text'

export type Date = {
  year: number
  month: number
  date: number
}

type Props = {
  id?: string
  label?: string
  error?: string
  name?: string
  value?: string
  onChange?: ChangeEventHandler<HTMLInputElement>
  size?: ControlSize
  disabled?: boolean
  expanded?: boolean
  className?: string
  placeholder?: string
}

const getSizeClasses = (size: ControlSize) => {
  switch (size) {
    case 'sm':
      return 'text-xs p-2'
    case 'default':
      return 'text-sm p-2.5'
    case 'lg':
      return 'text-md p-4'
  }
}

const weekDays = ['M', 'T', 'W', 'T', 'F', 'S', 'S']

export const WeekPicker = ({
  value: initialValue = '',
  onChange,
  size = 'default',
  label,
  id,
  name,
  error,
  disabled,
  expanded,
  className,
  placeholder = 'None',
}: Props) => {
  const sizeClasses = getSizeClasses(size)
  const now = moment()

  const [selectedStartOfWeek, setSelectedStartOfWeek] = useState(initialValue ? moment(initialValue) : null)
  const [selectedMonthAndYear, setSelectedMonthAndYear] = useState(initialValue ? moment(initialValue) : now)
  const [value, setValue] = useState(initialValue)
  const [collapsed, setCollapsed] = useState(true)
  const visibleInputRef = useRef<HTMLDivElement>(null)
  const hiddenInputRef = useRef<HTMLInputElement>(null)
  const dropdownStyles = useDropdownStyle(visibleInputRef)

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  const firstStartOfWeek = moment(selectedMonthAndYear).startOf('month').startOf('isoWeek')
  const lastStartOfWeek = moment(selectedMonthAndYear).endOf('month').startOf('isoWeek')

  const weekQty = lastStartOfWeek.diff(firstStartOfWeek, 'weeks') + 1

  const changeWeek = useCallback((startOfWeek: Moment) => {
    setSelectedStartOfWeek(startOfWeek)
    setValue(startOfWeek.format(DATE_FORMAT))
  }, [])

  const changeMonth = useCallback((event: React.MouseEvent, action: 'subtract' | 'add') => {
    event.stopPropagation()

    setSelectedMonthAndYear((selectedMonthAndYear) => {
      const newSelectedMonthAndYear = moment(selectedMonthAndYear)
      newSelectedMonthAndYear[action](1, 'month')
      return newSelectedMonthAndYear
    })
  }, [])

  useEffect(() => {
    const onClick = (event: MouseEvent) => {
      if (visibleInputRef.current && !isSameOrChildOf(event.target as HTMLDivElement, visibleInputRef.current)) {
        setCollapsed(true)
      }
    }

    document.addEventListener('click', onClick)

    return () => document.removeEventListener('click', onClick)
  }, [visibleInputRef])

  useEffect(() => {
    if (hiddenInputRef.current) {
      const nativeInputValue = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value')

      if (!nativeInputValue) {
        throw new Error('Native input value is not defined')
      }

      const nativeInputValueSetter = nativeInputValue.set

      if (!nativeInputValueSetter) {
        throw new Error('Native input value setter is not defined')
      }

      nativeInputValueSetter.call(hiddenInputRef.current, value)
      hiddenInputRef.current.dispatchEvent(new Event('change', {bubbles: true}))
    }
  }, [value])

  const onInputClick = useCallback(() => setCollapsed((collapsed) => !collapsed), [])

  const inputClasses = cx(
    'text-gray-900 border rounded-lg w-full',
    {
      'ring-inset ring-1 ring-blue-500 border-blue-500': !collapsed,
      '!text-gray-500 pointer-events-none !bg-gray-50': disabled,
    },
    sizeClasses
  )

  return (
    <div className={cx('flex flex-col select-none', expanded ? 'w-full' : 'w-42', className)}>
      {label && <Label htmlFor={id}>{label}</Label>}
      <input className="hidden" onChange={onChange} ref={hiddenInputRef} name={name} type="text" />

      <div ref={visibleInputRef} className={inputClasses} onClick={onInputClick}>
        {selectedStartOfWeek ? (
          <span className="text-gray-900">
            {selectedStartOfWeek.format('YYYY MMM DD')} - {moment(selectedStartOfWeek).add(7, 'days').format('YYYY MMM DD')}
          </span>
        ) : (
          <span className="italic text-gray-500">{placeholder}</span>
        )}
      </div>
      <div className="relative">
        <div className={cx('absolute flex flex-col text-sm bg-white border shadow z-10 rounded-lg ', {hidden: collapsed})} style={dropdownStyles}>
          <div className="flex justify-between p-2">
            <ChevronLeftIcon onClick={(event) => changeMonth(event, 'subtract')} className="cursor-pointer hover:opacity-70" />
            <div>{selectedMonthAndYear.format('YYYY, MMM')}</div>
            <ChevronForwardIcon onClick={(event) => changeMonth(event, 'add')} className="cursor-pointer hover:opacity-70" />
          </div>
          <div className="flex flex-col w-72">
            <div className="flex w-full">
              {weekDays.map((day, index) => {
                return (
                  <div key={day + index} className="leading-10 text-center text-gray-500 basis-0 grow">
                    {day}
                  </div>
                )
              })}
            </div>
            {range(weekQty).map((weekIndex) => {
              const startOfWeek = moment(firstStartOfWeek).add(weekIndex, 'weeks')
              const isSelectedWeek = startOfWeek.isSame(selectedStartOfWeek)
              return (
                <div
                  key={weekIndex}
                  className={cx('flex rounded', {
                    'bg-blue-500 text-white pointer-events-none': isSelectedWeek,
                    'cursor-pointer hover:bg-blue-200': !isSelectedWeek,
                  })}
                  onClick={() => changeWeek(startOfWeek)}
                >
                  {range(7).map((dayIndex) => {
                    return (
                      <div key={dayIndex} className="leading-10 text-center basis-0 grow">
                        {moment(startOfWeek).add(dayIndex, 'days').date()}
                      </div>
                    )
                  })}
                </div>
              )
            })}
          </div>
        </div>
      </div>
      {error && <Text color="red">{error}</Text>}
    </div>
  )
}
