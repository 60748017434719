import {UnavailabilityDescriptors} from 'common/types'
import {Utils} from 'ui'
import {axiosInstance} from './getInstance'

export const crudApi = new Utils.CrudApi<
  never,
  never,
  UnavailabilityDescriptors.Params.Create,
  UnavailabilityDescriptors.Params.Update,
  never,
  UnavailabilityDescriptors.Responses.Item,
  UnavailabilityDescriptors.Responses.Create,
  UnavailabilityDescriptors.Responses.Update
>('/unavailabilityDescriptors', axiosInstance)
