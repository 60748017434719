import {useIsLoading} from 'hooks/useIsLoading'
import {QueryClient, QueryClientConfig, QueryClientProvider} from 'react-query'
import {BrowserRouter as Router} from 'react-router-dom'
import {Components, Providers} from 'ui'
import {Routing} from './Routing'
import { useCallback } from 'react'
import { get } from 'lodash-es'

const WaitingChildren = () => {
  const isLoading = useIsLoading()
  const isWaiting = Providers.WaitingProvider.useIsWaiting()
  const showSpinner = isLoading || isWaiting

  return (
    <>
      <Router>
        <Routing />
      </Router>
      {showSpinner && <Components.Spinner />}
    </>
  )
}

const ToasterChildren = () => {
  const addToasterMessage = Providers.ToasterProvider.useToasterMessageAdder()

  const onError = useCallback((error: unknown) => {
    addToasterMessage({
      message: get(error, 'response.data.error.message') || get(error, 'message') || 'Unknown error',
      type: 'danger',
    })
  }, [addToasterMessage])

  const queryClientConfig: QueryClientConfig = {
    defaultOptions: {
      queries: {
        retry: false,
        refetchOnWindowFocus: false,
        onError,
      },
      mutations: {
        onError,
      }
    },
  }

  const queryClient = new QueryClient(queryClientConfig)

  return (
    <QueryClientProvider client={queryClient}>
      <Providers.WaitingProvider>
        <WaitingChildren />
      </Providers.WaitingProvider>
    </QueryClientProvider>
  )
}

export const App = () => {
  return (
    <Providers.ToasterProvider>
      <ToasterChildren />
    </Providers.ToasterProvider>
  )
}
