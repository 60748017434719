import cx from 'classnames'
import {ReactNode} from 'react'
import {ControlSize} from 'ui/types'

type Color = 'blue' | 'dark' | 'green' | 'red' | 'yellow' | 'purple' | 'exp-orange' | 'exp-dark-blue'

type Props = {
  onSubmit?: () => void
  onClick?: () => void
  children: ReactNode
  color?: Color
  size?: ControlSize
  outline?: boolean
  type?: 'button' | 'submit' | 'reset'
  className?: string
  expanded?: boolean
  disabled?: boolean
  form?: string
}

const getColorClasses = (color: Color, outline: boolean) => {
  if (outline) {
    switch (color) {
      case 'blue':
        return 'text-blue-700 hover:text-white border border-blue-700 hover:bg-blue-800 focus:ring-blue-300'
      case 'dark':
        return 'text-gray-900 hover:text-white border border-gray-800 hover:bg-gray-900 focus:ring-gray-300'
      case 'green':
        return 'text-green-700 hover:text-white border border-green-700 hover:bg-green-800 focus:ring-green-300'
      case 'red':
        return 'text-red-700 hover:text-white border border-red-700 hover:bg-red-800 focus:ring-red-300'
      case 'yellow':
        return 'text-yellow-400 hover:text-white border border-yellow-400 hover:bg-yellow-500 focus:ring-yellow-300'
      case 'purple':
        return 'text-purple-700 hover:text-white border border-purple-700 hover:bg-purple-800 focus:ring-purple-300'
      case 'exp-orange':
        return 'text-exp-orange hover:text-white border border-exp-orange hover:bg-exp-orange/90 focus:ring-exp-orange/30'
      case 'exp-dark-blue':
        return 'text-exp-dark-blue hover:text-white border border-exp-dark-blue hover:bg-exp-dark-blue/90 focus:ring-exp-dark-blue/50'
    }
  }

  switch (color) {
    case 'blue':
      return 'text-white bg-blue-700 hover:bg-blue-800 focus:ring-blue-300 disabled:bg-blue-500'
    case 'dark':
      return 'text-white bg-gray-800 hover:bg-gray-900 focus:ring-gray-300 disabled:bg-gray-500'
    case 'green':
      return 'text-white bg-green-700 hover:bg-green-800 focus:ring-green-300 disabled:bg-green-500'
    case 'red':
      return 'text-white bg-red-700 hover:bg-red-800 focus:ring-red-300 disabled:bg-red-500'
    case 'yellow':
      return 'text-white bg-yellow-400 hover:bg-yellow-500 focus:ring-yellow-300 disabled:bg-yellow-200'
    case 'purple':
      return 'text-white bg-purple-700 hover:bg-purple-800 focus:ring-purple-300 disabled:bg-purple-500'
    case 'exp-orange':
      return 'text-white bg-exp-orange hover:bg-exp-orange/90 focus:ring-exp-orange/30 disabled:bg-exp-orange/40'
    case 'exp-dark-blue':
      return 'text-white bg-exp-dark-blue hover:bg-exp-dark-blue/90 focus:ring-exp-dark-blue/50 disabled:bg-exp-dark-blue/40'
  }
}

const getSizeClasses = (size: ControlSize) => {
  switch (size) {
    case 'sm':
      return 'px-3 h-[34px] text-xs'
    case 'default':
      return 'px-5 h-[42px] text-sm'
    case 'lg':
      return 'px-5 h-[50px] text-base'
  }
}

export const Button = ({
  onSubmit,
  onClick,
  children,
  outline = false,
  size = 'default',
  color = 'exp-orange',
  type,
  className,
  disabled,
  expanded,
  form,
}: Props) => {
  const colorClasses = getColorClasses(color, outline)
  const sizeClasses = getSizeClasses(size)
  const resultClassName = cx(
    'focus:ring-4 font-semibold rounded-lg focus:outline-none text-center disabled:cursor-default',
    {'w-full': expanded, 'w-fit': !expanded},
    colorClasses,
    sizeClasses,
    className
  )
  return (
    <button onSubmit={onSubmit} onClick={onClick} className={resultClassName} type={type} disabled={disabled} form={form}>
      {children}
    </button>
  )
}
