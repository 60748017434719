import {Assignments} from 'common/types'
import {assignmentHooks} from 'hooks/assignments'
import {transferHooks} from 'hooks/transfers'
import {GuideShiftActions} from 'pages/shifts/guide/AssignmentViewer/GuideShiftActions'
import {Components} from 'ui'
import {AssignmentInfo} from './AssignmentInfo'

export type Props = {
  assignment: Assignments.Basic.StorableAugmentedEntity
  transferId: string | null
  onClose: () => void
  onChange: () => void
}

export const AssignmentViewer = ({assignment, transferId, onClose, ...props}: Props) => {
  const assignmentItemQuery = assignmentHooks.useItemQuery(assignment ? assignment.id : '', {
    initialData: assignment,
  })

  const transferItemQuery = transferHooks.useItemQuery(transferId ? transferId : 'NO_TRANSFER', {
    enabled: Boolean(transferId),
  })

  const onChange = () => {
    assignmentItemQuery.refetch()
    props.onChange()
  }

  if (!assignmentItemQuery.data || (transferId && !transferItemQuery.data)) {
    return null
  }

  const transfer = transferId ? transferItemQuery.data || null : null

  return (
    <Components.Modal title="Shift Invite" onClose={onClose} modalClassName="w-96" bodyClassName="grow min-h-0">
      <div className="flex flex-col gap-4 grow min-h-0 h-full">
        <AssignmentInfo assignment={assignment} transfer={transfer} />
        <GuideShiftActions
          assignment={assignmentItemQuery.data}
          transfer={transfer}
          onChange={onChange}
          onClose={onClose}
        />
      </div>
    </Components.Modal>
  )
}
