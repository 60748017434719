import {Assignments, Transfers, Users} from 'common/types'
import { capitalize } from 'lodash-es'
import {UserProvider} from 'providers/UserProvider'
import {Pill} from 'ui/components'
import {ReactComponent as CheckIcon} from 'icons/Check.svg'
import {ReactComponent as CrossIcon} from 'icons/Close.svg'
import {ReactComponent as PendingIcon} from 'icons/Pending.svg'
import {ReactElement} from 'react'


type View = 'guide' | 'operator'

type Props = {
  assignment: Assignments.Basic.AugmentedEntity
  transfer: Transfers.Basic.Entity | null
  view: View
} | {status: Assignments.Basic.Status}

type StatusPillStyle = {
  className: string;
  icon: ReactElement;
};

const STATUSES: Record<Transfers.Basic.Status, StatusPillStyle> = {
  pending: {
    className: '!text-yellow-500',
    icon: <PendingIcon className="w-3 h-3 mr-1 inline align-text-top" />,
  },
  accepted: {
    className: '!text-green-500',
    icon: <CheckIcon className="w-3 h-3 mr-1 inline align-text-top" />,
  },
  declined: {
    className: '!text-red-500',
    icon: <CrossIcon className="w-3 h-3 mr-1 inline align-text-top" />,
  },
  cancelled: {
    className: '!text-gray-500',
    icon: <CheckIcon className="w-3 h-3 mr-1 inline align-text-top" />,
  },
}

const getStatusName = (
  user: Users.Basic.JwtEntity,
  assignment: Assignments.Basic.AugmentedEntity,
  transfer: Transfers.Basic.Entity | null,
  view: View
) => {
  if (transfer) {
    if (view === 'operator') {
      return 'Transfer Requested'
    }

    if (user.id === transfer.fromGuideId) {
      return 'Transfer Requested By Me'
    }

    return 'Transfer Requested To Me'
  }

  return capitalize(assignment.status)
}

const getColorClasses = (
  user: Users.Basic.JwtEntity,
  assignment: Assignments.Basic.AugmentedEntity,
  transfer: Transfers.Basic.Entity | null,
  view: View
) => {
  if (transfer) {
    if (view === 'operator') {
      return 'bg-blue-500 !text-white'
    }

    if (user.id === transfer.fromGuideId) {
      return 'bg-blue-500 !text-white'
    }

    return 'bg-purple-500 !text-white'
  }

  return STATUSES[assignment.status].className;
}

export const AssignmentStatusPill = (props: Props) => {
  const user = UserProvider.useUser()

  if ('status' in props) {
    return <Pill className={STATUSES[props.status].className}>{STATUSES[props.status].icon} {capitalize(props.status)}</Pill>
  }

  const {assignment, transfer, view} = props
  return <Pill className={getColorClasses(user, assignment, transfer, view)}>{getStatusName(user, assignment, transfer, view)}</Pill>
}
