import {Guides} from 'common/types'
import {Where} from 'common/types/utils'
import {PageHeader} from 'components/PageHeader'
import {GuideStatusPill} from 'components/pills/GuideStatusPill'
import {guideHooks} from 'hooks/guides'
import {guidesStatusesHooks} from 'hooks/guidesStatuses'
import {locationHooks} from 'hooks/locations'
import {get, isEmpty, keyBy} from 'lodash-es'
import moment from 'moment'
import {UserProvider} from 'providers/UserProvider'
import {useCallback, useMemo, useRef, useState} from 'react'
import {createPortal} from 'react-dom'
import {Form} from 'react-final-form'
import {useNavigate} from 'react-router-dom'
import {Components, Fields} from 'ui'
import {Checkbox} from 'ui/components'
import {DEFAULT_LIMIT} from 'ui/components/Paginator'
import {getLastMonths} from 'utils/getLastMonths'
import {DEFAULT_FILTERS, Filters} from './Filters'
import {GrayBox} from './GrayBox'

type ScreenMode = 'view' | 'edit'

type Values = Partial<Record<string, boolean>>

export const List = () => {
  const navigate = useNavigate()
  const user = UserProvider.useUser()
  const [offset, setOffset] = useState(0)
  const [filters, setFilters] = useState(DEFAULT_FILTERS)
  const [mode, setMode] = useState<ScreenMode>('view')
  const buttonWrapperRef = useRef(null)
  const months = getLastMonths()
  const lastMonth = months[months.length - 1]

  const where: Where<Guides.Basic.StorableEntity> = useMemo(
    () => [
      {field: 'locationIds', op: '&&', values: isEmpty(filters.locationIds) ? user.locationIds : filters.locationIds},
      {field: 'productIds', op: '&&', values: filters.productIds, skip: isEmpty(filters.productIds)},
      {field: 'isDeleted', value: false},
    ],
    [filters, user]
  )

  const guideListQuery = guideHooks.useListQuery({
    where,
    orderBy: ['handle'],
    offset,
    limit: DEFAULT_LIMIT,
  })

  const guideCountQuery = guideHooks.useCountQuery({
    where,
  })

  const pushGuideStatus = guidesStatusesHooks.useCreateMutation()

  const onEditButtonClick = useCallback(() => {
    setMode('edit')
  }, [])

  const onSubmit = useCallback(
    (values: Values) => {
      const params = []

      for (const guideId of Object.keys(values)) {
        const isTopAchiever = values[guideId]

        if (isTopAchiever === undefined) {
          continue
        }

        params.push({
          guideId,
          isTopAchiever,
          month: lastMonth,
        })
      }

      pushGuideStatus.mutate(params, {
        onSuccess: () => {
          guideListQuery.refetch()
          setMode('view')
        },
      })
    },
    [lastMonth]
  )

  const locationListQuery = locationHooks.useListQuery({})

  const onAddButtonClick = () => {
    navigate('/guides/create')
  }

  if (!guideListQuery.data || !guideCountQuery.data || !locationListQuery.data) {
    return null
  }

  const locationMap = keyBy(locationListQuery.data, 'id')

  const headers = [
    {key: 'guide', value: 'Guide'},
    {key: 'contacts', value: 'Contacts'},
    {key: 'location', value: 'Location'},
    {key: 'probationPassedAt', value: 'Probation'},
    {
      key: 'topAchieverHistory',
      value: (
        <>
          Top Achiever
          <br />
          History
        </>
      ),
    },
    {
      key: 'status',
      value: (
        <>
          Performance
          <br />
          Status
        </>
      ),
    },
    {key: 'edit', className: 'w-1'},
  ]

  const rows = guideListQuery.data.map((guide) => {
    return {
      key: guide.id,
      columns: {
        contacts: (
          <div>
            <div>{guide.email}</div>
            <div className={guide.phone ? '' : 'italic text-xs'}>{guide.phone || 'No Phone Number'}</div>
          </div>
        ),
        guide: (
          <div>
            <div className="text-black font-semibold">{guide.handle}</div>
            <div className="text-xs">
              {guide.firstName} {guide.lastName}
            </div>
          </div>
        ),
        location: get(locationMap, [guide.locationIds[0], 'name']),
        probationPassedAt: guide.probationPassedAt ? (
          <>
            Passed at
            <br />
            {moment(guide.probationPassedAt).format('D MMM, YYYY')}
          </>
        ) : (
          'Not passed'
        ),
        topAchieverHistory: guide.probationPassedAt ? (
          <div className="grid grid-cols-5 gap-x-1 justify-items-center">
            {months.map((month) => {
              return (
                <div key={month} className="text-xs shrink-0">
                  {moment(month).format('MMM')}
                </div>
              )
            })}
            {months.slice(0, 4).map((month) => {
              return guide.topAchieverHistory[month] ? (
                <Checkbox
                  key={month}
                  value={guide.topAchieverHistory[month].isTopAchiever ? 'checked' : 'unchecked'}
                  disabled={mode === 'view'}
                  className="shrink-0"
                />
              ) : (
                <GrayBox key={month} className="mt-0.5 shrink-0" />
              )
            })}
            <Fields.CheckboxField name={guide.id} disabled={mode === 'view'} indeterminate className="shrink-0" />
          </div>
        ) : null,
        status: <GuideStatusPill status={guide.status} />,
        edit: <Components.EditLink to={`/guides/${guide.id}/edit`} />,
      },
    }
  })

  return (
    <div className="flex flex-col gap-5">
      <PageHeader title="Guides">
        <div className="w-full flex justify-between">
          <Filters value={filters} onChange={setFilters} />
          <div className="flex gap-4">
            <div ref={buttonWrapperRef} />
            <Components.Button onClick={onAddButtonClick} size="sm">
              Add Guide
            </Components.Button>
          </div>
        </div>
      </PageHeader>

      <Form
        initialValues={guideListQuery.data.reduce((result: Record<string, boolean | undefined>, guide) => {
          if (guide.probationPassedAt) {
            result[guide.id] = guide.topAchieverHistory[lastMonth]?.isTopAchiever
          }
          return result
        }, {})}
        onSubmit={onSubmit}
        render={({handleSubmit, pristine, values}) => {
          return (
            <form onSubmit={handleSubmit} id="guides">
              <Components.Table headers={headers} rows={rows} />
              {buttonWrapperRef.current &&
                createPortal(
                  <>
                    {mode === 'view' && (
                      <Components.Button onClick={onEditButtonClick} outline size="sm">
                        Edit
                      </Components.Button>
                    )}
                    {mode === 'edit' && (
                      <Components.Button type="submit" outline size="sm" form="guides">
                        Save
                      </Components.Button>
                    )}
                  </>,
                  buttonWrapperRef.current
                )}
            </form>
          )
        }}
      />
      <Components.Paginator offset={offset} total={guideCountQuery.data} onChangeOffset={setOffset} />
    </div>
  )
}
