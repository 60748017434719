import {PageHeader} from 'components/PageHeader'
import {assignmentHooks} from 'hooks/assignments'
import {transferHooks} from 'hooks/transfers'
import {keyBy, map} from 'lodash-es'
import {UserProvider} from 'providers/UserProvider'
import {useCallback, useMemo} from 'react'
import {Components} from 'ui'
import {TransferFromMe} from './cards/TransferFromMe'
import {TransferToMe} from './cards/TransferToMe'

export const List = () => {
  const user = UserProvider.useUser()

  const transferListFromMe = transferHooks.useListQuery({
    where: [{field: 'fromGuideId', value: user.id}],
    orderBy: ['createdAt'],
  })

  const transferListToMe = transferHooks.useListQuery({
    where: [{field: 'toGuideId', value: user.id}],
    orderBy: ['createdAt'],
  })

  const assignmentIds = useMemo(() => {
    return [...map(transferListFromMe.data, 'assignmentId'), ...map(transferListToMe.data, 'assignmentId')]
  }, [transferListFromMe.data, transferListToMe.data])

  const assignmentListQuery = assignmentHooks.useListQuery(
    {
      where: [{field: 'id', values: assignmentIds}],
    },
    {
      enabled: Boolean(transferListFromMe.data && transferListFromMe.data),
    }
  )

  const assignmentMap = useMemo(() => keyBy(assignmentListQuery.data, 'id'), [assignmentListQuery.data])

  const onChange = useCallback(() => {
    transferListFromMe.refetch()
  }, [transferListFromMe])

  if (!transferListFromMe.data || !transferListToMe.data || !assignmentListQuery.data) {
    return null
  }

  return (
    <>
      <PageHeader title="Transfer Requests" />
      <div className="flex flex-col gap-8">
        <div className="flex flex-col gap-3">
          <Components.Header.H5>Transfer Requests From Me</Components.Header.H5>
          {transferListFromMe.data.length > 0 ? (
            <>
              {transferListFromMe.data.map((transfer) => {
                return <TransferFromMe key={transfer.id} transfer={transfer} assignment={assignmentMap[transfer.assignmentId]} onChange={onChange} />
              })}
            </>
          ) : (
            <div className="italic text-gray-500 text-sm">No requests</div>
          )}
        </div>
        <div className="flex flex-col gap-3">
          <Components.Header.H5>Transfer Requests To Me</Components.Header.H5>
          {transferListToMe.data.length > 0 ? (
            <>
              {transferListToMe.data.map((transfer) => {
                return <TransferToMe key={transfer.id} transfer={transfer} assignment={assignmentMap[transfer.assignmentId]} onChange={onChange} />
              })}
            </>
          ) : (
            <div className="italic text-gray-500 text-sm">No requests</div>
          )}
        </div>
      </div>
    </>
  )
}
