import {assignmentApi, unavailabilityEventApi} from 'api'
import {Assignments, Transfers, UnavailabilityEvents} from 'common/types'
import {Storable} from 'common/types/utils'
import {STALE_TIME} from 'consts'
import {isEmpty} from 'lodash-es'
import moment from 'moment'
import {FiltersValue} from 'pages/shifts/guide/Filters'
import {UserProvider} from 'providers/UserProvider'
import {useState} from 'react'
import {useQuery} from 'react-query'
import {Period} from 'types'

type Data = {
  unavailabilityEvents: Storable<UnavailabilityEvents.Basic.AugmentedEntity>[]
  assignments: Storable<Assignments.Basic.AugmentedEntity>[]
  transfers: Storable<Transfers.Basic.Entity>[]
}

const DEFAULT_DATA: Data = {
  unavailabilityEvents: [],
  assignments: [],
  transfers: [],
}

type Params = {
  periodId: string
  groupFilters: FiltersValue
  period: Period
}

export const useData = ({periodId, groupFilters, period}: Params) => {
  const user = UserProvider.useUser()
  const [effectivePeriodId, setEffectivePeriodId] = useState(periodId)

  const startedAt = moment(periodId).format()
  const endedAt = moment(periodId).add(1, period).subtract(1, 'second').format()

  const query = useQuery<Data>(
    ['guideEvents', groupFilters, period, periodId],
    async () => {
      const [directAssignments, assignmentsRequestedForTransferFromMe, assignmentsRequestedForTransferToMe, unavailabilityEvents] = await Promise.all(
        [
          assignmentApi.list({
            where: [
              {field: 'startedAt', op: 'between', start: startedAt, end: endedAt},
              {field: 'guideId', value: user.id},
              {field: 'productId', values: groupFilters.productIds, skip: isEmpty(groupFilters.productIds)},
              {field: 'status', values: isEmpty(groupFilters.categories) ? ['pending', 'accepted'] : groupFilters.categories},
            ],
            orderBy: ['startedAt'],
          }),

          assignmentApi.listOfRequestedForTransfer({
            where: [
              {field: 'startedAt', op: 'between', start: startedAt, end: endedAt},
              {field: 'productId', values: groupFilters.productIds, skip: isEmpty(groupFilters.productIds)},
              {field: 'fromGuideId', value: user.id},
              {field: 'transferStatus', value: 'pending'},
            ],
            orderBy: ['startedAt'],
          }),

          assignmentApi.listOfRequestedForTransfer({
            where: [
              {field: 'startedAt', op: 'between', start: startedAt, end: endedAt},
              {field: 'productId', values: groupFilters.productIds, skip: isEmpty(groupFilters.productIds)},
              {field: 'toGuideId', value: user.id},
              {field: 'transferStatus', value: 'pending'},
            ],
            orderBy: ['startedAt'],
          }),

          unavailabilityEventApi.list({
            guideId: user.id,
            startedAt,
            endedAt,
          }),
        ]
      )

      const assignments = [...directAssignments, ...assignmentsRequestedForTransferToMe.map(({assignment}) => assignment)]
      const transfers = [...assignmentsRequestedForTransferFromMe, ...assignmentsRequestedForTransferToMe].map(({transfer}) => transfer)

      return {
        assignments,
        transfers,
        unavailabilityEvents,
      }
    },
    {
      staleTime: STALE_TIME,
      onSuccess: () => setEffectivePeriodId(periodId),
    }
  )

  return {
    data: query.data || DEFAULT_DATA,
    refetch: query.refetch,
    effectivePeriodId,
  }
}
