import {UnavailabilityEvents} from 'common/types'
import {UnavailabilityDescriptorModal} from 'components/UnavailabilityDescriptorModal'

export type Props = {
  unavailabilityEvent: UnavailabilityEvents.Basic.AugmentedEntity
  onClose: () => void
  onChange: () => void
}

export const UnavailabilityViewer = ({unavailabilityEvent, onClose, onChange}: Props) => {
  return <UnavailabilityDescriptorModal id={unavailabilityEvent.descriptorId} onClose={onClose} onChange={onChange} />
}
