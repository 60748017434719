import {ReactComponent as LockClock} from 'icons/LockClock.svg'
import {useCallback, useState} from 'react'
import { UnavailabilityDescriptorModal } from './UnavailabilityDescriptorModal'

type Props = {
  onChange: () => void
}

export const AddUnavailability = ({onChange}: Props) => {
  const [shown, setShown] = useState(false)

  const close = useCallback(() => setShown(false), [])

  return (
    <>
      <button
        className="fixed right-[20px] bottom-[20px] flex flex-nowrap gap-2 items-center justify-center bg-green-500 w-12 h-12 z-[1] rounded-full"
        onClick={() => setShown(true)}
      >
        <LockClock className="w-6 h-6 text-white" />
      </button>
      {shown && (
        <UnavailabilityDescriptorModal onClose={close} onChange={onChange}/>
      )}
    </>
  )
}
