import {Transfers} from 'common/types'
import {keyBy} from 'lodash-es'
import {useMemo} from 'react'
import {AssignmentViewer} from './AssignmentViewer/AssignmentViewer'
import {UnavailabilityViewer} from './UnavailabilityViewer'
import {Event} from './types'

type Props = {
  event: Event
  transfers: Transfers.Basic.StorableEntity[]
  onClose: () => void
  onChange: () => void
}

export const GuideEventViewer = ({event, transfers, onChange, onClose}: Props) => {
  const transferMap = useMemo(() => keyBy(transfers, 'assignmentId'), [transfers])

  if (event.eventType === 'assignment') {
    return (
      <AssignmentViewer
        assignment={event}
        transferId={transferMap[event.id] ? transferMap[event.id].id : null}
        onClose={onClose}
        onChange={onChange}
      />
    )
  }

  if (event.eventType === 'unavailability') {
    return <UnavailabilityViewer unavailabilityEvent={event} onClose={onClose} onChange={onChange} />
  }

  throw new Error('This should never happened')
}
