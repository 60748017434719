import cx from 'classnames'
import {Shifts} from 'common/types'
import {assignmentHooks} from 'hooks/assignments'
import {guideHooks} from 'hooks/guides'
import {difference, map, without} from 'lodash-es'
import {useState} from 'react'
import {AvailableGuidelist} from './AvailableGuidelist'
import {AssignedGuidelist} from './AssignedGuideList'
import { isOutdatedEvent } from 'utils/isOutdatedEvent'

type Props = {
  shift: Shifts.Basic.AugmentedEntity
  onChange: () => void
  className?: string
}

export const AssignmentPanel = ({shift, onChange, className}: Props) => {
  const allowActions = !isOutdatedEvent(shift)
  const [addedGuideIds, setAddedGuideIds] = useState<string[]>([])
  const [removedGuideIds, setRemovedGuideIds] = useState<string[]>([])

  const possibleGuideListQuery = guideHooks.useListOfPossibleForAssignmentQuery({shiftId: shift.id, withConflicts: true}, {
    keepPreviousData: true,
  })

  const assignmentListQuery = assignmentHooks.useListQuery({
    where: [
      {field: 'shiftId', value: shift.id},
      {field: 'status', values: ['pending', 'accepted']},
    ]
  }, {
    keepPreviousData: true,
  })

  const bulkChangeMutation = assignmentHooks.useBulkChangeMutation()

  const onRemoveAssignedGuide = (id: string) => {
    setRemovedGuideIds((list) => [...list, id])
  }

  const onRemoveAddedGuide = (id: string) => {
    setAddedGuideIds((list) => without(list, id))
  }

  const onAddRemovedGuide = (id: string) => {
    setRemovedGuideIds((list) => without(list, id))
  }

  const onAddAllowedGuide = (id: string) => {
    setAddedGuideIds((list) => [...list, id])
  }

  const onAutoAssign = (ids: string[]) => {
    setAddedGuideIds((list) => [...list, ...ids.slice(0, shift.requiredGuideQty - (shift.assignedGuideQty + addedGuideIds.length - removedGuideIds.length))])
  }

  const onReset = () => {
    setRemovedGuideIds([])
    setAddedGuideIds([])
  }

  const onSave = () => {
    bulkChangeMutation.mutate(
      {
        shiftId: shift.id,
        addedGuideIds,
        removedGuideIds,
      },
      {
        onSuccess() {
          onChange()
          onReset()
          possibleGuideListQuery.refetch()
          assignmentListQuery.refetch()
        },
      }
    )
  }

  const possibleGuideListQueryData = possibleGuideListQuery.data || []
  const assignmentListQueryData = assignmentListQuery.data || []

  const isLoading = possibleGuideListQuery.isLoading || assignmentListQuery.isLoading

  const assignedOnTargetShiftGuideIds = map(assignmentListQueryData, 'guideId')

  const unchangedGuideIds = difference(assignedOnTargetShiftGuideIds, removedGuideIds)

  return (
    <div className={cx('grid grid-cols-[1fr_150px_1fr] min-h-0 h-full', className)}>
      <AssignedGuidelist
        guides={possibleGuideListQueryData}
        assignments={assignmentListQueryData}
        shift={shift}
        addedGuideIds={addedGuideIds}
        removedGuideIds={removedGuideIds}
        unchangedGuideIds={unchangedGuideIds}
        onRemoveAssignedGuide={onRemoveAssignedGuide}
        onRemoveAddedGuide={onRemoveAddedGuide}
        onAddRemovedGuide={onAddRemovedGuide}
        onReset={onReset}
        onSave={onSave}
        className="min-w-0 col-span-2 h-full min-h-0 shrink-0 pr-3"
        isLoading={isLoading}
        allowActions={allowActions}
      />

      <AvailableGuidelist
        guides={possibleGuideListQueryData}
        assignments={assignmentListQueryData}
        addedGuideIds={addedGuideIds}
        onAddAllowedGuide={onAddAllowedGuide}
        onAutoAssign={onAutoAssign}
        className="min-w-0 h-full min-h-0 shrink-0 pl-3"
        isLoading={isLoading}
        allowActions={allowActions}
      />
    </div>
  )
}
