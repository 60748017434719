import cx from 'classnames'
import {TourEventLike} from 'common/types/utils'
import {ProductManager} from 'hooks/scheduling/ProductManager'
import {ReactNode} from 'react'
import {formatDate} from 'utils/formatDate'
import {formatInterval} from 'utils/formatInterval'

type Props = {
  title: ReactNode
  event: TourEventLike
  className?: string
}

export const TourEventCardInfo = ({title, event, className}: Props) => {
  const product = ProductManager.useById(event.productId)

  return (
    <div className={cx('flex flex-col', className)}>
      <div className="font-bold">{title}</div>
      <div className="text-xs">
        <div>
          {formatInterval(event.startedAt, event.endedAt)} {formatDate(event.startedAt)}
        </div>
        <div>{product.shortName}</div>
      </div>
    </div>
  )
}
