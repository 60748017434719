import {Assignments} from 'common/types'
import {guideHooks} from 'hooks/guides'
import {transferHooks} from 'hooks/transfers'
import {isEmpty} from 'lodash-es'
import {UserProvider} from 'providers/UserProvider'
import {useCallback} from 'react'
import {Form} from 'react-final-form'
import {Components, Fields} from 'ui'
import { AssignmentInfo } from './AssignmentInfo'

export type Props = {
  assignment: Assignments.Basic.StorableAugmentedEntity
  onClose: () => void
  onChange: () => void
}

export type Values = {
  toGuideId: string
}

export const TransferModal = ({assignment, onClose, onChange}: Props) => {
  const user = UserProvider.useUser()
  const transferCreateMutate = transferHooks.useCreateMutation().mutate

  const guideListQuery = guideHooks.useListOfPossibleForAssignmentQuery({shiftId: assignment.shiftId, withConflicts: false})

  const submit = useCallback((values: Values) => {
    transferCreateMutate(
      {
        assignmentId: assignment.id,
        fromGuideId: user.id,
        toGuideId: values.toGuideId,
      },
      {
        onSuccess() {
          onClose()
          onChange()
        },
      }
    )
  }, [assignment, onChange, onClose, transferCreateMutate, user])

  if (!guideListQuery.data) {
    return null
  }

  const guideOptions = guideListQuery.data
  .filter((guide) => guide.id !== user.id)
  .map((guide) => {
    return {
      label: guide.handle,
      value: guide.id,
    }
  })

  return (
    <Components.Modal title="Request Transfer" onClose={onClose} modalClassName="w-96" bodyClassName="grow min-h-0">
      <div className="flex flex-col gap-4 grow min-h-0 h-full">
      <AssignmentInfo assignment={assignment} transfer={null} />
        <Form
          onSubmit={submit}
          render={({handleSubmit, pristine, values}) => {
            const disabled = pristine || isEmpty(values.toGuideId)

            return (
              <form onSubmit={handleSubmit} className="flex flex-col gap-4">
                <Fields.SelectField name="toGuideId" options={guideOptions} expanded />
                <Components.Button disabled={disabled}>Request Transfer</Components.Button>
              </form>
            )
          }}
        />
      </div>
    </Components.Modal>
  )
}
