import {Users} from 'common/types'
import {Params} from 'common/types/auth'
import {Responses as AuthResponses} from 'common/types/auth'
import {axiosInstance} from './getInstance'

export const iAm = async (): Promise<Users.Responses.IAm> => {
  const response = await axiosInstance.post<Users.Responses.IAm>('/users/iAm')
  return response.data
}

export const signIn = async (params: Params.SignIn): Promise<Users.Responses.SignIn> => {
  const response = await axiosInstance.post<Users.Responses.SignIn>('/users/signIn', params)
  return response.data
}

export const forgotPassword = async (
  params: Params.ForgotPassword
): Promise<AuthResponses.ForgotPassword> => {
  const response = await axiosInstance.post<AuthResponses.ForgotPassword>(
    '/users/forgotPassword',
    params
  )
  return response.data
}

export const resetPassword = async (
  params: Params.ResetPassword
): Promise<void> => {
  const response = await axiosInstance.post<void>(
    '/users/resetPassword',
    params
  )
  return response.data
}

export const resetPasswordTokenValidation = async (
  params: Params.ResetPasswordTokenValidation
): Promise<AuthResponses.ResetPasswordTokenValidation> => {
  const response = await axiosInstance.get<AuthResponses.ResetPasswordTokenValidation>(
    '/users/resetPassword/' + params.token
  )
  return response.data
}