import {Transfers} from 'common/types'
import {keyBy} from 'lodash-es'
import {useMemo} from 'react'
import {PreviewComponentProps} from 'types'
import {AssignmentPreview} from './AssignmentPreview'
import {UnavailabilityPreview} from './UnavailabilityPreview'
import {Event} from './types'

type Params = {
  transfers: Transfers.Basic.StorableEntity[]
}

export const useGuideEventPreviewComponent = ({transfers}: Params) => {
  const transferMap = useMemo(() => keyBy(transfers, 'assignmentId'), [transfers])

  return useMemo(() => {
    return function PreviewComponent({event, focus, onClick}: PreviewComponentProps<Event>) {
      if (event.eventType === 'assignment') {
        return (
          <AssignmentPreview
            key={event.id}
            assignment={event}
            transfer={transferMap[event.id] || null}
            focus={focus}
            onClick={onClick}
          />
        )
      }

      if (event.eventType === 'unavailability') {
        return <UnavailabilityPreview key={event.id} unavailability={event} focus={focus} onClick={onClick} />
      }

      throw new Error('This should never happened')
    }
  }, [transferMap])
}
