import cx from 'classnames'
import {Assignments, Guides} from 'common/types'
import {GuideStatusPill} from 'components/pills/GuideStatusPill'
import {ReactComponent as AddCircle} from 'icons/AddCircle.svg'
import {ReactComponent as ArrowBack} from 'icons/ArrowBack.svg'
import {difference, keyBy, map, orderBy} from 'lodash-es'
import {ChangeEvent, ReactNode, useCallback, useState} from 'react'
import {Components} from 'ui'

type Props = {
  guides: Guides.Basic.StorableAugmentedEntity[]
  assignments: Assignments.Basic.Entity[]
  className?: string
  addedGuideIds: string[]
  onAddAllowedGuide: (id: string) => void
  onAutoAssign: (ids: string[]) => void
  isLoading: boolean
  allowActions: boolean
}

const orderByStatus = (guide: Guides.Basic.AugmentedEntity) => {
  switch (guide.status) {
    case 'new':
      return 1
    case 'regular':
      return 2
    case 'top_achiever':
      return 3
  }
}

export const AvailableGuidelist = ({
  guides,
  assignments,
  addedGuideIds,
  className,
  onAddAllowedGuide,
  onAutoAssign,
  isLoading,
  allowActions,
}: Props) => {
  const [showAll, setShowAll] = useState(false)

  const changeShowAll = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setShowAll(event.target.checked)
  }, [])

  const headers = [
    {key: 'control', value: ''},
    {key: 'guide', value: 'Guide'},
    {
      key: 'assignmentNumber',
      value: (
        <>
          Qty of
          <br />
          assignments
        </>
      ),
    },
    {
      key: 'guideStatus',
      value: (
        <>
          Performance
          <br />
          status
        </>
      ),
    },
  ]

  const assignedGuideIds = map(assignments, 'guideId')
  const guideMap = keyBy(guides, 'id')
  const usedGuidesIds = guides.filter((guide) => (showAll ? true : guide.conflicts.length === 0)).map((guide) => guide.id)
  const availableGuideIds = difference(usedGuidesIds, addedGuideIds, assignedGuideIds)
  const orderedAvailableGuides = orderBy(
    availableGuideIds.map((id) => guideMap[id]),
    ['assignmentNumber', orderByStatus, 'id'],
    ['asc', 'desc', 'asc']
  )
  const orderedAvailableGuideIds = map(orderedAvailableGuides, 'id')

  const rows = isLoading
    ? []
    : orderedAvailableGuides.map((guide) => ({
        key: guide.id,
        columns: {
          guide: (
            <div>
              <div className="font-bold">{guide.handle}</div>
              <div className="text-xs">
                {guide.firstName} {guide.lastName}
              </div>
              <div className="hidden">{guide.id}</div>
            </div>
          ),
          assignmentNumber: guide.assignmentNumber,
          guideStatus: <GuideStatusPill status={guide.status} />,
          control: <AddCircle className="w-6 h-6 cursor-pointer" onClick={() => onAddAllowedGuide(guide.id)} />,
          conflicted: guide.conflicts.length > 0,
        },
      }))

  return (
    <div className={cx('flex flex-col', className)}>
      <div className="py-3 px-3 rounded-t-lg font-semibold bg-gray-50 border flex justify-between items-center">
        <span>Allowed guides ({rows.length})</span>
        {allowActions && (
          <>
            <Components.Checkbox value={showAll ? 'checked' : 'unchecked'} onChange={changeShowAll} label="Show all" />
            <Components.Button
              size="sm"
              color="green"
              className="flex gap-2 items-center"
              onClick={() => onAutoAssign(orderedAvailableGuideIds)}
              disabled={guides.length === 0}
            >
              <ArrowBack className="w-4 h-4" />
              Auto fill
            </Components.Button>
          </>
        )}
      </div>
      {isLoading && (
        <Components.Table
          headers={[{key: 'loading', value: 'Loading...'}]}
          rows={[]}
          headTrClassName="sticky top-0 bg-slate-500 text-white h-[56px]"
        />
      )}
      {!isLoading && (
        <Components.Table
          headers={headers}
          rows={rows}
          bodyTdClassName="!px-2"
          bodyThClassName="!px-2"
          headThClassName="!px-2"
          getBodyTr={(row, rows, headers) => {
            return (
              <tr className={cx('border-y-4 border-white', row.columns.conflicted ? 'bg-red-200' : 'bg-gray-200')}>
                <th scope="row" className={cx('px-2 py-2 font-medium text-gray-900 whitespace-nowrap')}>
                  {row.columns[headers[0].key] as ReactNode}
                </th>
                {headers.slice(1).map((header) => (
                  <td key={header.key} className={cx('px-2 py-2')}>
                    {row.columns[header.key] as ReactNode}
                  </td>
                ))}
              </tr>
            )
          }}
          headTrClassName="sticky top-0 bg-slate-500 text-white"
        />
      )}
    </div>
  )
}
