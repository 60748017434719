import cx from 'classnames'
import { CSSProperties } from 'react'

type Props = {
  className?: string
  style?: CSSProperties
}

export const Circle = ({className, style}: Props) => {
  return <div className={cx('rounded-full w-2 h-2', className)} style={style} />
}
