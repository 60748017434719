import {Period} from 'types'
import {Components} from 'ui'
import {ControlSize} from 'ui/types'

type Props = {
  view: Period
  onChange: (view: Period) => void
  size?: ControlSize
}

const VIEW_OPTIONS = ['month', 'week', 'day']

export const ViewSwitcher = ({view, onChange, size = 'sm'}: Props) => {
  return (
    <div className="flex gap-2 items-center">
      <Components.Label>Period</Components.Label>
      <Components.Select
        options={VIEW_OPTIONS}
        value={view}
        size={size}
        onChange={(event) => onChange(event.target.value as Period)}
        className="!w-24"
      />
    </div>
  )
}
