import cx from 'classnames'
import {ReactComponent as Logout} from 'icons/Logout.svg'
import {ReactComponent as Person} from 'icons/Person.svg'
import {UserProvider} from 'providers/UserProvider'
import {useState} from 'react'
import {NavbarHeader} from './NavbarHeader'
import {NavbarItem} from './NavbarItem'
import {NavbarItems} from './NavbarItems'

type Props = {
  className?: string
}

export const DesktopNavbar = ({className}: Props) => {
  const [collapsed, setCollapsed] = useState(false)

  const user = UserProvider.useUser()

  const signOut = () => {
    window.localStorage.removeItem('token')
    window.location.href = '/signIn'
  }

  return (
    <div className={cx('shrink-0 hidden lg:block', collapsed ? 'w-16' : 'w-64', className)}>
      <div className={cx('fixed flex flex-col border-r py-3 px-3 bg-exp-dark-blue text-white h-full justify-between', collapsed ? 'w-16' : 'w-64')}>
        <div className="flex flex-col">
          <NavbarHeader onCollapseToggle={() => setCollapsed((prev) => !prev)} collapsed={collapsed} />
          <NavbarItems collapsed={collapsed} />
        </div>
      </div>
    </div>
  )
}
