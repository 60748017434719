import {MAX_BUFFER_MIN, MAX_CHECK_IN_DURATION_MIN} from 'common/consts/scheduling'
import {range} from 'lodash-es'

const getDurationOptions = (maxDurationMin: number) => {
  return range(Math.floor(maxDurationMin / 15) + 1).map((i) => {
    const value = String(i * 15)
    return {
      label: `${value} min`,
      value,
    }
  })
}

export const CHECK_IN_DURATION_OPTIONS = getDurationOptions(MAX_CHECK_IN_DURATION_MIN)
export const BUFFER_OPTIONS = getDurationOptions(MAX_BUFFER_MIN)
