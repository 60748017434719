import {Guides} from 'common/types'
import {guideHooks} from 'hooks/guides'
import {keyBy} from 'lodash-es'
import {UserProvider} from 'providers/UserProvider'
import {useMemo} from 'react'

const DEFAULT_VALUE: Guides.Basic.StorableEntity = {
  id: '',
  email: '',
  handle: '',
  createdAt: '',
  updatedAt: '',
  lastLoginAt: '',
  isDeleted: false,
  isTrainer: false,
  firstName: '',
  lastName: '',
  roles: [],
  locationIds: [],
  productIds: [],
  status: 'new',
  wiseEmail: '',
  topAchieverHistory: {},
  probationPassedAt: '',
}

const useList = () => {
  const user = UserProvider.useUser()

  const listQuery = guideHooks.useListQuery(
    {
      orderBy: ['handle'],
      where: [{field: 'locationIds', op: '<@', values: user.locationIds}],
    },
    {
      staleTime: 30 * 60 * 1000,
    }
  )

  return listQuery.data || []
}

const useMap = () => {
  const list = useList()

  return useMemo(() => {
    return keyBy(list, 'id')
  }, [list])
}

const useOptions = (withFilter?: (item: Guides.Basic.StorableEntity) => boolean) => {
  const list = useList()

  return useMemo(() => {
    return list.filter(withFilter ? withFilter : () => true).map((item) => item.handle)
  }, [list, withFilter])
}

const useById = (id: string) => {
  const map = useMap()
  return map[id] || DEFAULT_VALUE
}

export const GuideManager = {
  useList,
  useOptions,
  useMap,
  useById,
}
