import {Field} from 'react-final-form'
import { Text } from 'ui/components'
import { getFormError } from 'ui/utils/getFormError'

type Props = {
  name: string
}

export const ErrorField = ({name}: Props) => {
  return (
    <Field
      type="hidden"
      name={name}
      render={({meta}) => {
        const error = getFormError(meta)
        return error ? <Text color="red" size='sm'>{error}</Text> : null
      }}
    />
  )
}
