import {Params, Responses} from 'common/types/notifications'
import {Utils} from 'ui'
import {axiosInstance} from './getInstance'

export class CrudApi extends Utils.CrudApi<
  Params.Count,
  Params.List,
  never,
  never,
  Responses.List,
  never,
  never,
  never
> {
  async markAsSeen(id: string): Promise<void> {
    await this.axiosInstance.post<void>(`${this.entityApiPath}/${id}/markAsSeen`)
  }
}

export const crudApi = new CrudApi('/notifications', axiosInstance)
