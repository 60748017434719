import cx from 'classnames'
import {Shifts} from 'common/types'
import {Storable} from 'common/types/utils'
import {ProductManager} from 'hooks/scheduling/ProductManager'
import moment from 'moment'
import {formatIntervalAndDuration} from 'utils/formatIntervalAndDuration'

type Props = {
  shift: Storable<Shifts.Basic.AugmentedEntity>
  className?: string
}

export const ShiftDetails = ({shift, className}: Props) => {
  const product = ProductManager.useById(shift.productId)

  return (
    <div className={cx('flex gap-4', className)}>
      <div className="h-6 bg-slate-500 text-white px-2 py-0.5 font-bold">{product.shortName}</div>
      <div className="h-6 bg-slate-500 text-white px-2 py-0.5 font-bold">{formatIntervalAndDuration(shift.startedAt, shift.endedAt)}</div>
      <div className="h-6 bg-slate-500 text-white px-2 py-0.5 font-bold">{moment(shift.startedAt).format('ddd, MMM DD, YYYY')}</div>
    </div>
  )
}
