import {Notifications} from 'common/types'
import {AssignmentAccepted} from './cards/AssignmentAccepted'
import {AssignmentDeclined} from './cards/AssignmentDeclined'
import {GuideAssigned} from './cards/GuideAssigned'
import {GuideUnassigned} from './cards/GuideUnassigned'
import { TransferRequested } from './cards/TransferRequested'
import { TransferAccepted } from './cards/TransferAccepted'
import { TransferDeclined } from './cards/TransferDeclined'

type Props = {
  notification: Notifications.Basic.StorableEntity<Notifications.Basic.Details>
}

function hasDetailsType<Type extends Notifications.Basic.Details['type']>(
  notification: {details: {type: string}},
  type: Type
): notification is {details: {type: Type}} {
  return notification.details.type === type
}

export const Notification = ({notification}: Props) => {
  if (hasDetailsType(notification, 'assignmentDeclined')) {
    return <AssignmentDeclined notification={notification} />
  }

  if (hasDetailsType(notification, 'assignmentAccepted')) {
    return <AssignmentAccepted notification={notification} />
  }

  if (hasDetailsType(notification, 'guideAssigned')) {
    return <GuideAssigned notification={notification} />
  }

  if (hasDetailsType(notification, 'guideUnassigned')) {
    return <GuideUnassigned notification={notification} />
  }

  if (hasDetailsType(notification, 'transferRequested')) {
    return <TransferRequested notification={notification} />
  }

  if (hasDetailsType(notification, 'transferAccepted')) {
    return <TransferAccepted notification={notification} />
  }

  if (hasDetailsType(notification, 'transferDeclined')) {
    return <TransferDeclined notification={notification} />
  }

  return null
}
