import cx from 'classnames'
import {range} from 'lodash-es'
import {ChangeEventHandler, useMemo} from 'react'
import {ControlSize} from 'ui/types'
import {GroupItemsPicker} from './GroupItemsPicker'

type Props = {
  id?: string
  label?: string
  error?: string
  name?: string
  value?: string
  onChange?: ChangeEventHandler<HTMLInputElement>
  disabled?: boolean
  expanded?: boolean
  size?: ControlSize
  className?: string
  placeholder?: string
}

const HOUR_OPTIONS = range(24).map((hour) => (hour < 10 ? '0' + hour : '' + hour))
const MINUTE_OPTIONS = ['00', '15', '30', '45']

const buildValue = (groupValues: string[]) => {
  if (groupValues.some((groupValue) => !groupValue)) {
    return ''
  }
  return groupValues.join(':')
}

const parseValue = (value: string) => {
  if (!value) {
    return ['', '']
  }
  return value.split(':')
}

export const TimePicker = ({
  value,
  onChange,
  size = 'default',
  label,
  id,
  name,
  error,
  disabled,
  expanded,
  className,
  placeholder = 'None',
}: Props) => {
  const groups = useMemo(() => {
    return [
      {options: HOUR_OPTIONS, name: 'Hour'},
      {options: MINUTE_OPTIONS, name: 'Minute'},
    ]
  }, [])

  return (
    <GroupItemsPicker
      value={value}
      onChange={onChange}
      size={size}
      label={label}
      id={id}
      name={name}
      error={error}
      groups={groups}
      buildValue={buildValue}
      parseValue={parseValue}
      disabled={disabled}
      expanded={expanded}
      className={cx('w-14', className)}
      placeholder={placeholder}
    />
  )
}
