import cx from 'classnames'
import {ChangeEventHandler, MouseEvent, useCallback, useEffect, useRef, useState} from 'react'
import {ReactComponent as CheckboxBlankOutlineIcon} from '../icons/checkbox-blank-outline.svg'
import {ReactComponent as CheckboxIndeterminateIcon} from '../icons/checkbox-indeterminate.svg'
import {ReactComponent as CheckboxMarkedIcon} from '../icons/checkbox-marked.svg'
import {Label} from './Label'
import {Text} from './Text'

declare const Object: any

type Color = 'default' | 'green' | 'red'

export type CheckboxValue = 'checked' | 'unchecked' | 'indeterminate'

type Props = {
  id?: string
  label?: string
  error?: string
  color?: Color
  name?: string
  value?: CheckboxValue
  onChange?: ChangeEventHandler<HTMLInputElement>
  className?: string
  disabled?: boolean
  indeterminate?: boolean
  labelPosition?: 'left' | 'right'
}

const getColorClasses = (color: Color) => {
  switch (color) {
    case 'default':
      return 'text-blue-700'
    case 'green':
      return 'text-green-700'
    case 'red':
      return 'text-red-700'
  }
}

export const Checkbox = ({
  id,
  label,
  error,
  color = 'default',
  name,
  value: initialValue = 'unchecked',
  onChange,
  className,
  disabled,
  indeterminate,
  labelPosition = 'right',
}: Props) => {
  const hiddenInputRef = useRef<HTMLInputElement>(null)
  const [value, setValue] = useState(initialValue)

  const colorClasses = getColorClasses(color)
  const resultClassName = cx({'opacity-70': disabled}, colorClasses, className)

  const onInputClick = useCallback(() => {
    setValue((value) => (value === 'checked' ? 'unchecked' : 'checked'))
  }, [])

  useEffect(() => {
    if (hiddenInputRef.current) {
      const nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value').set
      nativeInputValueSetter.call(hiddenInputRef.current, value)
      hiddenInputRef.current.dispatchEvent(new Event('change', {bubbles: true}))
    }
  }, [value])

  const onLabelClick = useCallback((event: MouseEvent) => {
    event.preventDefault()
  }, [])

  return (
    <div className={cx('flex flex-col gap-1', className)}>
      <input id={id} type="text" name={name} className="hidden" onChange={onChange} ref={hiddenInputRef} />
      <div
        className={cx('flex gap-1 items-center select-none', {'cursor-pointer': !disabled, 'pointer-events-none': disabled})}
        onClick={onInputClick}
      >
        {label && labelPosition === 'left' && (
          <Label htmlFor={id} onClick={onLabelClick}>
            {label}
          </Label>
        )}
        {indeterminate && value === 'indeterminate' ? (
          <CheckboxIndeterminateIcon className={resultClassName} />
        ) : value === 'checked' ? (
          <CheckboxMarkedIcon className={resultClassName} />
        ) : (
          <CheckboxBlankOutlineIcon className={resultClassName} />
        )}
        {label && labelPosition === 'right' && (
          <Label htmlFor={id} onClick={onLabelClick}>
            {label}
          </Label>
        )}
      </div>
      {error && <Text color="red">{error}</Text>}
    </div>
  )
}
