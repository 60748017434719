import cx from 'classnames'
import Dropdown from 'components/Dropdown'
import {ReactComponent as NavIcon} from 'icons/Nav.svg'
import {UserProvider} from 'providers/UserProvider'
import {useMemo} from 'react'
import {useAppContext} from './AppContext'

type Props = {
  className?: string
  showNav: Function
}

export const AppHeader = ({className, showNav}: Props) => {
  const appContext = useAppContext()
  const user = UserProvider.useUser()

  const signOut = () => {
    window.localStorage.removeItem('token')
    window.location.href = '/signIn'
  }

  const ProfileIcon = useMemo(
    () =>
      function ProfileIcon({className}: {className?: string}) {
        return (
          <div
            className={cx('rounded-full min-w-6 h-6 mr-2 bg-blue-500 text-white flex items-center justify-center font-bold text-[10px]', className)}
          >
            {user.firstName[0]}
            {user.lastName[0]}
          </div>
        )
      },
    [user]
  )

  return (
    <div className="w-full flex items-center bg-white border-b border-gray-300 py-1 px-5 lg:sticky lg:top-0 z-20">
      <h1 className="text-2xl font-extrabold text-exp-dark-blue inline pr-4">
        {appContext.data.title}
        {appContext.data.subtitle && <span className="text-lg font-normal text-gray-500 ml-2">&raquo; {appContext.data.subtitle}</span>}
      </h1>
      <div className="grow flex">{appContext.data.children && appContext.data.children}</div>
      <div className="flex items-center gap-4">
        <Dropdown>
          <Dropdown.Trigger>
            <span className="inline-flex rounded-md">
              <button
                type="button"
                className="inline-flex items-center rounded-md border border-transparent bg-white px-3 py-2 text-sm font-medium leading-4 text-gray-500 transition duration-150 ease-in-out hover:text-gray-700 focus:outline-none"
              >
                <ProfileIcon />
                {`${user.firstName} ${user.lastName}`}

                <svg className="-me-0.5 ms-2 h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </span>
          </Dropdown.Trigger>

          <Dropdown.Content>
            <Dropdown.Link onClick={signOut} href="#" method="post" as="button">
              Log Out
            </Dropdown.Link>
          </Dropdown.Content>
        </Dropdown>

        <button className="border rounded-md border-gray-200 mr-2 lg:hidden p-1 text-gray-400" onClick={() => showNav(true)}>
          <NavIcon className="w-6 h-6" />
        </button>
      </div>
    </div>
  )
}
