import {Components, Types, Utils} from 'ui'
import {ControlSize} from 'ui/types'

type Props = {
  options: Types.SelectOptions
  productIds: string[]
  onChange: (productIds: string[]) => void
  expanded?: boolean
  className?: string
  size?: ControlSize
}

export const ProductFilter = ({options, productIds, onChange, className, expanded, size = 'sm'}: Props) => {
  return (
    <Components.MultiSelect
      options={options}
      value={productIds}
      size={size}
      onChange={(event) => onChange(Utils.getMultiSelectValues(event))}
      placeholder="Select tour"
      expanded={expanded}
      className={className}
    />
  )
}
