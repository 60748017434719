import {Params, Responses} from 'common/types/guides'
import {Utils} from 'ui'
import {axiosInstance} from './getInstance'

export class CrudApi extends Utils.CrudApi<Params.Count, Params.List, never, Params.Update, Responses.List, Responses.Item, never, Responses.Update> {
  async listOfPossibleForAssignment(params: Params.ListOfPossibleForAssignment): Promise<Responses.ListOfPossibleForAssignment> {
    const result = await this.axiosInstance.post<Responses.ListOfPossibleForAssignment>(this.entityApiPath + '/list/possibleForAssignment', params)
    return result.data
  }
}

export const crudApi = new CrudApi('/guides', axiosInstance)
