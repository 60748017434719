import {AxiosError} from 'axios'
import {Users} from 'common/types'
import {useIAmQuery} from 'hooks/auth'
import React, {ReactNode, useContext, useState} from 'react'

type Props = {
  children: ReactNode
}

const DEFAULT_USER: Users.Basic.JwtEntity = {
  id: '',
  email: '',
  createdAt: '',
  updatedAt: '',
  firstName: '',
  lastName: '',
  isDeleted: true,
  roles: [],
  permissions: [],
  locationIds: [],
  lastLoginAt: '',
}

type UserContextValue = Users.Basic.JwtEntity

const UserContext = React.createContext<UserContextValue>(DEFAULT_USER)

export const UserProvider = ({children}: Props) => {
  const [user, setUser] = useState<Users.Basic.JwtEntity | null>(null)

  useIAmQuery({
    cacheTime: 0,
    onSuccess: setUser,
    onError: (error: AxiosError) => {
      if (error.response?.status === 401) {
        window.location.href = `/signIn?redirect=${window.location.pathname}`
        return
      }
    },
  })

  if (!user) {
    return null
  }

  return <UserContext.Provider value={user}>{children}</UserContext.Provider>
}

UserProvider.useUser = (): Users.Basic.JwtEntity => {
  return useContext(UserContext)
}
