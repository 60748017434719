import {Components, Types, Utils} from 'ui'
import {ControlSize} from 'ui/types'

type Props = {
  options: Types.SelectOptions
  categories: string[]
  onChange: (categories: string[]) => void
  expanded?: boolean
  className?: string
  size?: ControlSize
}

export const CategoryFilter = ({options, categories, onChange, expanded, className, size = 'sm'}: Props) => {
  return (
    <Components.MultiSelect
      options={options}
      value={categories}
      size={size}
      onChange={(event) => onChange(Utils.getMultiSelectValues(event))}
      placeholder="Select status"
      className={className}
      expanded={expanded}
    />
  )
}
