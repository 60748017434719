import logo from 'logo.png'
import {Form} from 'react-final-form'
import {Components, Fields} from 'ui'
import {Link} from 'components/Link'
import {useResetPasswordMutation, useResetPasswordTokenValidationQuery} from 'hooks/auth'
import {ReactNode, useState} from 'react'
import {useParams} from 'react-router-dom'
import { isEmpty, trim } from 'lodash-es'
import {Params} from 'common/types/auth'

type Errors = Partial<Record<keyof Params.ResetPassword, string>> & {
  password?: string
  passwordConfirm?: string
}

const validate = (values: Params.ResetPassword) => {
  const errors: Errors = {};

  const trimmedPassword = trim(values.password);
  if (isEmpty(trimmedPassword)) {
    errors.password = 'Password shouldn\'t be empty';
  }

  if (trimmedPassword.length < 8) {
    errors.password = 'Password should be more than 8 symbols';
  }

  if (values.passwordConfirm !== values.password) {
    errors.passwordConfirm = 'Password confirmation should be the same as password';
  }

  return errors;
}

export const ResetPassword = () => {
  const { token } = useParams();
  if (!token) {
    throw new Error('Token is not defined');
  }

  const [isResetSuccessfull, setIsResetSuccessfull] = useState(false)

  const { isLoading, data } = useResetPasswordTokenValidationQuery({ token });

  const resetPasswordMutation = useResetPasswordMutation();

  const onSubmit = (values: Params.ResetPassword) => {
    const errors = validate(values);
    if (!isEmpty(errors)) {
      return errors;
    }

    resetPasswordMutation.mutate(values, {
      onSuccess() {
        setIsResetSuccessfull(true);
      }
    })
  }

  const renderForm = (): ReactNode => {
    return <Form
      onSubmit={onSubmit}
      render={({handleSubmit, pristine, values}) => {
        const disableReset = pristine
          || isEmpty(values.password)
          || isEmpty(values.passwordConfirm)
          || values.password !== values.passwordConfirm

        return (
          <form onSubmit={handleSubmit} className="flex flex-col gap-4 w-full">
            <Fields.InputField type="hidden" name="token" initialValue={token} />

            <Fields.InputField label="Password" name="password" type="password" expanded />
            <Fields.InputField label="Confirm password" name="passwordConfirm" type="password" expanded />

            <Components.Button disabled={disableReset} className="" expanded>
              Apply new password
            </Components.Button>
          </form>
        )
      }}
    />
  }

  const renderMessage = (message: string): ReactNode => {
    return (<div className="text-center">{message}</div>);
  }

  const renderContent = (): ReactNode => {
    if (isResetSuccessfull) {
      return renderMessage('Password was successfully changed');
    }

    if (isLoading || !data) {
      return renderMessage('Loading...');
    }

    if (data.isValid) {
      return renderForm();
    } else {
      return renderMessage('Token is expired or invalid');
    }
  }

  return (
    <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-50">
      <div className="mx-4 bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12 w-96 flex flex-col items-center gap-6">
        <div className="flex flex-col items-center">
          <img src={logo} className="h-12 w-12 mb-3" alt="ExperienceFirst logo" />
          <div className="font-bold text-lg">ExperienceFirst Scheduler</div>
        </div>

        { renderContent() }

        <Link
          className='text-sm'
          redirectTo='/signIn'
        >
          Sign In
        </Link>
      </div>
    </div>
  )
}
