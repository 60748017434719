import {UnavailabilityEvents} from 'common/types'
import {Utils} from 'ui'
import {axiosInstance} from './getInstance'

export const crudApi = new Utils.CrudApi<
  never,
  UnavailabilityEvents.Params.List,
  never,
  never,
  UnavailabilityEvents.Responses.List,
  never,
  never,
  never
>('/unavailabilityEvents', axiosInstance)
