import {Notifications} from 'common/types'
import {TourEventCardInfo} from 'components/TourEventCardInfo'
import {getDayPeriodIdByDate} from 'components/filters/DayFilter'
import {AssignmentStatusPill} from 'components/pills/AssignmentStatusPill'
import {GuideManager} from 'hooks/scheduling/GuideManager'
import {useCallback} from 'react'
import {useNavigate} from 'react-router-dom'
import {Components} from 'ui'
import {NotificationCard} from '../NotificationCard'
import {useAssignmentById} from '../hooks/useAssignment'
import {useMarkAsSeen} from '../hooks/useMarkAsSeen'

type Props = {
  notification: Notifications.Basic.StorableEntity<Notifications.Basic.AssignmentDeclinedDetails>
}

export const AssignmentDeclined = ({notification}: Props) => {
  const navigate = useNavigate()
  const {isSeen, markAsSeen} = useMarkAsSeen(notification, 'manual')
  const assignment = useAssignmentById(notification.details.assignmentId)
  const guide = GuideManager.useById(assignment.guideId)

  const scheduleIt = useCallback(() => {
    markAsSeen()
    navigate(`/shifts/scheduling?view=day&periodId=${getDayPeriodIdByDate(assignment.startedAt)}&eventId=${assignment.shiftId}`)
  }, [assignment, markAsSeen, navigate])

  return (
    <NotificationCard isSeen={isSeen}>
      <TourEventCardInfo title={`Shift rejected by ${guide.firstName} ${guide.lastName}`} event={assignment} />
      {isSeen ? (
        <AssignmentStatusPill status="declined" />
      ) : (
        <Components.Button onClick={scheduleIt} size="sm">
          Schedule it
        </Components.Button>
      )}
    </NotificationCard>
  )
}
