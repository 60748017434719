import {Storable} from 'common/types/utils'
import {formatIntervalAndDuration} from 'utils/formatIntervalAndDuration'
import {Preview} from '../../../components/Preview'
import { isOutdatedEvent } from 'utils/isOutdatedEvent'
import { TextEllipsis } from 'components/TextEllipsis'
import { UnavailabilityEvents } from 'common/types'

type Props = {
  unavailability: Storable<UnavailabilityEvents.Basic.AugmentedEntity>
  onClick?: () => void
  focus?: boolean
}

export const UnavailabilityPreview = ({unavailability, onClick, focus}: Props) => {
  return (
    <Preview
      borderColor="gray"
      backgroundColor="rgb(209 213 219)"
      onClick={onClick}
      focus={focus}
      opacity={isOutdatedEvent(unavailability) ? '50%' : 'unset'}
    >
      <div className="w-full p-0.5 leading-[1.35] min-w-0 text-[11px]">
        <TextEllipsis className='font-semibold'>
          {formatIntervalAndDuration(unavailability.startedAt, unavailability.endedAt)}
        </TextEllipsis>
        <TextEllipsis>{unavailability.description ? unavailability.description : 'No description'}</TextEllipsis>
      </div>
    </Preview>
  )
}
