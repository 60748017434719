import {Guides, GuidesStatuses, Users} from 'common/types'
import {PageHeader} from 'components/PageHeader'
import {guideHooks} from 'hooks/guides'
import {guidesStatusesHooks} from 'hooks/guidesStatuses'
import {locationHooks} from 'hooks/locations'
import {productHooks} from 'hooks/products'
import {useId} from 'hooks/useId'
import {userHooks} from 'hooks/users'
import {pick} from 'lodash-es'
import moment from 'moment'
import {Form} from 'react-final-form'
import {Components, Fields} from 'ui'
import {Frame} from 'ui/components/Frame'
import {isEmptyValue} from 'ui/utils'
import {getLastMonths} from 'utils/getLastMonths'
import {GrayBox} from './GrayBox'

type UserValues = Omit<Users.Params.Update, 'locationIds'> & {locationId: string}
type GuideValues = Guides.Params.Update
type GuideStatusValues = GuidesStatuses.Params.Create[number]

export const Edit = () => {
  const id = useId()
  const itemQuery = guideHooks.useItemQuery(id)
  const months = getLastMonths()
  const lastMonth = months[months.length - 1]

  const locationsQuery = locationHooks.useListQuery({
    orderBy: ['name'],
  })

  const productsQuery = productHooks.useListQuery({
    orderBy: ['name'],
  })

  const updateUserDataMutation = userHooks.useUpdateMutation()
  const updateGuideDataMutation = guideHooks.useUpdateMutation()
  const pushGuideStatus = guidesStatusesHooks.useCreateMutation()

  const updateUserData = (values: UserValues) => {
    updateUserDataMutation.mutate(
      {
        id,
        email: values.email,
        firstName: values.firstName,
        lastName: values.lastName,
        roles: values.roles,
        locationIds: [values.locationId],
        isDeleted: values.isDeleted,
      },
      {
        onSuccess() {
          itemQuery.refetch()
        },
      }
    )
  }

  const updateGuideData = (values: GuideValues) => {
    updateGuideDataMutation.mutate(values, {
      onSuccess() {
        itemQuery.refetch()
      },
    })
  }

  const updateGuideStatus = (values: GuideStatusValues) => {
    pushGuideStatus.mutate([values], {
      onSuccess() {
        itemQuery.refetch()
      },
    })
  }

  if (!itemQuery.data || !locationsQuery.data || !productsQuery.data) {
    return null
  }

  const locationOptions = locationsQuery.data.map((location) => {
    return {
      label: location.name,
      value: location.id,
    }
  })

  const productOptions = productsQuery.data
    .filter((product) => {
      return itemQuery.data.locationIds.includes(product.locationId)
    })
    .map((product) => {
      return {
        label: product.shortName,
        value: product.id,
      }
    })

  return (
    <div className="flex flex-col gap-12">
      <PageHeader title={`${itemQuery.data.firstName} ${itemQuery.data.lastName}`} subtitle="Edit Guide" />

      <Frame title="User info">
        <Form
          initialValues={{
            email: itemQuery.data.email,
            firstName: itemQuery.data.firstName,
            lastName: itemQuery.data.lastName,
            roles: itemQuery.data.roles,
            locationId: itemQuery.data.locationIds[0],
            isDeleted: itemQuery.data.isDeleted,
          }}
          onSubmit={updateUserData}
          render={({handleSubmit, pristine, values}) => {
            const disableSave =
              pristine ||
              isEmptyValue(values.firstName) ||
              isEmptyValue(values.lastName) ||
              isEmptyValue(values.email) ||
              isEmptyValue(values.locationId)

            return (
              <form onSubmit={handleSubmit} className="flex flex-col gap-4">
                <Fields.InputField label="First name" name="firstName" expanded />
                <Fields.InputField label="Last name" name="lastName" expanded />
                <Fields.InputField type="email" label="Email" name="email" expanded />
                <Fields.SelectField label="Location" name="locationId" options={locationOptions} expanded />
                <Fields.CheckboxField label="Inactive" name="isDeleted" />

                <Components.Button disabled={disableSave} className="ml-auto">
                  Save
                </Components.Button>
              </form>
            )
          }}
        />
      </Frame>
      <Frame title="Guide info">
        <Form
          initialValues={pick(itemQuery.data, 'id', 'handle', 'wiseEmail', 'phone', 'productIds', 'probationPassedAt', 'isTrainer')}
          onSubmit={updateGuideData}
          render={({handleSubmit, pristine, values}) => {
            const disableSave = pristine || isEmptyValue(values.handle) || isEmptyValue(values.productIds)

            return (
              <form onSubmit={handleSubmit} className="flex flex-col gap-4">
                <Fields.InputField label="Handle" name="handle" expanded />
                <Fields.InputField type="email" label="Wise email" name="wiseEmail" expanded />
                <Fields.InputField type="phone" label="Phone" name="phone" expanded />
                <Fields.MultiSelectField label="Tours" name="productIds" options={productOptions} expanded />
                <Fields.DatePickerField
                  label="Probation passed at"
                  name="probationPassedAt"
                  className="!w-full"
                  disabled={Boolean(itemQuery.data.probationPassedAt)}
                  placeholder="Not Passed"
                />
                <Fields.CheckboxField label="Guide trainer" name="isTrainer" />

                <Components.Button disabled={disableSave} className="ml-auto">
                  Save
                </Components.Button>
              </form>
            )
          }}
        />
      </Frame>
      {itemQuery.data.probationPassedAt && (
        <Frame title="Top achiever history">
          <Form
            initialValues={
              pick(itemQuery.data.topAchieverHistory[lastMonth], 'guideId', 'isTopAchiever', 'month') || {
                guideId: id,
                isTopAchiever: false,
                month: lastMonth,
              }
            }
            onSubmit={updateGuideStatus}
            render={({handleSubmit, pristine, values}) => {
              const disableSave = pristine || isEmptyValue(values.isTopAchiever)

              return (
                <form onSubmit={handleSubmit} className="flex flex-col gap-4">
                  <table className="w-full text-sm text-left text-gray-500 table-fixed">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                      <tr>
                        {months.map((month) => {
                          return (
                            <th key={month} className="px-6 py-3">
                              {moment(month).format('MMMM')}
                            </th>
                          )
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        {months.slice(0, 4).map((month) => {
                          return (
                            <td key={month} className="px-6 py-4">
                              {itemQuery.data.topAchieverHistory[month] ? (
                                <Components.Checkbox
                                  value={itemQuery.data.topAchieverHistory[month]?.isTopAchiever ? 'checked' : 'unchecked'}
                                  disabled
                                />
                              ) : (
                                <GrayBox />
                              )}
                            </td>
                          )
                        })}
                        <td className="px-6 py-4">
                          <Fields.CheckboxField name="isTopAchiever" />
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <Components.Button disabled={disableSave} className="ml-auto">
                    Save
                  </Components.Button>
                </form>
              )
            }}
          />
        </Frame>
      )}
    </div>
  )
}
