import {Field} from 'react-final-form'
import {Select} from '../components'
import {ControlSize, SelectOption, SelectOptions} from '../types'

type Props = {
  id?: string
  label?: string
  name: string
  options: SelectOptions
  disabled?: boolean
  expanded?: boolean
  className?: string
  size?: ControlSize
  notSelectedOption?: boolean | SelectOption
  hideSeparator?: boolean
  initialValue?: string
  placeholder?: string
}

export const SelectField = ({name, initialValue, ...other}: Props) => {
  return (
    <Field
      name={name}
      initialValue={initialValue}
      type="select"
      render={({input}) => {
        return <Select {...input} {...other} />
      }}
    />
  )
}
