import { Users } from 'common/types'
import {useSignInMutation} from 'hooks/auth'
import {isEmpty} from 'lodash-es'
import logo from 'logo.png'
import { useCallback } from 'react'
import {Form} from 'react-final-form'
import {useLocation} from 'react-router-dom'
import {Components, Fields} from 'ui'
import {Link} from 'components/Link'

type Values = Users.Params.SignIn

export const SignIn = () => {
  const location = useLocation()
  const signInMutate = useSignInMutation().mutate

  const onSubmit = useCallback(async (values: Values) => {
    signInMutate(values, {
      onSuccess(token) {
        window.localStorage.setItem('token', token)
        const searchPparams = new URLSearchParams(location.search)
        const redirect = searchPparams.get('redirect') || '/'
        window.location.href = redirect
      },
    })
  }, [location.search, signInMutate])

  return (
    <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-50">
      <div className="mx-4 bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12 w-96 flex flex-col items-center gap-6">
        <div className="flex flex-col items-center">
          <img src={logo} className="h-12 w-12 mb-3" alt="ExperienceFirst logo" />
          <div className="font-bold text-lg">ExperienceFirst Scheduler</div>
        </div>

        <Form
          onSubmit={onSubmit}
          render={({handleSubmit, pristine, values}) => {
            const disableSave = pristine || isEmpty(values.email) || isEmpty(values.password)

            return (
              <form onSubmit={handleSubmit} className="flex flex-col gap-4 w-full">
                <Fields.InputField label="Email" name="email" expanded />
                <Fields.InputField label="Password" name="password" type="password" expanded />

                <Components.Button color="exp-orange" disabled={disableSave} expanded>
                  Sign in
                </Components.Button>
              </form>
            )
          }}
        />

        <Link
          className='text-sm'
          redirectTo='/forgotPassword'
        >
          Forgot password
        </Link>
      </div>
    </div>
  )
}
