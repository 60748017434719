import {Field} from 'react-final-form'
import {getFormError} from 'ui/utils/getFormError'
import {DatePicker} from '../components'
import {ControlSize} from '../types'

type Props = {
  id?: string
  label?: string
  name: string
  type?: string
  disabled?: boolean
  expanded?: boolean
  className?: string
  size?: ControlSize
  initialValue?: string
  placeholder?: string
}

export const DatePickerField = ({id, label, name, type, disabled, expanded, className, size, initialValue, placeholder}: Props) => {
  return (
    <Field
      type={type}
      name={name}
      initialValue={initialValue}
      render={({input, meta}) => {
        return (
          <DatePicker
            {...input}
            id={id}
            label={label}
            disabled={disabled}
            expanded={expanded}
            className={className}
            size={size}
            error={getFormError(meta)}
            placeholder={placeholder}
          />
        )
      }}
    />
  )
}
