import cx from 'classnames'
import {Shifts} from 'common/types'
import {Storable} from 'common/types/utils'
import {InfoBlock} from 'components/InfoBlock/InfoBlock'
import {bookingHooks} from 'hooks/bookings'
import {groupBy, keys, mapValues, sortBy, sumBy} from 'lodash-es'

type Props = {
  shift: Storable<Shifts.Basic.AugmentedEntity>
  className?: string
}

export const ResellerBreakdown = ({shift, className}: Props) => {
  const bookingsQuery = bookingHooks.useListQuery({
    where: [{field: 'shiftId', value: shift.id}],
  })

  if (!bookingsQuery.data) {
    return null
  }

  const bookingsWithReseller = bookingsQuery.data.filter((booking) => booking.ventrataResellerName)
  const bookingsWithoutReseller = bookingsQuery.data.filter((booking) => !booking.ventrataResellerName)

  const resellerGroups = groupBy(bookingsWithReseller, 'ventrataResellerName')
  const resellerNames = sortBy(keys(resellerGroups))
  const resellerBreakdown = mapValues(resellerGroups, (bookings) => sumBy(bookings, (booking) => booking.adultQty + booking.childQty))

  return (
    <div className={cx('flex flex-col text-sm w-full gap-4', className)}>
      <div className="font-bold">Reseller Breakdown</div>
      <InfoBlock>
        {resellerNames.map((resellerName, i) => {
          return (
            <InfoBlock.Item key={resellerName} label={resellerName}>
              {resellerBreakdown[resellerName]}
            </InfoBlock.Item>
          )
        })}
        <InfoBlock.Item label="Without reseller">{sumBy(bookingsWithoutReseller, (booking) => booking.adultQty + booking.childQty)}</InfoBlock.Item>
      </InfoBlock>
    </div>
  )
}
