/* eslint-disable react-hooks/rules-of-hooks */
import {guideApi} from 'api'
import {CrudApi} from 'api/guides'
import {Params, Responses} from 'common/types/guides'
import {useQuery} from 'react-query'
import {Types, Utils} from 'ui'

class CrudHooks extends Utils.CrudHooks<
  Params.Count,
  Params.List,
  Params.Create,
  Params.Update,
  Responses.List,
  Responses.Item,
  Responses.Create,
  Responses.Update,
  CrudApi
> {
  useListOfPossibleForAssignmentQuery(params: Params.ListOfPossibleForAssignment, options?: Types.QueryOptions<Responses.ListOfPossibleForAssignment>) {
    return useQuery<Responses.ListOfPossibleForAssignment>(
      [this.entityKey, 'listOfPossibleForAssignment', params],
      () => this.crudApi.listOfPossibleForAssignment(params),
      options
    )
  }
}

export const guideHooks = new CrudHooks('guide', 'Guide', guideApi)
