import {TourEventLike} from 'common/types/utils'
import {LocationManager} from 'hooks/scheduling/LocationManager'
import {ProductManager} from 'hooks/scheduling/ProductManager'
import moment from 'moment'
import {formatIntervalAndDuration} from 'utils/formatIntervalAndDuration'
import {InfoBlock} from './InfoBlock'
import { ReactNode } from 'react'

type Props = {
  event: TourEventLike
  className?: string
  children?: ReactNode
}

export const TourEventInfoBlock = ({event, className, children}: Props) => {
  const product = ProductManager.useById(event.productId)
  const location = LocationManager.useById(event.locationId)
  return (
    <InfoBlock className={className}>
      <InfoBlock.Item label="Tour">{product.shortName}</InfoBlock.Item>
      <InfoBlock.Item label="Location">{location.name}</InfoBlock.Item>
      <InfoBlock.Item label="Date">{moment(event.startedAt).format('ddd, MMM DD, YYYY')}</InfoBlock.Item>
      <InfoBlock.Item label="Duration">{formatIntervalAndDuration(event.startedAt, event.endedAt)}</InfoBlock.Item>
      {children}
    </InfoBlock>
  )
}
