import {assignmentApi, transferApi} from 'api'
import {Assignments, Notifications, Transfers} from 'common/types'
import {TourEventCardInfo} from 'components/TourEventCardInfo'
import {TransferStatusPill} from 'components/pills/TransferStatusPill'
import {transferHooks} from 'hooks/transfers'
import {useCallback, useEffect, useState} from 'react'
import {useQuery} from 'react-query'
import {Components} from 'ui'
import {NotificationCard} from '../NotificationCard'
import {useAssignmentById} from '../hooks/useAssignment'
import {useMarkAsSeen} from '../hooks/useMarkAsSeen'
import {useTransferById} from '../hooks/useTransferById'

type Props = {
  notification: Notifications.Basic.StorableEntity<Notifications.Basic.TransferRequestedDetails>
}

export const TransferRequested = ({notification}: Props) => {
  const {isSeen, markAsSeen} = useMarkAsSeen(notification, 'manual')
  const initialAssignment = useAssignmentById(notification.details.assignmentId)
  const initialTransfer = useTransferById(notification.details.transferId)

  const [assignment, setAssignment] = useState<Assignments.Basic.StorableAugmentedEntity>(initialAssignment)
  const [transfer, setTransfer] = useState<Transfers.Basic.StorableEntity>(initialTransfer)

  const query = useQuery(
    ['transferRequestData'],
    async () => {
      const [assignment, transfer] = await Promise.all([
        assignmentApi.item(notification.details.assignmentId),
        transferApi.item(notification.details.transferId),
      ])

      return {assignment, transfer}
    },
    {
      enabled: false,
      onSuccess({assignment, transfer}) {
        setAssignment(assignment)
        setTransfer(transfer)
      },
    }
  )

  useEffect(() => {
    if (query.data) {
      markAsSeen()
    }
  }, [query.data, markAsSeen])

  const acceptMutate = transferHooks.useAcceptMutation().mutate
  const declineMutate = transferHooks.useDeclineMutation().mutate

  const accept = useCallback(async () => {
    acceptMutate(notification.details.transferId, {
      onSuccess: () => query.refetch(),
    })
  }, [acceptMutate, notification, query])

  const decline = useCallback(() => {
    declineMutate(notification.details.transferId, {
      onSuccess: () => query.refetch(),
    })
  }, [declineMutate, notification, query])

  return (
    <NotificationCard isSeen={isSeen}>
      <TourEventCardInfo title="Shift Transfer Request" event={assignment} />
      {isSeen ? (
        <TransferStatusPill transfer={transfer} />
      ) : (
        <div className="flex gap-4">
          <Components.Button color="green" size="sm" onClick={accept}>
            Accept
          </Components.Button>
          <Components.Button color="red" size="sm" onClick={decline}>
            Decline
          </Components.Button>
        </div>
      )}
    </NotificationCard>
  )
}
