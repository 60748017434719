import {forgotPassword, resetPassword, resetPasswordTokenValidation, iAm, signIn} from 'api'
import {AxiosError} from 'axios'
import {Users} from 'common/types'
import {Params} from 'common/types/auth'
import {useMutation, useQuery} from 'react-query'
import {Types} from 'ui'

export const useIAmQuery = (options: Types.QueryOptions<Users.Basic.JwtEntity, AxiosError, Users.Basic.JwtEntity, string[]>) => {
  return useQuery(['iAm'], () => iAm(), options)
}

export const useSignInMutation = () => {
  return useMutation((params: Params.SignIn) => signIn(params))
}

export const useForgotPasswordMutation = () => {
  return useMutation((params: Params.ForgotPassword) => forgotPassword(params))
}

export const useResetPasswordMutation = () => {
  return useMutation((params: Params.ResetPassword) => resetPassword(params))
}

export const useResetPasswordTokenValidationQuery = (params: Params.ResetPasswordTokenValidation) => {
  return useQuery(
    ['resetPasswordTokenValidation', params.token],
    () => resetPasswordTokenValidation(params)
  )
}