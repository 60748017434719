import { ReactNode } from "react"
import cx from 'classnames'

type Props = {
  children: ReactNode
  className?: string
}

export const TextEllipsis = ({children, className}: Props) => {
  return (
    <div className={cx("text-ellipsis whitespace-nowrap overflow-hidden", className)}>
      {children}
    </div>
  )
}