import {PageHeader} from 'components/PageHeader'
import {ViewSwitcher} from 'components/filters/ViewSwitcher'
import {ReactComponent as MagicStick} from 'icons/MagicStick.svg'
import {keyBy} from 'lodash-es'
import {useInitialEventId} from 'pages/shifts/hooks/useInitialEventId'
import {useInitialPeriodId} from 'pages/shifts/hooks/useInitialPeriodId'
import {Filters, FiltersValue} from 'pages/shifts/operator/Filters'
import {useCallback, useMemo, useState} from 'react'
import {Period} from 'types'
import {Components} from 'ui'
import {usePeriodCalendarComponent} from '../hooks/usePeriodCalendarComponent'
import {usePeriodFilterComponent} from '../hooks/usePeriodFilterComponent'
import {useShiftPreviewComponent} from './ShiftPreview'
import {ShiftViewer} from './ShiftViewer'
import {useShifts} from './useShifts'

type Props = {
  title: string
  period: Period
  onChangeView: (view: Period) => void
  groupFilters: FiltersValue
  onChangeGroupFilters: (filters: FiltersValue) => void
}

export const PeriodView = ({title, period, onChangeView, groupFilters, onChangeGroupFilters}: Props) => {
  const PeriodCalendarComponent = usePeriodCalendarComponent(period)
  const PeriodFilterComponent = usePeriodFilterComponent(period)
  const previewComponent = useShiftPreviewComponent()

  const initialPeriodId = useInitialPeriodId(period)
  const [periodId, setPeriodId] = useState(initialPeriodId)

  const {shifts, refetch, autoAssign, effectivePeriodId} = useShifts({periodId, groupFilters, period})

  const initialEventId = useInitialEventId()
  const [focusedEventId, setFocusedEventId] = useState(initialEventId)
  const [displayedEventId, setDisplayedEventId] = useState(initialEventId)

  const onClose = useCallback(() => {
    setDisplayedEventId('')
  }, [])

  const onChange = useCallback(() => {
    refetch()
  }, [refetch])

  const onSelect = useCallback((eventId: string) => {
    setFocusedEventId(eventId)
    setDisplayedEventId(eventId)
  }, [])

  const shiftMap = useMemo(() => keyBy(shifts, 'id'), [shifts])

  return (
    <>
      <PageHeader title={title}>
        <Components.Button color="green" size="sm" className="ml-auto flex flex-nowrap gap-2 items-center" onClick={autoAssign}>
          <MagicStick className="w-4 h-4" />
          Auto assign
        </Components.Button>
      </PageHeader>

      <div className="flex flex-col">
        <div className="flex flex-col gap-2 p-4 sticky top-[51px] p-4 bg-white z-10 border-b border-gray-300">
          <div className="flex flex-col gap-2 w-full">
            <div className="flex justify-between items-center">
              <div className="flex gap-5 items-center">
                <ViewSwitcher view={period} onChange={onChangeView} />
                <PeriodFilterComponent value={periodId} onChange={setPeriodId} />
              </div>
              <div className="flex w-full justify-end">
                <div className="font-bold">
                  <span className="text-xs">Number of tours: </span>
                  <span className="text-sm">{shifts.length}</span>
                </div>
              </div>
            </div>
            <div className="flex justify-between w-full">
              <Filters value={groupFilters} onChange={onChangeGroupFilters} />
            </div>
          </div>
        </div>
        <PeriodCalendarComponent
          periodId={effectivePeriodId}
          focusedEventId={focusedEventId}
          events={shifts}
          onSelect={onSelect}
          previewComponent={previewComponent}
          headerClassName="sticky top-[160px] z-[2]"
        />
        {displayedEventId && (
          <ShiftViewer shiftId={displayedEventId} initialShiftData={shiftMap[displayedEventId]} onClose={onClose} onChange={onChange} />
        )}
      </div>
    </>
  )
}
