import {UnavailabilityDescriptors} from 'common/types'
import {DATE_FORMAT, TIME_FORMAT} from 'consts'
import {unavailabilityDescriptorHooks} from 'hooks/unavailabilityDescriptors'
import {isEmpty, trim} from 'lodash-es'
import moment from 'moment'
import {UserProvider} from 'providers/UserProvider'
import {useCallback} from 'react'
import {Form} from 'react-final-form'
import {Components, Fields} from 'ui'

type Props = {
  id?: string
  onChange: () => void
  onClose: () => void
}

type Values = {
  fromDate: string
  fromTime: string
  toDate: string
  toTime: string
  untilDate: string
  untilTime: string
  repeat: boolean
  allDay: boolean
  description: string
  frequency: UnavailabilityDescriptors.Basic.Frequency
}

type Errors = Partial<Record<keyof Values, string>> & {
  toDateError?: string
  untilDateError?: string
}

const getStartedAt = (values: Values) => {
  return values.allDay ? moment(values.fromDate) : moment(`${values.fromDate} ${values.fromTime}`)
}

const getEndedAt = (values: Values) => {
  return values.allDay ? moment(values.toDate).add(1, 'day') : moment(`${values.toDate} ${values.toTime}`)
}

const getUntil = (values: Values) => {
  return values.allDay ? moment(values.untilDate).add(1, 'day') : moment(`${values.untilDate} ${values.untilTime}`)
}

const validate = (values: Values) => {
  const errors: Errors = {}

  if (!trim(values.description)) {
    errors.description = 'Description is empty'
  }

  if (getEndedAt(values).isSameOrBefore(getStartedAt(values))) {
    errors.toDateError = 'To date should be later than From date'
  }

  if (values.repeat && getUntil(values).isSameOrBefore(getStartedAt(values))) {
    errors.untilDateError = 'Until date should be later than From date'
  }

  return errors
}

export const UnavailabilityDescriptorModal = ({id, onChange, onClose}: Props) => {
  const user = UserProvider.useUser()

  const unavailabilityDescriptorItemQuery = unavailabilityDescriptorHooks.useItemQuery(id || 'INVALID_ID', {
    enabled: Boolean(id),
  })

  const unavailabilityDescriptorCreateMutate = unavailabilityDescriptorHooks.useCreateMutation().mutate
  const unavailabilityDescriptorRemoveMutate = unavailabilityDescriptorHooks.useRemoveMutation().mutate

  const submit = useCallback((values: Values) => {
    const startedAt = getStartedAt(values)
    const endedAt = getEndedAt(values)
    const until = getUntil(values)

    const errors = validate(values)

    if (!isEmpty(errors)) {
      return errors
    }

    unavailabilityDescriptorCreateMutate(
      {
        startedAt: startedAt.format(),
        endedAt: values.repeat ? until.format() : endedAt.format(),
        duration: endedAt.diff(startedAt),
        description: values.description,
        guideId: user.id,
        frequency: values.repeat ? values.frequency : null,
      },
      {
        onSuccess() {
          onClose()
          onChange()
        },
      }
    )
  }, [unavailabilityDescriptorCreateMutate, onChange, onClose, user])

  const remove = useCallback(() => {
    unavailabilityDescriptorRemoveMutate(id || 'INVALID_ID', {
      onSuccess() {
        onClose()
        onChange()
      },
    })
  }, [unavailabilityDescriptorRemoveMutate, onChange, onClose, id])

  const getInitialValues = () => {
    const today = moment()
    const tomorrow = moment().add(1, 'day')
    const inAWeek = moment().add(7, 'day')

    if (id && unavailabilityDescriptorItemQuery.data) {
      const descriptor = unavailabilityDescriptorItemQuery.data
      const repeat = descriptor.frequency !== null
      const allDay = descriptor.duration % (24 * 60 * 60) === 0
      const startedAt = moment(descriptor.startedAt)
      const endedAt = allDay ? moment(startedAt).add(descriptor.duration).subtract(1, 'day') : moment(startedAt).add(descriptor.duration)
      const until = allDay ? moment(descriptor.endedAt).subtract(1, 'day') : moment(descriptor.endedAt)

      return {
        allDay,
        repeat,
        frequency: descriptor.frequency,
        fromDate: startedAt.format(DATE_FORMAT),
        fromTime: startedAt.format(TIME_FORMAT),
        toDate: endedAt.format(DATE_FORMAT),
        toTime: endedAt.format(TIME_FORMAT),
        description: descriptor.description,
        untilDate: repeat ? until.format(DATE_FORMAT) : inAWeek.format(DATE_FORMAT),
        untilTime: repeat ? until.format(TIME_FORMAT) : inAWeek.format(TIME_FORMAT),
      }
    }

    return {
      allDay: false,
      repeat: false,
      frequency: null,
      fromDate: today.format(DATE_FORMAT),
      fromTime: '00:00',
      toDate: tomorrow.format(DATE_FORMAT),
      toTime: '00:00',
      description: '',
      untilDate: inAWeek.format(DATE_FORMAT),
      untilTime: '00:00',
    }
  }

  return (
    <Components.Modal title={id ? 'Edit unavailability' : 'New unavailability'} onClose={onClose} bodyClassName="w-96">
      <Form
        initialValues={getInitialValues()}
        onSubmit={submit}
        render={({values, handleSubmit}) => {
          const startedAt = getStartedAt(values)
          const endedAt = getEndedAt(values)
          const diffMins = endedAt.diff(startedAt, 'minutes')

          return (
            <form className="flex flex-col gap-4" onSubmit={handleSubmit}>
              <div className="flex flex-col gap-4">
                <div className="flex gap-4 items-center">
                  <Components.Label className="w-12">All day</Components.Label>
                  <Fields.CheckboxField name="allDay" />
                </div>

                <div className="flex gap-4 items-center">
                  <Components.Label className="w-12">From</Components.Label>
                  <Fields.DatePickerField name="fromDate" size="sm" />
                  {!values.allDay && <Fields.TimePickerField name="fromTime" size="sm" />}
                </div>

                <div className="flex flex-col gap-1">
                  <div className="flex gap-4 items-center">
                    <Components.Label className="w-12">To</Components.Label>
                    <Fields.DatePickerField name="toDate" size="sm" />
                    {!values.allDay && <Fields.TimePickerField name="toTime" size="sm" />}
                  </div>
                  <Fields.ErrorField name="toDateError" />
                </div>

                <Fields.TextAreaField label="Description" name="description" size="sm" expanded rows={5} />

                <div className="flex gap-4 items-center">
                  <Components.Label className="w-12">Repeat</Components.Label>
                  <Fields.CheckboxField name="repeat" />
                </div>

                {values.repeat && (
                  <>
                    <div className="flex flex-col gap-2">
                      {diffMins < 24 * 60 && <Fields.RadioField name="frequency" value="daily" label="Daily" />}
                      {diffMins < 7 * 24 * 60 && <Fields.RadioField name="frequency" value="weekly" label="Weekly" />}
                      {diffMins < 28 * 24 * 60 && <Fields.RadioField name="frequency" value="monthly" label="Monthly" />}
                    </div>

                    <div className="flex flex-col gap-1">
                      <div className="flex gap-4 items-center">
                        <Components.Label className="w-12">Until</Components.Label>
                        <Fields.DatePickerField name="untilDate" size="sm" />
                        {!values.allDay && <Fields.TimePickerField name="untilTime" size="sm" />}
                      </div>
                      <Fields.ErrorField name="untilDateError" />
                    </div>
                  </>
                )}
              </div>

              {!id && (
                <Components.Button color="green" size="sm" className="ml-auto" type="submit">
                  Add
                </Components.Button>
              )}
              {id && (
                <Components.Button color="red" size="sm" className="ml-auto" onClick={remove} type="button">
                  Delete
                </Components.Button>
              )}
            </form>
          )
        }}
      />
    </Components.Modal>
  )
}
