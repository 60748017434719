type Props = {
  type?: string;
  title?: string;
  text: string;
};

export const Notice = ({ text, title, type = 'default' }: Props) => {
  let titleClass = 'text-gray-900';

  if (type === 'warning') {
    titleClass = 'text-red-700';
  }

  return (
    <div className="bg-white border border-gray-100 p-4 rounded-md shadow-sm">
      {title ? <h2 className={`text-xl font-bold mb-2 ${titleClass}`}>{title}</h2> : ''}
      <div className="text-gray-600">
        {text}
      </div>
    </div>
  )
}
