import cx from 'classnames'
import {NavbarHeader} from './NavbarHeader'
import {NavbarItems} from './NavbarItems'

type Props = {
  className?: string
  setShow: Function
}

export const MobileNavbar = ({className, setShow}: Props) => {
  return (
    <div
      className={cx(
        'fixed z-10 top-0 left-0 h-screen w-screen shrink-0 lg:hidden flex flex-col border-r gap-10 py-3 px-3 bg-exp-dark-blue text-white',
        className
      )}
    >
      <NavbarHeader onClose={() => setShow(false)} />
      <NavbarItems />
    </div>
  )
}
