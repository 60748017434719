import cx from 'classnames'
import { StorableEventLike } from 'common/types/utils'
import {WEEK_DAYS} from 'consts'
import {get, groupBy, range} from 'lodash-es'
import moment from 'moment'
import {ComponentType} from 'react'
import {PreviewComponentProps} from 'types'
import {pluralize} from 'utils/pluralize'

type Props<T extends StorableEventLike> = {
  periodId: string
  focusedEventId?: string 
  onSelect: (eventId: string) => void
  events: T[]
  previewComponent: ComponentType<PreviewComponentProps<T>>
  headerClassName?: string
}

const groupEvents = <T extends StorableEventLike>(events: T[]) => {
  return groupBy(events, (event) => moment(event.startedAt).date())
}

export const WeekCalendar = <T extends StorableEventLike>({
  periodId,
  events,
  onSelect,
  previewComponent: PreviewComponent,
  focusedEventId,
  headerClassName,
}: Props<T>) => {
  const startOfWeek = moment(periodId)
  const now = moment()
  const groupedEvents = groupEvents(events)
  const startDateTime = startOfWeek.format()

  return (
    <div>
      <div className={cx("grid grid-cols-7 border-y border-gray-300 bg-gray-300 gap-px", headerClassName)}>
        {WEEK_DAYS.map((day) => {
          return (
            <div className="text-center relative bg-white p-1 text-xs font-semibold leading-6 text-gray-700" key={day}>
              {day}
            </div>
          )
        })}
      </div>
      <div className="grid grid-cols-7 border-b border-gray-300 bg-gray-300 gap-px">
        {range(7).map((i) => {
          const day = moment(startDateTime).add(i, 'days').date()
          const dayEvents = get(groupedEvents, day, [])
          return (
            <div className="relative bg-white p-1 min-h-28" key={`${startOfWeek.year()} ${startOfWeek.month()} ${day}`}>
              <div className="flex justify-between">
                <div
                  className={cx('text-xs text-gray-700 w-6 h-6 flex items-center justify-center mb-2', {
                    'bg-blue-500 rounded-full text-white': startOfWeek.year() === now.year() && startOfWeek.month() === now.month() && day === now.date(),
                  })}
                >
                  {day}
                </div>
                {dayEvents.length > 0 && (
                  <div className="text-[11px] font-semibold">
                    {dayEvents.length} {pluralize(dayEvents.length, 'shift', 'shifts')}
                  </div>
                )}
              </div>
              <div className="flex flex-col gap-px">
                {dayEvents.map((event) => {
                  return (
                    <PreviewComponent
                      key={event.id}
                      event={event}
                      focus={event.id === focusedEventId}
                      onClick={() =>onSelect(event.id)}
                    />
                  )
                })}
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
