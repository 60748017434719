import {Field} from 'react-final-form'
import {getFormError} from 'ui/utils/getFormError'
import {Input} from '../components'
import {ControlSize} from '../types'

type Props<FieldValue> = {
  id?: string
  label?: string
  name: string
  type?: string
  disabled?: boolean
  expanded?: boolean
  spellCheck?: boolean
  className?: string
  parse?: (value: string) => FieldValue
  format?: (value: FieldValue, name: string) => string
  min?: number
  max?: number
  size?: ControlSize
  initialValue?: FieldValue
}

export const InputField = <FieldValue,>({
  id,
  label,
  name,
  type,
  disabled,
  expanded,
  className,
  spellCheck,
  min,
  max,
  size,
  initialValue,
  format,
  parse,
}: Props<FieldValue>) => {
  return (
    <Field<FieldValue, HTMLInputElement, string>
      type={type}
      name={name}
      initialValue={initialValue}
      parse={parse}
      format={format}
      render={({input, meta}) => {
        return (
          <Input
            {...input}
            id={id}
            label={label}
            disabled={disabled}
            expanded={expanded}
            className={className}
            spellCheck={spellCheck}
            min={min}
            max={max}
            size={size}
            error={getFormError(meta)}
          />
        )
      }}
    />
  )
}
