import {Locations} from 'common/types'
import {locationHooks} from 'hooks/locations'
import {keyBy} from 'lodash-es'
import {UserProvider} from 'providers/UserProvider'
import {useMemo} from 'react'

const DEFAULT_VALUE: Locations.Basic.StorableEntity = {
  id: '',
  createdAt: '',
  updatedAt: '',
  code: '',
  isDeleted: false,
  name: '',
  shortName: '',
  timeZone: '',
  currency: null,
}

const useList = () => {
  const user = UserProvider.useUser()

  const listQuery = locationHooks.useListQuery(
    {
      orderBy: ['name'],
      where: [{field: 'id', values: user.locationIds}],
    },
    {
      staleTime: 30 * 60 * 1000,
    }
  )

  return listQuery.data || []
}

const useOptions = (withFilter?: (item: Locations.Basic.StorableEntity) => boolean) => {
  const list = useList()

  return useMemo(() => {
    return list.filter(withFilter ? withFilter : () => true).map((item) => {
      return {
        label: item.name,
        value: item.id,
      }
    })
  }, [list, withFilter])
}

const useMap = () => {
  const list = useList()

  return useMemo(() => {
    return keyBy(list, 'id')
  }, [list])
}

const useById = (id: string) => {
  const map = useMap()
  return map[id] || DEFAULT_VALUE
}

export const LocationManager = {
  useList,
  useOptions,
  useMap,
  useById,
}
