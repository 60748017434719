import {Transfers} from 'common/types'
import { capitalize } from 'lodash-es'
import {Pill} from 'ui/components'
import {ReactComponent as CheckIcon} from 'icons/Check.svg'
import {ReactComponent as CrossIcon} from 'icons/Close.svg'
import {ReactComponent as PendingIcon} from 'icons/Pending.svg'
import { ReactElement } from 'react'

type Props = {
  transfer: Transfers.Basic.Entity
} | {status: Transfers.Basic.Status}

type StatusPillStyle = {
  className: string;
  icon: ReactElement;
};

const STATUSES: Record<Transfers.Basic.Status, StatusPillStyle> = {
  pending: {
    className: '!text-yellow-500',
    icon: <PendingIcon className="w-3 h-3 mr-1 inline align-text-top" />,
  },
  accepted: {
    className: '!text-green-500',
    icon: <CheckIcon className="w-3 h-3 mr-1 inline align-text-top" />,
  },
  declined: {
    className: '!text-red-500',
    icon: <CrossIcon className="w-3 h-3 mr-1 inline align-text-top" />,
  },
  cancelled: {
    className: '!text-gray-500',
    icon: <CrossIcon className="w-3 h-3 mr-1 inline align-text-top" />,
  },
}



export const TransferStatusPill = (props: Props) => {
    if ('status' in props) {
      return <Pill className={STATUSES[props.status]['className']}>{STATUSES[props.status].icon} {capitalize(props.status)}</Pill>
    }

  return <Pill className={STATUSES[props.transfer.status]['className']}>{STATUSES[props.transfer.status].icon} {capitalize(props.transfer.status)}</Pill>
}
