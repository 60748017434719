import cx from 'classnames'
import {ComponentType, ReactNode} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'

type Props = {
  icon: ComponentType<{className?: string}>
  title: ReactNode
  redirectTo?: string
  className?: string
  collapsed?: boolean
  onClick?: () => void
  disabled?: boolean
}

export const NavbarItem = ({icon: Icon, title, redirectTo, className, collapsed, onClick, disabled}: Props) => {
  const navigate = useNavigate()

  const action = redirectTo
    ? () => {
        navigate(redirectTo)
      }
    : onClick

  const location = useLocation()

  let activeClassName = 'hover:bg-gray-50/25'

  if (redirectTo && location.pathname.startsWith(redirectTo)) {
    activeClassName = 'bg-exp-orange'
  }

  return (
    <div
      onClick={action}
      className={cx(
        `text-white group flex gap-2 items-center rounded-md p-2 text-sm leading-6 font-semibold cursor-pointer ${activeClassName}`,
        collapsed ? 'w-fit' : '',
        disabled ? 'pointer-events-none' : '',
        className
      )}
    >
      <Icon className="min-w-6 h-6" />
      {!collapsed && title}
    </div>
  )
}
