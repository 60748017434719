/* eslint-disable react-hooks/rules-of-hooks */
import {notificationApi} from 'api'
import {CrudApi} from 'api/notifications'
import {Params, Responses} from 'common/types/notifications'
import {useMutation} from 'react-query'
import {Utils} from 'ui'

class CrudHooks extends Utils.CrudHooks<
  Params.Count,
  Params.List,
  never,
  never,
  Responses.List,
  never,
  never,
  never,
  CrudApi
> {
  useMarkAsSeenMutation() {
    return useMutation<void, unknown, string>((id: string) => this.crudApi.markAsSeen(id))
  }
}

export const notificationHooks = new CrudHooks('notification', 'Notification', notificationApi)
