import {Assignments, Transfers} from 'common/types'
import {assignmentHooks} from 'hooks/assignments'
import {transferHooks} from 'hooks/transfers'
import moment from 'moment'
import {UserProvider} from 'providers/UserProvider'
import {useCallback} from 'react'
import {Components} from 'ui'
import {isCloseEvent} from 'utils/isCloseEvent'
import {TransferModal} from './TransferModal'

type Props = {
  assignment: Assignments.Basic.StorableAugmentedEntity
  transfer: Transfers.Basic.StorableEntity | null
  onChange: () => void
  onClose: () => void
}

export const GuideShiftActions = ({assignment, transfer, onChange, onClose}: Props) => {
  const user = UserProvider.useUser()
  const allowActions = !isCloseEvent(assignment.startedAt)
  const assignmentAcceptMutate = assignmentHooks.useAcceptMutation().mutate
  const assignmentDeclineMutate = assignmentHooks.useDeclineMutation().mutate
  const transferRemoveMutate = transferHooks.useRemoveMutation().mutate
  const transferAcceptMutate = transferHooks.useAcceptMutation().mutate
  const transferDeclineMutate = transferHooks.useDeclineMutation().mutate

  const transferModalManager = Components.Modal.useModalManager()

  const acceptAssignment = useCallback(() => {
    assignmentAcceptMutate(assignment.id, {
      onSuccess: () => onChange(),
    })
  }, [assignment, assignmentAcceptMutate, onChange])

  const declineAssignment = useCallback(() => {
    assignmentDeclineMutate(assignment.id, {
      onSuccess: () => {
        onChange()
        onClose()
      },
    })
  }, [assignment, assignmentDeclineMutate, onChange, onClose])

  const acceptTransfer = useCallback(() => {
    if (!transfer) {
      return
    }

    transferAcceptMutate(transfer.id, {
      onSuccess: () => onChange(),
    })
  }, [transfer, transferAcceptMutate, onChange])

  const declineTransfer = useCallback(() => {
    if (!transfer) {
      return
    }

    transferDeclineMutate(transfer.id, {
      onSuccess: () => {
        onChange()
        onClose()
      },
    })
  }, [transfer, transferDeclineMutate, onChange, onClose])

  const removeTransfer = useCallback(() => {
    if (!transfer) {
      return
    }

    transferRemoveMutate(transfer.id, {
      onSuccess: () => onChange(),
    })
  }, [transfer, transferRemoveMutate, onChange])

  if (!allowActions) {
    return null
  }

  if (transfer) {
    if (user.id === transfer.fromGuideId) {
      return (
        <div className="flex gap-2">
          <Components.Button color="green" size="sm" expanded onClick={removeTransfer}>
            Cancel Request
          </Components.Button>
          <Components.Button color="red" size="sm" expanded onClick={declineAssignment}>
            Decline
          </Components.Button>
        </div>
      )
    } else {
      return (
        <div className="flex gap-2">
          <Components.Button color="green" size="sm" expanded onClick={acceptTransfer}>
            Accept
          </Components.Button>
          <Components.Button color="red" size="sm" expanded onClick={declineTransfer}>
            Decline
          </Components.Button>
        </div>
      )
    }
  }

  if (assignment.status === 'pending') {
    return (
      <div className="flex gap-2">
        <Components.Button color="green" size="sm" expanded onClick={acceptAssignment}>
          Accept
        </Components.Button>
        <Components.Button color="red" size="sm" expanded onClick={declineAssignment}>
          Decline
        </Components.Button>
      </div>
    )
  }

  return (
    <div className="flex gap-2">
      {moment(assignment.startedAt).subtract(48, 'hours').isAfter(moment()) && (
        <Components.Button size="sm" color="yellow" expanded onClick={transferModalManager.open}>
          Request Transfer
        </Components.Button>
      )}
      <Components.Button size="sm" color="red" expanded onClick={declineAssignment}>
        Decline
      </Components.Button>

      {transferModalManager.state === 'opened' && <TransferModal assignment={assignment} onClose={transferModalManager.close} onChange={onChange} />}
    </div>
  )
}
