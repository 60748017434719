import {Notifications} from 'common/types'
import { useMarkAsSeen } from '../hooks/useMarkAsSeen'
import { TourEventCardInfo } from 'components/TourEventCardInfo'
import { useAssignmentById } from '../hooks/useAssignment'
import { NotificationCard } from '../NotificationCard'
import { AssignmentStatusPill } from 'components/pills/AssignmentStatusPill'

type Props = {
    notification: Notifications.Basic.StorableEntity<Notifications.Basic.GuideUnassignedDetails>
}

export const GuideUnassigned = ({notification}: Props) => {
  const {isSeen} = useMarkAsSeen(notification, 'auto')
  const assignment = useAssignmentById(notification.details.assignmentId)

  return (
    <NotificationCard isSeen={isSeen}>
      <TourEventCardInfo title="Assignment cancellation" event={assignment} />
      <AssignmentStatusPill status="cancelled" />
    </NotificationCard>
  )
}
