import cx from 'classnames'
import {DATE_FORMAT} from 'consts'
import {range} from 'lodash-es'
import moment from 'moment'
import {ChangeEventHandler, useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {ControlSize} from 'ui/types'
import {isSameOrChildOf} from 'ui/utils/isSameOrChildOf'
import {useDropdownStyle} from 'ui/utils/useDropdownStyle'
import {ReactComponent as ChevronForwardIcon} from '../icons/chevron-forward.svg'
import {ReactComponent as ChevronLeftIcon} from '../icons/chevron-left.svg'
import {Label} from './Label'
import {Text} from './Text'

declare const Object: any

export type Date = {
  year: number
  month: number
  date: number
}

type Props = {
  id?: string
  label?: string
  error?: string
  name?: string
  value?: string
  onChange?: ChangeEventHandler<HTMLInputElement>
  size?: ControlSize
  disabled?: boolean
  expanded?: boolean
  className?: string
  placeholder?: string
}

const getSizeClasses = (size: ControlSize) => {
  switch (size) {
    case 'sm':
      return 'text-xs p-2'
    case 'default':
      return 'text-sm p-2.5'
    case 'lg':
      return 'text-md p-4'
  }
}

const weekDays = ['M', 'T', 'W', 'T', 'F', 'S', 'S']

export const DatePicker = ({
  value: initialValue = '',
  onChange,
  size = 'default',
  label,
  id,
  name,
  error,
  disabled,
  expanded,
  className,
  placeholder = 'None',
}: Props) => {
  const sizeClasses = getSizeClasses(size)
  const now = moment()

  const [selectedDate, setSelectedDate] = useState(initialValue ? moment(initialValue) : null)
  const [selectedMonthAndYear, setSelectedMonthAndYear] = useState(initialValue ? moment(initialValue) : now)
  const [value, setValue] = useState(initialValue)
  const [collapsed, setCollapsed] = useState(true)
  const visibleInputRef = useRef<HTMLDivElement>(null)
  const hiddenInputRef = useRef<HTMLInputElement>(null)

  const dropdownStyles = useDropdownStyle(visibleInputRef)

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  const numberOfPaddingDays = useMemo(() => (selectedMonthAndYear.startOf('month').day() + 6) % 7, [selectedMonthAndYear])
  const daysInMonth = useMemo(() => selectedMonthAndYear.daysInMonth(), [selectedMonthAndYear])

  const changeDate = useCallback(
    (date: number) => {
      const newSelectedDate = moment({year: selectedMonthAndYear.year(), month: selectedMonthAndYear.month(), date})
      setSelectedDate(newSelectedDate)
      setValue(newSelectedDate.format(DATE_FORMAT))
    },
    [selectedMonthAndYear]
  )

  const changeMonth = useCallback((event: React.MouseEvent, action: 'subtract' | 'add') => {
    event.stopPropagation()

    setSelectedMonthAndYear((selectedMonthAndYear) => {
      const newSelectedMonthAndYear = moment(selectedMonthAndYear)
      newSelectedMonthAndYear[action](1, 'month')
      return newSelectedMonthAndYear
    })
  }, [])

  useEffect(() => {
    const onClick = (event: MouseEvent) => {
      if (visibleInputRef.current && !isSameOrChildOf(event.target as HTMLDivElement, visibleInputRef.current)) {
        setCollapsed(true)
      }
    }

    document.addEventListener('click', onClick)

    return () => document.removeEventListener('click', onClick)
  }, [visibleInputRef])

  useEffect(() => {
    if (hiddenInputRef.current) {
      const nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value').set
      nativeInputValueSetter.call(hiddenInputRef.current, value)
      hiddenInputRef.current.dispatchEvent(new Event('change', {bubbles: true}))
    }
  }, [value])

  const onInputClick = useCallback(() => setCollapsed((collapsed) => !collapsed), [])

  const inputClasses = cx(
    'text-gray-900 border rounded-lg w-full',
    {
      'ring-inset ring-1 ring-blue-500 border-blue-500': !collapsed,
      '!text-gray-500 pointer-events-none !bg-gray-50': disabled,
    },
    sizeClasses
  )

  return (
    <div className={cx('flex flex-col select-none', expanded ? 'w-full' : 'w-24', className)}>
      {label && <Label htmlFor={id}>{label}</Label>}
      <input className="hidden" onChange={onChange} ref={hiddenInputRef} name={name} type="text" />
      <div ref={visibleInputRef} className={inputClasses} onClick={onInputClick}>
        {selectedDate ? (
          <span className="text-gray-900">{selectedDate.format('YYYY MMM DD')}</span>
        ) : (
          <span className="italic text-gray-500">{placeholder}</span>
        )}
      </div>
      <div className="relative">
        <div
          className={cx('absolute flex flex-col text-sm bg-white border shadow z-20 rounded-lg max-h-72', {hidden: collapsed})}
          style={dropdownStyles}
        >
          <div className="flex justify-between p-2">
            <ChevronLeftIcon onClick={(event) => changeMonth(event, 'subtract')} className="cursor-pointer hover:opacity-70" />
            <div>{selectedMonthAndYear.format('YYYY, MMM')}</div>
            <ChevronForwardIcon onClick={(event) => changeMonth(event, 'add')} className="cursor-pointer hover:opacity-70" />
          </div>
          <div className="grid grid-cols-7 w-72">
            {weekDays.map((day, index) => {
              return (
                <div key={day + index} className="leading-10 text-center text-gray-500">
                  {day}
                </div>
              )
            })}
            {range(numberOfPaddingDays).map((day) => {
              return <div key={day} className="h-10" />
            })}
            {range(1, daysInMonth + 1).map((date) => {
              const isSelectedDate =
                date === selectedDate?.date() &&
                selectedDate?.month() === selectedMonthAndYear.month() &&
                selectedDate?.year() === selectedMonthAndYear.year()
              return (
                <div
                  key={date}
                  className={cx('leading-10 text-center rounded', {
                    'bg-blue-500 text-white pointer-events-none': isSelectedDate,
                    'cursor-pointer hover:bg-blue-200': !isSelectedDate,
                  })}
                  onClick={() => changeDate(date)}
                >
                  {date}
                </div>
              )
            })}
          </div>
        </div>
      </div>

      {error && (
        <Text color="red" size="sm">
          {error}
        </Text>
      )}
    </div>
  )
}
