import cx from 'classnames'
import {ReactNode} from 'react'

type Prop = {
  children: ReactNode
  className?: string
}

export const Pill = ({children, className}: Prop) => {
  return (
    <div className={cx('px-2 py-1 text-xs font-light bg-white border border-gray-300 rounded-md whitespace-nowrap w-fit', className)}>{children}</div>
  )
}
