import {Assignments, Transfers} from 'common/types'
import {TourEventCardInfo} from 'components/TourEventCardInfo'
import {GuideManager} from 'hooks/scheduling/GuideManager'
import {transferHooks} from 'hooks/transfers'
import {useCallback} from 'react'
import {Components} from 'ui'
import {Card} from 'ui/components/Card'

type Props = {
  transfer: Transfers.Basic.StorableEntity
  assignment: Assignments.Basic.StorableAugmentedEntity
  onChange: () => void
}

export const TransferToMe = ({assignment, transfer, onChange}: Props) => {
  const fromGuide = GuideManager.useById(transfer.toGuideId)

  const acceptMutate = transferHooks.useAcceptMutation().mutate
  const declineMutate = transferHooks.useDeclineMutation().mutate

  const accept = useCallback(() => {
    acceptMutate(transfer.id, {onSuccess: onChange})
  }, [acceptMutate, onChange, transfer])

  const decline = useCallback(() => {
    declineMutate(transfer.id, {onSuccess: onChange})
  }, [declineMutate, onChange, transfer])

  return (
    <Card className="flex justify-between items-center text-sm">
      <TourEventCardInfo title={`Transfer Request from ${fromGuide.firstName} ${fromGuide.lastName}`} event={assignment} />
      <div className="flex gap-4">
        <Components.Button color="green" size="sm" onClick={accept}>
          Accept
        </Components.Button>
        <Components.Button color="red" size="sm" onClick={decline}>
          Decline
        </Components.Button>
      </div>
    </Card>
  )
}
