import {PageHeader} from 'components/PageHeader'
import {locationHooks} from 'hooks/locations'
import {useNavigate} from 'react-router-dom'
import {Components} from 'ui'
import {formatCurrency} from 'utils/formatCurrency'

export const List = () => {
  const navigate = useNavigate()

  const paginatedQuery = locationHooks.useListQuery({
    limit: 50,
    orderBy: ['createdAt'],
    where: [{field: 'isDeleted', value: false}],
  })

  const onAddButtonClick = () => {
    navigate('/locations/create')
  }

  const headers = [
    {key: 'name', value: 'Name'},
    {key: 'shortName', value: 'Short name'},
    {key: 'code', value: 'Code'},
    {key: 'timeZone', value: 'Time zone'},
    {key: 'currency', value: 'Currency'},
    {key: 'edit', className: 'w-1'},
  ]

  if (!paginatedQuery.data) {
    return null
  }

  const rows = paginatedQuery.data.map((location) => ({
    key: location.id,
    columns: {
      name: location.name,
      shortName: location.shortName,
      code: location.code,
      timeZone: location.timeZone,
      currency: formatCurrency(location.currency),
      edit: <Components.EditLink to={`/locations/${location.id}/edit`} />,
    },
  }))

  return (
    <div className="flex flex-col gap-4">
      <PageHeader title="Locations">
        <Components.Button size="sm" onClick={onAddButtonClick}>
          Add Location
        </Components.Button>
      </PageHeader>

      <Components.Table headers={headers} rows={rows} />
    </div>
  )
}
