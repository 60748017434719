import cx from 'classnames'
import {Children, ReactNode} from 'react'
import {Item} from './Item'

type Props = {
  className?: string
  children: ReactNode
}

const getItemClassName = (total: number, index: number) => {
  if (index === total - 1) {
    return 'pt-2'
  }

  if (index === 0) {
    return 'pb-2'
  }

  return 'py-2'
}

export const InfoBlock = ({className, children}: Props) => {
  const total = Children.count(children)
  return (
    <div className={cx('flex flex-col divide-y divide-gray-300 text-sm', className)}>
      {Children.map(children, (child, index) => {
        return child ? <div className={getItemClassName(total, index)}>{child}</div> : null
      })}
    </div>
  )
}

InfoBlock.Item = Item
