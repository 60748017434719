import {Assignments, Transfers} from 'common/types'
import {TourEventCardInfo} from 'components/TourEventCardInfo'
import {GuideManager} from 'hooks/scheduling/GuideManager'
import {transferHooks} from 'hooks/transfers'
import {useCallback} from 'react'
import {Components} from 'ui'
import {Card} from 'ui/components/Card'

type Props = {
  transfer: Transfers.Basic.StorableEntity
  assignment: Assignments.Basic.StorableAugmentedEntity
  onChange: () => void
}

export const TransferFromMe = ({assignment, transfer, onChange}: Props) => {
  const toGuide = GuideManager.useById(transfer.toGuideId)
  const cancelMutate = transferHooks.useRemoveMutation().mutate

  const cancel = useCallback(() => {
    cancelMutate(transfer.id, {onSuccess: onChange})
  }, [cancelMutate, onChange, transfer])

  return (
    <Card className="flex justify-between items-center text-sm">
      <TourEventCardInfo title={`Transfer Request to ${toGuide.firstName} ${toGuide.lastName}`} event={assignment} />
      <Components.Button color="green" size="sm" onClick={cancel}>
        Cancel
      </Components.Button>
    </Card>
  )
}
