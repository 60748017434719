import {Guides} from 'common/types'
import {PageHeader} from 'components/PageHeader'
import {guideHooks} from 'hooks/guides'
import {locationHooks} from 'hooks/locations'
import {productHooks} from 'hooks/products'
import {isEmpty} from 'lodash-es'
import {Form} from 'react-final-form'
import {useNavigate} from 'react-router-dom'
import {Components, Fields} from 'ui'
import {Frame} from 'ui/components/Frame'

type Values = Guides.Params.Create

export const Create = () => {
  const navigate = useNavigate()

  const locationsQuery = locationHooks.useListQuery({
    orderBy: ['name'],
  })

  const productsQuery = productHooks.useListQuery({
    orderBy: ['name'],
  })

  const createGuideDataMutation = guideHooks.useCreateMutation()

  const onSubmit = (values: Values) => {
    createGuideDataMutation.mutate(values, {
      onSuccess: (guide) => {
        navigate(`/guides/${guide.id}/edit`)
      },
    })
  }

  if (!locationsQuery.data || !productsQuery.data) {
    return null
  }

  const locationOptions = locationsQuery.data.map((location) => {
    return {
      label: location.name,
      value: location.id,
    }
  })

  return (
    <div className="flex flex-col">
      <PageHeader title="Guides" subtitle="Add Guide" />

      <Form
        onSubmit={onSubmit}
        render={({handleSubmit, pristine, values}) => {
          const disableSave =
            pristine ||
            isEmpty(values.firstName) ||
            isEmpty(values.lastName) ||
            isEmpty(values.email) ||
            isEmpty(values.locationId) ||
            isEmpty(values.productIds) ||
            isEmpty(values.handle)

          const productOptions = productsQuery.data
            .filter((product) => {
              return values.locationId === product.locationId
            })
            .map((product) => {
              return {
                label: product.shortName,
                value: product.id,
              }
            })

          return (
            <form onSubmit={handleSubmit} className="flex flex-col gap-6">
              <Frame title="User info" className="flex flex-col gap-3">
                <Fields.InputField label="First name" name="firstName" expanded />
                <Fields.InputField label="Last name" name="lastName" expanded />
                <Fields.InputField label="Email" name="email" expanded />
                <Fields.SelectField label="Location" name="locationId" options={locationOptions} expanded />
              </Frame>
              <Frame title="Guide info" className="flex flex-col gap-3">
                <Fields.InputField label="Handle" name="handle" expanded />
                <Fields.InputField label="Wise email" name="wiseEmail" expanded />
                <Fields.MultiSelectField label="Tours" name="productIds" options={productOptions} expanded />
                <Fields.CheckboxField label="Guide trainer" name="isTrainer" />
              </Frame>

              <Components.Button disabled={disableSave} className="ml-auto">
                Add Guide
              </Components.Button>
            </form>
          )
        }}
      />
    </div>
  )
}
