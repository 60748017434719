import {Products} from 'common/types'
import {Where} from 'common/types/utils'
import {PageHeader} from 'components/PageHeader'
import {locationHooks} from 'hooks/locations'
import {productHooks} from 'hooks/products'
import {isEmpty, keyBy} from 'lodash-es'
import {useMemo, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {Components} from 'ui'
import {Circle} from 'ui/components/Circle'
import {DEFAULT_LIMIT} from 'ui/components/Paginator'
import {formatMoney} from 'utils/formatMoney'
import {DEFAULT_FILTERS, Filters} from './Filters'

export const List = () => {
  const navigate = useNavigate()
  const [offset, setOffset] = useState(0)
  const [filters, setFilters] = useState(DEFAULT_FILTERS)

  const where: Where<Products.Basic.StorableEntity> = useMemo(
    () => [
      {field: 'locationId', values: filters.locationIds, skip: isEmpty(filters.locationIds)},
      {field: 'isDeleted', value: false},
    ],
    [filters]
  )

  const locationListQuery = locationHooks.useListQuery({})

  const productListQuery = productHooks.useListQuery({
    orderBy: ['createdAt'],
    where,
    limit: DEFAULT_LIMIT,
    offset,
  })

  const productCountQuery = productHooks.useCountQuery({
    where,
  })

  const onAddButtonClick = () => {
    navigate('/products/create')
  }

  const headers = [
    {key: 'shortName', value: 'Reference'},
    {key: 'name', value: 'Name'},
    {key: 'location', value: 'Location'},
    {key: 'ratio', value: 'guests / guide'},
    {key: 'checkInDurationMin', value: 'Checkin Duration'},
    {key: 'bufferMin', value: 'Buffer'},
    {key: 'payment', value: 'Payment'},
    {key: 'edit'},
  ]

  if (!productListQuery.data || !productCountQuery.data || !locationListQuery.data) {
    return null
  }

  const locationMap = keyBy(locationListQuery.data, 'id')

  const rows = productListQuery.data.map((product) => ({
    key: product.id,
    columns: {
      name: product.name,
      ratio: `${product.ratio}`,
      checkInDurationMin: `${product.checkInDurationMin} min`,
      bufferMin: `${product.bufferMin} min`,
      location: locationMap[product.locationId].name,
      payment: formatMoney(product.payment, locationMap[product.locationId].currency),
      shortName: (
        <div className="flex gap-2 items-center">
          <Circle style={{backgroundColor: product.color}} />
          <div>{product.shortName}</div>
        </div>
      ),
      edit: <Components.EditLink to={`/products/${product.id}/edit`} />,
    },
  }))

  return (
    <div className="flex flex-col gap-4">
      <PageHeader title="Tours">
        <div className="w-full flex justify-between">
          <Filters value={filters} onChange={setFilters} />
          <Components.Button size="sm" onClick={onAddButtonClick} className="ml-auto">
            Add Tour
          </Components.Button>
        </div>
      </PageHeader>
      <Components.Table headers={headers} rows={rows} />
      <Components.Paginator offset={offset} total={productCountQuery.data} onChangeOffset={setOffset} />
    </div>
  )
}
