import {Field} from 'react-final-form'
import {TextArea, TextAreaProps} from '../components/TextArea'
import { getFormError } from 'ui/utils/getFormError'

type Props = Omit<TextAreaProps, 'value' | 'onChange' | 'error' | 'name'> & {
  initialValue?: string
  name: string
}

export const TextAreaField = ({name, initialValue, ...other}: Props) => {
  return (
    <Field
      type="textarea"
      name={name}
      initialValue={initialValue}
      render={(props) => {
        return <TextArea {...props.input} {...other} error={getFormError(props.meta)} />
      }}
    />
  )
}
