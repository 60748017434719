import cx from 'classnames'

type Props = {
  total: number
  offset: number
  limit?: number
  onChangeOffset: (offset: number) => void
}

export const DEFAULT_LIMIT = 20

const getTotalPageQty = (total: number, limit: number) => {
  return Math.ceil(total / limit)
}

const getCurrentPage = (limit: number, offset: number) => {
  return offset / limit + 1
}

const getPages = (totalPageQty: number, currentPage: number) => {
  const previousPage = currentPage === 1 ? null : currentPage - 1
  const nextPage = currentPage === totalPageQty ? null : currentPage + 1

  const pages = []

  if (previousPage) {
    if (previousPage > 1) {
      pages.push(1)
    }

    if (previousPage > 2) {
      pages.push(null)
    }

    pages.push(previousPage)
  }

  pages.push(currentPage)

  if (nextPage) {
    pages.push(nextPage)

    if (nextPage < totalPageQty - 1) {
      pages.push(null)
    }

    if (nextPage < totalPageQty) {
      pages.push(totalPageQty)
    }
  }
  return pages
}

export const Paginator = ({total, offset, limit = DEFAULT_LIMIT, onChangeOffset}: Props) => {
  const currentPage = getCurrentPage(limit, offset)
  const totalPageQty = getTotalPageQty(total, limit)
  const pages = getPages(totalPageQty, currentPage)

  return (
    <div className="flex justify-between text-sm">
      <div className="text-gray-400">
        Showing {offset + 1} to {Math.min(offset + limit, total)} of {total} entries
      </div>

      {totalPageQty > 1 ? (
        <div className="flex border rounded">
          {pages.map((page, index) => {
            if (page === null) {
              return (
                <div key={page + '.' + index} className="px-3 py-2">
                  ...
                </div>
              )
            }

            return (
              <div
                key={index}
                className={cx('px-3 py-2', {
                  'cursor-pointer': page !== currentPage,
                  'bg-blue-500 text-white font-bold': page === currentPage,
                })}
                onClick={() => onChangeOffset((page - 1) * limit)}
              >
                {page}
              </div>
            )
          })}
        </div>
      ) : null}
    </div>
  )
}
