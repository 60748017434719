export const isEmptyValue = (value: unknown) => {
  return (
    value === null ||
    value === undefined ||
    (typeof value === 'string' && value.trim().length === 0) ||
    value === 0 ||
    Number.isNaN(value) ||
    (Array.isArray(value) && value.length === 0)
  )
}
