import {AddUnavailability} from 'components/AddUnavailability'
import {PageHeader} from 'components/PageHeader'
import {ViewSwitcher} from 'components/filters/ViewSwitcher'
import {keyBy} from 'lodash-es'
import {Filters, FiltersValue} from 'pages/shifts/guide/Filters'
import {useInitialEventId} from 'pages/shifts/hooks/useInitialEventId'
import {useInitialPeriodId} from 'pages/shifts/hooks/useInitialPeriodId'
import {useCallback, useMemo, useState} from 'react'
import {Period} from 'types'
import {usePeriodCalendarComponent} from '../hooks/usePeriodCalendarComponent'
import {usePeriodFilterComponent} from '../hooks/usePeriodFilterComponent'
import {useGuideEventPreviewComponent} from './GuideEventPreview'
import {GuideEventViewer} from './GuideEventViewer'
import {useData} from './useData'

type Props = {
  title: string
  period: Period
  onChangeView: (view: Period) => void
  groupFilters: FiltersValue
  onChangeGroupFilters: (filters: FiltersValue) => void
}

export const PeriodView = ({title, period, onChangeView, groupFilters, onChangeGroupFilters}: Props) => {
  const PeriodCalendarComponent = usePeriodCalendarComponent(period)
  const PeriodFilterComponent = usePeriodFilterComponent(period)
  const initialPeriodId = useInitialPeriodId(period)
  const [periodId, setPeriodId] = useState(initialPeriodId)
  const {data, refetch, effectivePeriodId} = useData({periodId, groupFilters, period})
  const {unavailabilityEvents, assignments, transfers} = data
  const previewComponent = useGuideEventPreviewComponent({transfers})
  const initialEventId = useInitialEventId()
  const [focusedEventId, setFocusedEventId] = useState(initialEventId)
  const [displayedEventId, setDisplayedEventId] = useState(initialEventId)

  const onClose = useCallback(() => {
    setDisplayedEventId('')
  }, [])

  const onChange = useCallback(() => {
    refetch()
  }, [refetch])

  const onSelect = useCallback((eventId: string) => {
    setFocusedEventId(eventId)
    setDisplayedEventId(eventId)
  }, [])

  const events = useMemo(() => {
    return [
      ...assignments.map((item) => ({...item, eventType: 'assignment' as const})),
      ...unavailabilityEvents.map((item) => ({...item, eventType: 'unavailability' as const})),
    ]
  }, [assignments, unavailabilityEvents])

  const eventMap = useMemo(() => keyBy(events, 'id'), [events])

  return (
    <>
      <PageHeader title={title} />
      <div className="flex flex-col">
        <div className="flex p-4 sticky top-[51px] bg-white z-10">
          <div className="flex flex-col gap-2 w-full">
            <div className="flex gap-5 items-center">
              <ViewSwitcher view={period} onChange={onChangeView} />
              <PeriodFilterComponent value={periodId} onChange={setPeriodId} />
              <AddUnavailability onChange={refetch} />
            </div>
            <Filters value={groupFilters} onChange={onChangeGroupFilters} />
          </div>
        </div>
        <PeriodCalendarComponent
          periodId={effectivePeriodId}
          focusedEventId={focusedEventId}
          events={events}
          onSelect={onSelect}
          previewComponent={previewComponent}
        />
        {displayedEventId && <GuideEventViewer transfers={transfers} event={eventMap[displayedEventId]} onClose={onClose} onChange={onChange} />}
      </div>
    </>
  )
}
