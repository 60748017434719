import {Currency} from 'common/types/utils'

export const formatCurrency = (currency: Currency | null) => {
  switch (currency) {
    case 'EUR':
      return '€'
    case 'USD':
      return '$'
    case 'GBP':
      return '£'
    case null:
      return '?'
  }
}
