import { ReactNode } from "react"
import cx from 'classnames'

  type Props = {
    label: ReactNode
    children: ReactNode
    className?: string
  }

export const Item = ({children, label, className}: Props) => {
  return (
    <div className={cx("flex justify-between", className)}>
      <div className="text-gray-500">{label}</div>
      <div className="text-gray-700">{children}</div>
    </div>
  )
}
