import {LocationFilter} from 'components/filters/LocationFilter'
import {LocationManager} from 'hooks/scheduling/LocationManager'
import {UserProvider} from 'providers/UserProvider'

export type FiltersValue = {
  locationIds: string[]
}

type Props = {
  value: FiltersValue
  onChange: (value: FiltersValue) => void
}

export const DEFAULT_FILTERS: FiltersValue = {
  locationIds: [],
}

export const Filters = ({value, onChange}: Props) => {
  const user = UserProvider.useUser()

  const locationOptions = LocationManager.useOptions((location) => {
    return user.locationIds.includes(location.id)
  })

  return (
    <div className="flex gap-5 items-center">
      <LocationFilter
        options={locationOptions}
        locationIds={value.locationIds}
        onChange={(locationIds) => onChange({...value, locationIds})}
        className="!w-56"
      />
    </div>
  )
}
